import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';

const WizardStep = observer((props) => {
  let bold = ' font-bold';
  let bgColor = ' bg-gray-400';
  let textColor = ' text-gray-400';
  let border = null;
  let isDisable = false;
  let cursor = ' cursor-pointer';
  let currentSelectedBgColor = ' bg-blue-600 hover:bg-blue-800';
  let stepPassedBgColor = ' bg-blue-400 hover:bg-blue-600';
  if (props.index < appState.currentIndex) {
    bgColor = stepPassedBgColor;
    textColor = ' text-blue-400';
  } else if (props.index === appState.currentIndex) {
    bold = ' font-extrabold';
    bgColor = currentSelectedBgColor;
    textColor = ' text-blue-500';
    border = ' border border-4 border-blue-900';
  } else if (props.index === appState.currentIndex + 1) {
    isDisable = props.enable;
    cursor = props.enable ? ' cursor-pointer' : ' cursor-not-allowed';
  } else if (props.index > appState.currentIndex + 1) {
    isDisable = true;
    cursor = ' cursor-not-allowed';
  }

  return (
    <div className="w-1/4">
      <div className="relative mb-1 xl:mb-0.5 2xl:mb-1">
        <button
          className={
            'shadow-2xl w-8 h-8 xl:w-6 xl:h-6 2xl:h-8 2xl:w-8 mx-auto rounded-full ' +
            bgColor +
            ' text-lg text-white flex p-2 items-center focus:outline-none' +
            cursor
          }
          onClick={() => {
            appState.currentIndex = props.index;
          }}
          disabled={isDisable}
        >
          <span className="w-full text-center text-white xl:text-sm 2xl:text-base">
            {props.index + 1}
          </span>
        </button>
      </div>
      <div
        className={
          'wizard-title ' +
          'text-xs ' +
          bold +
          textColor +
          ' text-center md:text-base xl:text-sm 2xl:text-base'
        }
      >
        {props.title}
      </div>
    </div>
  );
});

export default WizardStep;
