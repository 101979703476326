import { observable } from 'mobx';
import {
  FREE_USER_PHOTOS_LIMIT,
  DEFAULT_TEXT_COLOR,
  DEFAULT_OUTLINE_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SVG_IMAGE_COLOR,
  RESIZE_OPTIONS,
} from '../Constants';
import firebase from 'firebase';

export var appState = observable({
  listOfAllUploadedImage: [],
  listOfAllFinalImage: [],
  listOfTextAdded: [],
  listOfDefaultWatermarkIds: [],
  listOfModifiedWatermarkIds: [],
  listOfTextRepeatObj: [],
  listOfLogoRepeatObj: [],
  mainImageCanvasWidth: 0,
  mainImageCanvasHeight: 0,
  defaultWatermarksList: null,
  defaultWaterMarks: null,
  modifiedWatermarksList: null,
  modifiedWaterMarks: null,
  canvasId: null,
  selectedProperty: null,
  selectedText: null,
  selectedLogo: null,
  selectedGroup: null,
  allAddedText: [],
  allAddedLogos: [],
  allAddedGroups: [],
  activeObjectType: null,
  isDownloadModalVisible: false,
  isSignUpModalVisible: false,
  loggedInUser: null,
  allSourceImages: [],
  imageHeight: 0,
  imageWidth: 0,
  scaleMultiplier: 0,
  pickerColors: [
    'green',
    DEFAULT_TEXT_COLOR,
    DEFAULT_BACKGROUND_COLOR,
    DEFAULT_OUTLINE_COLOR,
    DEFAULT_SHADOW_COLOR,
    DEFAULT_SHADOW_COLOR,
    DEFAULT_SVG_IMAGE_COLOR,
  ],
  listOfStoredWatermarks: [],
  currentSelectedImageIndex: 0,
  currentIndex: 0,
  enableNextStep: false,
  isMyWatermarksModalVisible: false,
  isIconsModalVisible: false,
  backgroundColor: DEFAULT_BACKGROUND_COLOR,
  outline: DEFAULT_OUTLINE_COLOR,
  textShadow: null,
  logoShadow: null,
  repeatLogo: [],
  repeatText: [],
  showFreeUserNotificationFor: null,
  noOfImagesWaterMarked: 0,
  accountDetails: null,
  userHasSavedWatermark: false,
  isBold: false,
  isItalic: false,
  isUnderline: false,
  isPaidUser: false,
  showNotificationModal: false,
  noOfWaterMarksAdded: 0,
  showCancelSubscriptionConfirmation: false,
  imagesScaleInfo: [],
  resizeDimension: '',
  resizeOption: RESIZE_OPTIONS[0],
  resizeLabel: 'Do not Resize',
  showDownloadAllLoader: false,
  showSingleDownloadSpinner: false,
  currentImageIndex: -1,
  progressPercentage: '0%',
});

/**
 * allImageObjects is a array of objects with images details like
 * { srcImage: '', imageName: 'abc.jpg', processedImage: '', status:'processing'}
 */

export let allImageObjects = [];
export const addImage = (image) => {
  allImageObjects.push(image);
};

export const removeImage = (index) => {
  allImageObjects.splice(index, 1);
};
export const removeAllImage = () => {
  //allImageObjects = [];
  allImageObjects.length = 0;
};
export const deleteUserWatermark = (singleWatermark) => {
  const index = appState.listOfStoredWatermarks.indexOf(singleWatermark);
  if (index > -1) {
    appState.listOfStoredWatermarks.splice(index, 1);
  }
  //logic to be added to delete from firestore
};
export const deleteImage = (image) => {
  const index = appState.listOfAllUploadedImage.indexOf(image);
  if (index > -1) {
    appState.listOfAllUploadedImage.splice(index, 1);
  }
  // appState.listOfAllUploadedImage.splice(index, 1);
  //TODO: logic to be added to delete from firestore
};

export const deleteActiveWatermarkForAppState = (waterMarkId) => {
  const defaultWaterMarkIndex =
    appState.listOfDefaultWatermarkIds.indexOf(waterMarkId);
  const modifiedWaterMarkIndex =
    appState.listOfModifiedWatermarkIds.indexOf(waterMarkId);
  appState.modifiedWatermarksList.deleteDefaultWatermark(waterMarkId);
  appState.modifiedWatermarksList.deleteModifiedWatermark(waterMarkId);
  if (defaultWaterMarkIndex > -1) {
    appState.listOfDefaultWatermarkIds.splice(defaultWaterMarkIndex, 1);
  }
  if (modifiedWaterMarkIndex > -1) {
    appState.listOfModifiedWatermarkIds.splice(modifiedWaterMarkIndex, 1);
  }
};

export const freeUserPhotosLimits = (files) => {
  var user = firebase.auth().currentUser;
  if (user && user.paid_user === true) {
    return true;
  }
  //console.log(">>>-->", appState.listOfAllUploadedImage);
  if (
    appState.listOfAllUploadedImage &&
    appState.listOfAllUploadedImage.length > 0
  ) {
    // already one file in the list
    appState.showFreeUserNotificationFor = FREE_USER_PHOTOS_LIMIT;
    //return true;
  } else if (files && files.length > 1) {
    appState.showFreeUserNotificationFor = FREE_USER_PHOTOS_LIMIT;
    //return false;
  }
  return true;
};

export const clearAllData = () => {
  appState.listOfAllUploadedImage = [];
  appState.defaultWatermarksList = null;
  appState.modifiedWatermarksList = null;
  appState.listOfDefaultWatermarkIds = [];
  appState.listOfModifiedWatermarkIds = [];
  appState.listOfAllFinalImage = [];
  appState.listOfTextRepeatObj = [];
  appState.listOfLogoRepeatObj = [];
  appState.listOfTextAdded = [];
  appState.allAddedText = [];
  appState.allAddedLogos = [];
  appState.allAddedGroups = [];
  appState.selectedProperty = null;
  appState.imagesScaleInfo = [];
};
