import React from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import {
  appState,
  deleteActiveWatermarkForAppState,
} from '../../modals/basemodal';
import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_OUTLINE_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  SHADOW_X_OFFSET,
  SHADOW_Y_OFFSET,
  SHADOW_BLUR,
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SVG_IMAGE_COLOR,
} from '../../Constants';
import ColorPickerContainer from '../ColorPickerContainer';
import { fabric } from 'fabric';
import WmHereSlider from './WmHereSlider';
import DeleteSvgIcon from '../DeleteSvgIcon';
import WmHereSwitch from './WmHereSwitch';
import { cloneDeep } from 'lodash';

var variablePaddingForAngle = 110; // this value should be 75% of width (here width is 100 so value is 75).

const LogoProperty = observer(
  class LogoProperty extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        renderVal: 0,
        inputValue_Opacity: 1,
        activeColorPickerId: 0,
        shadowX: SHADOW_X_OFFSET,
        shadowY: SHADOW_Y_OFFSET,
        shadowBlur: SHADOW_BLUR,
        shadowColor: DEFAULT_SHADOW_COLOR,
        repeatPadding: 0,
        repeatAngle: 0,
        isRepeatEnabled: false,
        isShadowEnabled: false,
        isSvgFile: false,
      };

      const disposer = observe(appState, 'selectedLogo', (change) => {
        this.setLogoProperty();
      });
    }

    setLogoProperty() {
      if (appState.selectedLogo) {
        //console.log("SELECTED LOGO---> " + appState.selectedLogo.path)
        if (appState.selectedLogo.myid.match('svgLogo')) {
          this.setState({ isSvgFile: true });
        } else {
          this.setState({ isSvgFile: false });
        }

        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          let logoRepeatObj =
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid];
          let logoRepeatPadding =
            appState.repeatLogo[appState.selectedLogo.myid].padding;
          this.setState({
            isRepeatEnabled: logoRepeatObj.isRepeat,
            repeatPadding: logoRepeatPadding,
            repeatAngle: logoRepeatObj.angle,
          });
        } else {
          this.setState({
            isRepeatEnabled: false,
            repeatPadding: 0,
            repeatAngle: appState.selectedLogo.angle,
            inputValue_Opacity: appState.selectedLogo.opacity,
          });
        }
        let isShadow = appState.selectedLogo.shadow === null ? false : true;
        this.setState({ isShadowEnabled: isShadow });
        if (isShadow) {
          this.setState({
            shadowX: appState.selectedLogo.shadow.offsetX,
            shadowY: appState.selectedLogo.shadow.offsetY,
            shadowBlur: appState.selectedLogo.shadow.blur,
            shadowColor: appState.selectedLogo.shadow.color,
          });
        } else {
          this.setState({
            shadowX: SHADOW_X_OFFSET,
            shadowY: SHADOW_Y_OFFSET,
            shadowBlur: SHADOW_BLUR,
            shadowColor: DEFAULT_SHADOW_COLOR,
          });
        }
      }
    }

    componentDidMount() {
      this.hideColorPickerOnMouseOutsideClick();
      this.setLogoProperty();
    }

    // Same CODE in TextProperty and LogoProperty. (Because we need to change local state)
    hideColorPickerOnMouseOutsideClick() {
      var me = this;
      document
        .getElementById('root')
        .addEventListener('mousedown', bodyClick, false);
      function bodyClick(event) {
        var class_name = event.target.className;
        if (
          !(
            class_name === 'color-label' ||
            class_name === 'color-picker' ||
            class_name === 'color-block' ||
            class_name === 'color-strip'
          ) &&
          me.state.activeColorPickerId !== 0
        ) {
          me.setState({ activeColorPickerId: 0 });
          //console.log('====>2', event.target.className);
        }
      }
    }

    onOpacityChange = (value) => {
      var myCanvas = appState.canvasId;
      if (isNaN(value)) {
        return;
      }
      this.setState({ inputValue_Opacity: value });

      if (this.state.isRepeatEnabled) {
        appState.listOfLogoRepeatObj[appState.selectedLogo.myid].opacity =
          value;
      } else {
        appState.selectedLogo.opacity = value;
      }
      myCanvas.renderAll(myCanvas);
    };

    setColorProps = (color, keyIndex) => {
      //console.log("SHADOW COLOR PROPERTY", color, keyIndex)
      var myCanvas = appState.canvasId;
      this.setState({ shadowColor: color });
      const { shadowColor, shadowX, shadowY, shadowBlur } = this.state;
      if (keyIndex === 5) {
        var shadowClr =
          color + ' ' + shadowX + ' ' + shadowY + ' ' + shadowBlur;
        appState.selectedLogo.set('shadow', shadowClr);
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          appState.repeatLogo[appState.selectedLogo.myid].set(
            'shadow',
            shadowClr
          );
        }
      } else if (keyIndex === 6) {
        if (appState.selectedLogo.path) {
          appState.selectedLogo.set('fill', color);
        } else if (appState.selectedLogo._objects) {
          for (var i = 0; i < appState.selectedLogo._objects.length; i++) {
            appState.selectedLogo._objects[i].set('fill', color);
          }
        }
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          let repeatObj = appState.repeatLogo[appState.selectedLogo.myid];
          if (repeatObj.path) {
            repeatObj.set('fill', color);
          } else if (repeatObj._objects) {
            for (var i = 0; i < repeatObj._objects.length; i++) {
              repeatObj._objects[i].set('fill', color);
            }
          }
        }
      }
      myCanvas.renderAll(myCanvas);
    };

    onShadowPropertyChange = (value, key) => {
      //console.log("SHADOW PROPERTY", value, key)
      var myCanvas = appState.canvasId;
      var { shadowColor, shadowX, shadowY, shadowBlur } = this.state;
      if (isNaN(value)) {
        return;
      }
      if (key === 'blur') {
        this.setState({ shadowBlur: value });
        shadowBlur = value;
      } else if (key === 'shadowX') {
        this.setState({ shadowX: value });
        shadowX = value;
      } else if (key === 'shadowY') {
        this.setState({ shadowY: value });
        shadowY = value;
      }
      //console.log("#####################",shadowColor)
      var shadowClr =
        shadowColor + ' ' + shadowX + ' ' + shadowY + ' ' + shadowBlur;
      appState.selectedLogo.set('shadow', shadowClr);
      if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
        appState.repeatLogo[appState.selectedLogo.myid].set(
          'shadow',
          shadowClr
        );
      }
      myCanvas.renderAll(myCanvas);
    };

    hideAndShowObject(oldObject, visibility, type) {
      //console.log("hide and show object", oldObject, visibility, type)
      var myCanvas = appState.canvasId;

      if (oldObject) {
        if (visibility === 'hide') {
          oldObject.opacity = 0;
          oldObject.isHidden = true;
          oldObject.selectable = false;
          myCanvas.discardActiveObject(oldObject); // after repeat on property window getting hidden, We cant select repeated.
          if (type === 'repeatedLogo') {
            oldObject.height = 0; // To Hide Repeate Object.
            oldObject.width = 0;
            myCanvas.remove(oldObject);
          } else {
            myCanvas.sendToBack(oldObject);
          }
        } else if (visibility === 'show') {
          oldObject.opacity = 1;
          oldObject.isHidden = false;
          oldObject.selectable = true;
          if (type !== 'repeatedLogo') {
            myCanvas.bringToFront(oldObject);
          } else if (type === 'repeatedLogo') {
            // To Show hiddden Repeated Object.
            oldObject.height = appState.listOfAllUploadedImage[0].height * 6;
            oldObject.width = appState.listOfAllUploadedImage[0].width * 6;
          }
          this.updateDefaultWatermark(oldObject);
          myCanvas.setActiveObject(oldObject);
        }
        myCanvas.renderAll(myCanvas);
      }
    }

    onShadowEnable = (isChecked) => {
      var myCanvas = appState.canvasId;
      this.setState({ isShadowEnabled: isChecked });
      var shadowClr = DEFAULT_SHADOW_COLOR + ' ' + 10 + ' ' + 10 + ' ' + 10;
      if (isChecked) {
        var bgColor =
          appState.logoShadow !== null ? appState.logoShadow : shadowClr;
        appState.selectedLogo.set('shadow', bgColor);
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          appState.repeatLogo[appState.selectedLogo.myid].set(
            'shadow',
            bgColor
          );
        }
      } else {
        appState.logoShadow = appState.selectedLogo.shadow;
        appState.selectedLogo.set('shadow', null);

        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          appState.repeatLogo[appState.selectedLogo.myid].shadow = null;
        }
      }
      myCanvas.renderAll(myCanvas);
    };

    onRepeatEnable = (isChecked) => {
      this.setState({ isRepeatEnabled: isChecked, inputValue_Opacity: 1 });
      appState.activeObjectType = 'logo';
      var myCanvas = appState.canvasId;
      if (
        isChecked &&
        !appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
      ) {
        if (appState.selectedLogo.myid.match('logo')) {
          var imgDataSrc = appState.selectedLogo._element.src;
          this.setState({ repeatPadding: 0, repeatAngle: appState.selectedLogo.angle });
          var me = this;
          var imgObj = new Image();
          imgObj.src = imgDataSrc;
          imgObj.onload = function () {
            var image = new fabric.Image(imgObj);
            image.set({
              left: 100,
              top: 100,
              shadow: appState.selectedLogo.shadow,
              lockUniScaling: true,
              hasRotatingPoint: false,
              padding: me.state.repeatPadding,
            });
            appState.repeatLogo[appState.selectedLogo.myid] = image;
            var patternSourceCanvas = new fabric.StaticCanvas();
            patternSourceCanvas.add(image);
            patternSourceCanvas.renderAll();

            var pattern = new fabric.Pattern({
              source: function () {
                patternSourceCanvas.setDimensions({
                  width:
                    image.getScaledWidth() +
                    image.padding +
                    variablePaddingForAngle +
                    me.state.shadowX,
                  height:
                    image.getScaledHeight() +
                    image.padding +
                    variablePaddingForAngle +
                    me.state.shadowY,
                });
                patternSourceCanvas.renderAll();
                return patternSourceCanvas.getElement();
              },
              repeat: 'repeat',
            });

            var previous_repeatLogoObj =
              appState.listOfLogoRepeatObj[appState.selectedLogo.myid];
            // create a rectangle object
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid] =
              new fabric.Rect({
                fill: pattern,
                width: appState.listOfAllUploadedImage[0].width * 6,
                height: appState.listOfAllUploadedImage[0].height * 6,
                objectCaching: false,
                originX: 'center',
                originY: 'center',
                left: 0,
                top: 0, //                 // very imp
                myid: appState.selectedLogo.myid,
                isHidden: false,
                angle: me.state.repeatAngle,
                isRepeat: true,
              });

            if (!previous_repeatLogoObj) {
              myCanvas.add(
                appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
              );
              myCanvas.sendToBack(
                appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
              );
            }
            me.hideAndShowObject(appState.selectedLogo, 'hide', 'logo'); // to execute below fn its taking some mili seconds
            me.hideAndShowObject(
              appState.listOfLogoRepeatObj[appState.selectedLogo.myid],
              'show',
              'repeatedLogo'
            );
          };
        } else if (appState.selectedLogo.myid.match('svgLogo')) {
          var me = this;
          this.setState({ repeatPadding: 0, repeatAngle: appState.selectedLogo.angle });
          let clonedSvgImg = cloneDeep(appState.selectedLogo);
          clonedSvgImg.set({
            left: 100,
            top: 100,
            shadow: appState.selectedLogo.shadow,
            padding: me.state.repeatPadding,
          });

          appState.repeatLogo[appState.selectedLogo.myid] = clonedSvgImg;
          var patternSourceCanvas = new fabric.StaticCanvas();
          patternSourceCanvas.add(clonedSvgImg);
          patternSourceCanvas.renderAll();

          var pattern = new fabric.Pattern({
            source: function () {
              patternSourceCanvas.setDimensions({
                width:
                  clonedSvgImg.getScaledWidth() +
                  clonedSvgImg.padding +
                  variablePaddingForAngle +
                  me.state.shadowX,
                height:
                  clonedSvgImg.getScaledHeight() +
                  clonedSvgImg.padding +
                  variablePaddingForAngle +
                  me.state.shadowY,
              });
              patternSourceCanvas.renderAll();
              return patternSourceCanvas.getElement();
            },
            repeat: 'repeat',
          });
          var previous_repeatLogoObj =
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid];
          // create a rectangle object
          appState.listOfLogoRepeatObj[appState.selectedLogo.myid] =
            new fabric.Rect({
              fill: pattern,
              width: appState.listOfAllUploadedImage[0].width * 6,
              height: appState.listOfAllUploadedImage[0].height * 6,
              objectCaching: false,
              originX: 'center',
              originY: 'center',
              left: 0,
              top: 0, //                 // very imp
              myid: appState.selectedLogo.myid,
              isHidden: false,
              angle: me.state.repeatAngle,
              isRepeat: true,
            });

          if (!previous_repeatLogoObj) {
            myCanvas.add(
              appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
            );
            myCanvas.sendToBack(
              appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
            );
          }
          me.hideAndShowObject(appState.selectedLogo, 'hide', 'logo'); // to execute below fn its taking some mili seconds
          me.hideAndShowObject(
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid],
            'show',
            'repeatedLogo'
          );
        }
      }
      if (isChecked === false) {
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid] !== null) {
          appState.listOfLogoRepeatObj[
            appState.selectedLogo.myid
          ].isRepeat = false; //  !== null
          this.hideAndShowObject(
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid],
            'hide',
            'repeatedLogo'
          );
          this.hideAndShowObject(appState.selectedLogo, 'show', 'logo');
          appState.listOfLogoRepeatObj[appState.selectedLogo.myid] = null; // repeatedLogo object is removed in hideAndShowObject()
        }
      } else if (isChecked === true) {
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid] !== null) {
          this.hideAndShowObject(appState.selectedLogo, 'hide', 'logo'); // Hide then Show
          this.hideAndShowObject(
            appState.listOfLogoRepeatObj[appState.selectedLogo.myid],
            'show',
            'repeatedLogo'
          );
        }
      }
      myCanvas.renderAll(myCanvas);
    };

    onRepeatPropertyChange = (value, key) => {
      var myCanvas = appState.canvasId;
      if (key === 'padding') {
        this.setState({ repeatPadding: value });
        if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
          appState.repeatLogo[appState.selectedLogo.myid].padding = value;
        }
      }
      myCanvas.renderAll(myCanvas);
    };

    onAngleChange = (value) => {
      var myCanvas = appState.canvasId;
      this.setState({ repeatAngle: value });
      appState.selectedLogo.angle = value;
      if(appState.listOfLogoRepeatObj && appState.listOfLogoRepeatObj[appState.selectedLogo.myid]){
        appState.listOfLogoRepeatObj[appState.selectedLogo.myid].angle = value;
      }
      myCanvas.renderAll(myCanvas);
    }

    activeColorPicker = (activeIndex) => {
      this.setState({ activeColorPickerId: activeIndex });
    };

    deleteLogoObject = () => {
      var myCanvas = appState.canvasId;
      myCanvas.remove(appState.selectedLogo);
      if (appState.listOfLogoRepeatObj[appState.selectedLogo.myid]) {
        myCanvas.remove(
          appState.listOfLogoRepeatObj[appState.selectedLogo.myid]
        );
      }
      if (appState.selectedLogo.myid.match('group') !== true) {
        appState.noOfWaterMarksAdded = appState.noOfWaterMarksAdded - 1;
      }
      deleteActiveWatermarkForAppState(appState.selectedLogo.myid);
      myCanvas.renderAll(myCanvas);
    };

    updateDefaultWatermark(updatedLogo) {
      if (updatedLogo) {
        if (
          appState.listOfDefaultWatermarkIds.indexOf(updatedLogo.myid) > -1 &&
          appState.modifiedWatermarksList
        ) {
          appState.modifiedWatermarksList._defaultWatermarks._allWatermarks[
            updatedLogo.myid
          ] = updatedLogo;
        }
      }
    }

    render() {
      const {
        inputValue_Opacity,
        shadowBlur,
        shadowX,
        shadowY,
        activeColorPickerId,
        repeatPadding,
        repeatAngle,
      } = this.state;

      return appState.selectedProperty === 'logo' ? (
        <div
          className=" w-full mr-5 mt-2 border border-gray-400 rounded-lg p-2"
          key={this.state.renderVal}
        >
          <div className="flex-1 bg-gray-100">
            <WmHereSlider
              title="Opacity"
              min={0}
              max={1}
              step={0.1}
              onChange={this.onOpacityChange}
              value={
                typeof inputValue_Opacity === 'number' ? inputValue_Opacity : 0
              }
            />
            <WmHereSlider
                  title="Rotate"
                  min={0}
                  max={360}
                  step={1}
                  onChange={(e) => this.onAngleChange(e, 'angle')}
                  value={typeof repeatAngle === 'number' ? repeatAngle : 0}
                />
            {this.state.isSvgFile && (
              <div>
                <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>
                <tr className="flex w-full min-w-full">
                  <td className="min-w-1/2 w-1/2 text-gray-800 mr-2">Color</td>
                  <td
                    className={
                      'min-w-1/2 w-1/2 ml-auto relative logo-color-picker'
                    }
                  >
                    <ColorPickerContainer
                      key="6"
                      pickerId={6}
                      activeColorPicker={this.activeColorPicker}
                      activeColorPickerId={activeColorPickerId}
                      setColorProps={this.setColorProps}
                    />
                  </td>
                </tr>
              </div>
            )}
            <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>
            <tr className="flex w-full justify-between min-w-full mt-2">
              <td className="font-bold min-w-1/2 w-1/2 text-gray-800">
                Shadow
              </td>
              <td>
                <WmHereSwitch
                  onChange={this.onShadowEnable}
                  checked={this.state.isShadowEnabled}
                />
              </td>
              {this.state.isShadowEnabled && (
                <td className="min-w-1/4 w-1/4 relative logo-color-picker">
                  <ColorPickerContainer
                    key="5"
                    pickerId={5}
                    activeColorPicker={this.activeColorPicker}
                    activeColorPickerId={activeColorPickerId}
                    setColorProps={this.setColorProps}
                  />
                </td>
              )}
            </tr>
            {this.state.isShadowEnabled ? (
              <div className="bg-gray-200 rounded-md">
                <WmHereSlider
                  className="mt-1"
                  title="Blur"
                  min={0}
                  max={100}
                  step={1}
                  onChange={(e) => this.onShadowPropertyChange(e, 'blur')}
                  value={typeof shadowBlur === 'number' ? shadowBlur : 0}
                />
                <WmHereSlider
                  className="mt-1"
                  title="X-Offset"
                  min={0}
                  max={100}
                  step={1}
                  onChange={(e) => this.onShadowPropertyChange(e, 'shadowX')}
                  value={typeof shadowX === 'number' ? shadowX : 0}
                />
                <WmHereSlider
                  className="mt-1"
                  title="Y-Offset"
                  min={0}
                  max={100}
                  step={1}
                  onChange={(e) => this.onShadowPropertyChange(e, 'shadowY')}
                  value={typeof shadowY === 'number' ? shadowY : 0}
                />
              </div>
            ) : null}
            <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>
            <tr className="flex w-full justify-between min-w-full mt-2">
              <td className="font-bold min-w-1/2 w-1/2 text-gray-800">
                Repeat
              </td>
              <td>
                <WmHereSwitch
                  onChange={this.onRepeatEnable}
                  checked={this.state.isRepeatEnabled}
                />
              </td>
            </tr>
            {this.state.isRepeatEnabled ? (
              <div className="bg-gray-200 rounded-md">
                <WmHereSlider
                  title="Padding"
                  min={0}
                  max={150}
                  step={1}
                  onChange={(e) => this.onRepeatPropertyChange(e, 'padding')}
                  value={typeof repeatPadding === 'number' ? repeatPadding : 0}
                />
              </div>
            ) : null}
            <div className="flex justify-center">
              <button
                onClick={this.deleteLogoObject}
                className="flex items-center mt-4 mb-2 w-100 flex justify-center font-medium bg-red-700 hover:bg-red-800 text-white p-2 focus:outline-none rounded-lg"
              >
                {/* <DeleteSvgIcon className={"pointer-events-none w-6 h-6 mr-1 text-white"} ></DeleteSvgIcon> */}
                Delete{' '}
              </button>
            </div>
          </div>
        </div>
      ) : null;
    }
  }
);

export default LogoProperty;
