import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import firebase from 'firebase';
import { fabric } from 'fabric';
import { cloneDeep } from 'lodash';
import { resizeByWidth } from '../../modals/resize';
import { RESIZE_OPTIONS } from '../../Constants';
import ReactModal from 'react-modal';
import { observe } from 'mobx';
import AcknowledgeModal from '../AddPhotos/AcknowledgeModal';
import { FREE_MY_WATERMARKS_LIMIT } from '../../Constants';
import FreeUserLimitDialog from '../FreeUserLimitDialog';

import {
  resizeNone,
  handleCancel,
  downloadImage,
  downloadAsZip,
} from './utils';

const DownloadImage = observer(
  class DownloadImage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showResizeModal: false,
      };

      observe(appState, 'noOfImagesWaterMarked', (change) => {
        let percentage =
          (appState.noOfImagesWaterMarked /
            appState.listOfAllUploadedImage.length) *
          100;
        let percentageAsString = percentage + '%';
        appState.progressPercentage = percentageAsString;
      });
    }

    customStyles = {
      content: {
        top: '45%',
        left: '50%',
        right: '95%',
        bottom: '15%',
        marginRight: '-80%',
        transform: 'translate(-50%, -60%)',
        borderRadius: '15px',
        opacity: 1,
        height: 350,
      },
      overlay: {
        backgroundColor: '#9298a6d9',
      },
    };

    componentDidMount() {
      if (appState.resizeOption === RESIZE_OPTIONS[0]) {
        resizeNone();
      }
    }

    onOptionChange = (value) => {
      if (!appState.loggedInUser || !appState.isPaidUser) {
        this.setState({ showResizeModal: true });
        return;
      }
      appState.resizeOption = value;
      if (value === RESIZE_OPTIONS[3]) {
        appState.resizeDimension = 50;
        appState.resizeLabel = value + ' (%) :';
      } else if (value === RESIZE_OPTIONS[1] || value === RESIZE_OPTIONS[2]) {
        appState.resizeDimension = 1024;
        appState.resizeLabel = value + ' (px) :';
      } else if (value === RESIZE_OPTIONS[0]) {
        appState.resizeLabel = 'Do not Resize';
        resizeNone();
      }
    };

    onInputChange = (value) => {
      appState.resizeDimension = value;
    };

    async saveUserWatermark() {
      if (!appState.isPaidUser){
        appState.showFreeUserNotificationFor = FREE_MY_WATERMARKS_LIMIT;

      }
      else{
      var watermarkObjectsList = [];
      if (
        appState.listOfDefaultWatermarkIds.length > 0 &&
        appState.noOfWaterMarksAdded > 0
      ) {
        this.setState({ loading: true });
        appState.listOfDefaultWatermarkIds.forEach((event) => {
          let eachWatermark = cloneDeep(
            appState.modifiedWatermarksList.getDefaultWatermarkByKey(event)
          );
          watermarkObjectsList.push(eachWatermark);
        });
        var allTogetherWatermarkObj = new fabric.Group(watermarkObjectsList);
        allTogetherWatermarkObj._calcBounds();
        allTogetherWatermarkObj.setCoords();
        allTogetherWatermarkObj.set({
          top: 0,
          left: 0,
        });
        let clonedAllTogetherWatermarkObj = cloneDeep(allTogetherWatermarkObj);
        let allWatermarkObjectAsSvg = allTogetherWatermarkObj.toSVG();
        let startSvg =
          '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" ' +
          'xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:xlink="http://www.w3.org/1999/xlink" ' +
          'width="' +
          allTogetherWatermarkObj.width +
          '" height="' +
          allTogetherWatermarkObj.height +
          '">';
        let endSvg = '</svg>';

        let finalSvg = startSvg + allWatermarkObjectAsSvg + endSvg;

        var originalImageURL =
          'data:image/svg+xml;utf8,' + encodeURIComponent(finalSvg);
        let originalWatermarkData = {
          url: originalImageURL,
          width: allTogetherWatermarkObj.width,
          height: allTogetherWatermarkObj.height,
        };

        let img = new Image();
        img.src = clonedAllTogetherWatermarkObj.toDataURL('image/png', 1.0);
        img.onload = await function () {};
        let thumbnailImage = await resizeByWidth(100, img);
        let thumbnailWatermarkData = {
          url: thumbnailImage.url,
          width: thumbnailImage.resizedWidth,
          height: thumbnailImage.resizedHeight,
        };
        this.uploadWatermarkInStorage(
          originalWatermarkData,
          thumbnailWatermarkData
        );
      }
    }
    }

    uploadWatermarkInStorage(originalImageToUpload, thumbnailImageToUpload) {
      let storageRef = firebase.storage().ref();
      let today = new Date();
      let currentDataTime =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate() +
        '-' +
        today.getHours() +
        '-' +
        today.getMinutes() +
        '-' +
        today.getSeconds();
      let user = firebase.auth().currentUser;
      if (user) {
        let fileNameOnly = '/WM/' + currentDataTime;
        let waterMarkData = {
          path: fileNameOnly,
          originalWatermarkWidth: originalImageToUpload.width,
          originalWatermarkHeight: originalImageToUpload.height,
          thumbnailWatermarkWidth: thumbnailImageToUpload.width,
          thumbnailWatermarkHeight: thumbnailImageToUpload.height,
        };
        let storagePath = 'users/' + user.uid + fileNameOnly;
        let originalImageStoragePath = storagePath + '.svg';
        let thumbnailImageStoragePath = storagePath + '-thumbnail.png';

        let originalImageStorageRef = storageRef.child(
          originalImageStoragePath
        );
        let thumbnailImageStorageRef = storageRef.child(
          thumbnailImageStoragePath
        );

        originalImageStorageRef
          .putString(originalImageToUpload.url, 'data_url')
          .then(function (snapshot) {
            console.log('Uploaded a main svg to storage!');
          });

        thumbnailImageStorageRef
          .putString(thumbnailImageToUpload.url, 'data_url', {
            contentType: 'image/png',
          })
          .then(function (snapshot) {
            console.log('Uploaded a thumbnail img to storage!');
          });

        var userRef = firebase.firestore().collection('users').doc(user.uid);
        userRef.get().then(function (userInfo) {
          if (userInfo.exists) {
            let watermarkArray = userInfo.data().userWatermarks;
            if (!watermarkArray) {
              watermarkArray = [];
            }
            watermarkArray.push(waterMarkData);
            userRef.update({ userWatermarks: watermarkArray });
          }
        });
      }
      this.setState({ loading: false });
      appState.showNotificationModal = true;
    }

    render() {
      let listOfDownloadUrl = null;
      if (appState.listOfAllFinalImage.length > 0) {
        listOfDownloadUrl = appState.listOfAllFinalImage.map(
          (finalImage, index) => (
            <button
              className={`text-gray-700 flex justify-between items-center bg-gray-200 font-bold w-full my-1 h-8 focus:outline-none hover:bg-gray-300 rounded text-sm xl:text-xs lg:text-xs 2xl:text-sm px-2 ${
                appState.listOfAllFinalImage.length - 1 !== index
                  ? 'border-b border-gray-400'
                  : ''
              }`}
              onClick={() => downloadImage(finalImage, index)}
            >
              <div className="w-100">
                {finalImage.fileName.length > 20
                  ? finalImage.fileName.substring(0, 20) + '...'
                  : finalImage.fileName}
              </div>
              <div className="flex justify-end w-1/4 min-w-1/4">
                {appState.showSingleDownloadSpinner &&
                appState.currentImageIndex === index ? (
                  <svg
                    className="w-5 h-5 mt-1 mr-3 -ml-1 animate-spin text-gray"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                ) : null}
                <button className="max-w-1/3" type="button">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-700 cursor-pointer hover:text-gray-800 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </button>
              </div>
            </button>
          )
        );
      }
      return (
        <>
          <ReactModal
            isOpen={appState.showDownloadAllLoader}
            style={this.customStyles}
          >
            <div className="mx-24 my-8">
              <div className="relative pt-9">
                <span className="inline-block px-2 py-1 font-semibold uppercase bg-gray-200 rounded-lg text-m text-white-600">
                  Processing
                </span>

                <div className="pt-1 text-right">
                  <span className="inline-block text-lg font-semibold text-black-600">
                    {appState.noOfImagesWaterMarked}/
                    {appState.listOfAllUploadedImage.length}
                  </span>
                </div>

                <div className="flex h-3 mb-4 overflow-hidden text-xs bg-gray-400 rounded-lg">
                  <div
                    style={{ width: appState.progressPercentage }}
                    className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
                  ></div>
                </div>

                <div className="pt-9">
                  <button
                    className="float-right w-24 px-2 py-1 text-white bg-red-700 border border-red-500 rounded-lg shadow"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>

          <div className="flex flex-col p-4 bg-white border border-gray-300 h-3/4 rounded-xl">
            <div className="w-full mb-2 bg-white rounded-md">
              <div className="flex items-center">
                <label
                  className="text-sm font-bold tracking-wide text-gray-700 xl:text-xs lg:text-xs 2xl:text-sm"
                  htmlFor="grid-state"
                >
                  Resize Images :
                </label>

                <div className="relative items-center w-1/2 ">
                  <select
                    value={appState.resizeOption}
                    onChange={(event) =>
                      this.onOptionChange(event.target.value)
                    }
                    className="w-1/2 h-8 px-3 py-1 ml-2 text-sm text-justify text-gray-800 bg-white border rounded outline-none lg:w-1/2 xl:text-xs lg:text-xs 2xl:text-sm"
                  >
                    <option className="py-1">
                      {appState.isPaidUser && appState.loggedInUser
                        ? RESIZE_OPTIONS[0]
                        : '700px'}
                    </option>
                    <option className="py-1">{RESIZE_OPTIONS[1]}</option>
                    <option className="py-1">{RESIZE_OPTIONS[2]}</option>
                    <option className="py-1">{RESIZE_OPTIONS[3]}</option>
                  </select>
                  {this.state.showResizeModal && (
                    <AcknowledgeModal
                      heading="Upgrade to Premium"
                      content="To resize your images, consider upgrading to premium membership."
                      onOkClick={() =>
                        this.setState({ showResizeModal: false })
                      }
                    />
                  )}
                </div>
              </div>

              {appState.resizeLabel !== 'Do not Resize' ? (
                <div className="flex items-center p-2 lg:px-0">
                  <label
                    className="text-sm font-bold tracking-wide text-gray-700 xl:text-xs lg:text-xs 2xl:text-sm lg:font-hairline"
                    for="Width"
                  >
                    {appState.resizeLabel}
                  </label>
                  <div className="w-1/2">
                    <input
                      value={appState.resizeDimension}
                      onChange={(event) =>
                        this.onInputChange(event.target.value)
                      }
                      className="w-20 px-3 py-1 ml-2 leading-tight text-gray-700 border rounded appearance-none lg:text-sm focus:outline-none focus:shadow-md"
                      id="Width"
                      type="text"
                    />
                  </div>
                </div>
              ) : (
                <div className="min-h-20"></div>
              )}
            </div>
            <div className="w-full overflow-y-auto bg-gray-200 border border-gray-400 rounded-md">
              {listOfDownloadUrl}
            </div>

            {appState.listOfAllFinalImage &&
            appState.listOfAllFinalImage.length > 0 ? (
              <div>
                <button
                  onClick={() => downloadAsZip()}
                  className="flex inline-flex items-center justify-center w-full px-6 py-2 mt-3 mb-1 mr-1 text-sm font-bold text-black bg-yellow-300 rounded-md hover:bg-yellow-500 focus:outline-none"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-8 h-8 text-gray-700 cursor-pointer xl:w-6 2xl:w-8 xl:h-6 2xl:h-8 hover:text-gray-800 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>{' '}
                  <span className="pl-2">Download All</span>
                </button>
                <p className="mt-1 text-xs text-center">
                  All images will be downloaded as a ZIP file
                </p>
                <hr className="mt-3 mb-1 border border-gray-300" />
                <button
                  className="inline-flex items-center justify-center w-full px-6 py-3 mt-3 mb-1 mr-1 text-sm font-bold text-black bg-blue-400 rounded-md xl:py-2 2xl:py-3 hover:bg-blue-500 focus:outline-none"
                  onClick={() => this.saveUserWatermark()}
                >
                  Save Watermarks
                </button>
                {appState.showNotificationModal && (
                  <AcknowledgeModal
                    heading="Your Watermarks Have Been Saved"
                    content="The watermarks you've added have been saved. You can reuse them next time you visit WatermarkHere."
                  />
                )}
                <p className="mt-1 text-xs text-center">
                  Watermarks on current images will be stored for future use
                </p>
                <FreeUserLimitDialog />
              </div>
            ) : null}
            <div id="offCanvas_div">
              <canvas id="offCanvas"></canvas>
            </div>
            <div id="offCanvas_div">
              <canvas id="logoCanvas"></canvas>
            </div>
          </div>
        </>
      );
    }
  }
);

export default DownloadImage;
