import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { appState } from '../../modals/basemodal';
import { saveAs } from 'file-saver';
import { RESIZE_OPTIONS } from '../../Constants';
import { addWaterMarkForEachImage, addWaterMarkToAllImage } from './AddProps';
import {
  calculateDimForGivenPercent,
  getDisabledDimensionValue,
} from '../../modals/resize';

function onResize() {
  if (!appState.isPaidUser || !appState.loggedInUser) {
    resizeByWidth(700);
    return;
  }
  if (appState.resizeDimension === '') {
    resizeNone();
  } else {
    if (appState.resizeOption === RESIZE_OPTIONS[1]) {
      resizeByWidth(appState.resizeDimension);
    } else if (appState.resizeOption === RESIZE_OPTIONS[2]) {
      resizeByHeight(appState.resizeDimension);
    } else if (appState.resizeOption === RESIZE_OPTIONS[3]) {
      resizeByPercentage(appState.resizeDimension);
    } else {
      resizeNone();
    }
  }
}

export function resizeNone() {
  appState.listOfAllFinalImage = [];
  appState.listOfAllUploadedImage.forEach((imgObj, index) => {
    let resizedImage = {
      imgTag: imgObj.imgTag,
      src: imgObj.src,
      index: index,
      newWidth: imgObj.width,
      newHeight: imgObj.height,
      fileName: imgObj.fileName,
      fileType: imgObj.fileType,
    };
    appState.listOfAllFinalImage.push(resizedImage);
  });
}

function resizeByWidth(width) {
  appState.listOfAllUploadedImage.forEach((imgObj, index) => {
    const resizeImageIndex = appState.listOfAllUploadedImage.indexOf(imgObj);
    let height = '';
    height = getDisabledDimensionValue(width, imgObj.width, imgObj.height);
    if (resizeImageIndex > -1) {
      appState.listOfAllFinalImage[resizeImageIndex].resizeWidth = width;
      appState.listOfAllFinalImage[resizeImageIndex].resizeHeight = height;
    }
  });
}

function resizeByHeight(height) {
  appState.listOfAllUploadedImage.forEach((imgObj, index) => {
    const resizeImageIndex = appState.listOfAllUploadedImage.indexOf(imgObj);
    let width = '';
    width = getDisabledDimensionValue(height, imgObj.height, imgObj.width);
    if (resizeImageIndex > -1) {
      appState.listOfAllFinalImage[resizeImageIndex].resizeWidth = width;
      appState.listOfAllFinalImage[resizeImageIndex].resizeHeight = height;
    }
  });
}

function resizeByPercentage(percentage) {
  appState.listOfAllUploadedImage.forEach((imgObj, index) => {
    const resizeImageIndex = appState.listOfAllUploadedImage.indexOf(imgObj);
    let height = '';
    let width = '';
    height = calculateDimForGivenPercent(imgObj.height, percentage);
    width = calculateDimForGivenPercent(imgObj.width, percentage);
    if (resizeImageIndex > -1) {
      appState.listOfAllFinalImage[resizeImageIndex].resizeWidth = width;
      appState.listOfAllFinalImage[resizeImageIndex].resizeHeight = height;
    }
  });
}

export function handleCancel() {
  onResize();
  appState.showDownloadAllLoader = false;
}

export async function downloadImage(finalImage, imageIndex) {
  appState.showSingleDownloadSpinner = true;
  appState.currentImageIndex = imageIndex;
  await onResize();
  let imageUrl = await addWaterMarkForEachImage(imageIndex);
  if (imageUrl) {
    let tempLink = document.createElement('a');
    tempLink.href = imageUrl;
    tempLink.download = finalImage.fileName;
    tempLink.click();
    appState.showSingleDownloadSpinner = false;
  }
}

export async function downloadAsZip() {
  appState.noOfImagesWaterMarked = 0;
  appState.showDownloadAllLoader = true;
  await onResize();
  let listOfAllFinalImage = await addWaterMarkToAllImage();
  var zipFilename = 'WatermarkHere.zip';
  var zip = new JSZip();
  var count = 0;
  if (listOfAllFinalImage && listOfAllFinalImage.length > 0) {
    var main = this;
    listOfAllFinalImage.forEach((finalImage) => {
      var filename = finalImage.name;
      let blob = fetch(finalImage.url).then((r) => r.blob());
      JSZipUtils.getBinaryContent(finalImage.url, function (err, data) {
        if (err) {
          throw err;
        }
      });
      zip.file(filename + '.jpeg', blob, { binary: true });
      count++;
      if (
        count == listOfAllFinalImage.length &&
        appState.showDownloadAllLoader === true
      ) {
        zip.generateAsync({ type: 'blob' }).then(function (content) {
          appState.showDownloadAllLoader = false
          saveAs(content, zipFilename);
        });
      }
    });
  }
}
