import { appState } from '../../modals/basemodal';
import { cloneDeep } from 'lodash';
import { IMAGE_COMPRESS_VALUE } from '../../Constants';

// Add or remove watermark for free users
const ADD_WATERMARK_FREE_USERS = false;

export async function addWaterMarkForEachImage(imageIndex) {
  let image = appState.listOfAllFinalImage[imageIndex];
  let dataUrl = await addWaterMarkToGivenImage(image);
  return dataUrl;
}

export async function addWaterMarkToGivenImage(image) {
  let canvas = document.getElementById('offCanvas');
  let ctx = canvas.getContext('2d');
  let scaledInfo = appState.imagesScaleInfo[image.index];
  let oldImageHeight = image.newHeight * scaledInfo.scale;
  let oldImageWidth = image.newWidth * scaledInfo.scale;
  let resizedWidth = image.resizeWidth;
  let resizedHeight = image.resizeHeight;
  let newImageWidth = image.newWidth;
  let newImageHeight = image.newHeight;
  if (resizedWidth !== undefined || resizedHeight !== undefined) {
    newImageWidth = resizedWidth;
    newImageHeight = resizedHeight;
  }
  await loadImage(image.imgTag.src).then((img) => {
    canvas.height = newImageHeight;
    canvas.width = newImageWidth;
    ctx.drawImage(img, 0, 0, newImageWidth, newImageHeight);
  });
  await setPropsOnCanvas(
    canvas,
    image,
    oldImageWidth,
    oldImageHeight,
    newImageWidth,
    newImageHeight,
    ctx
  );
  let dataURL = canvas.toDataURL('image/jpeg', IMAGE_COMPRESS_VALUE);
  //console.log(dataURL);
  return dataURL;
}

export async function addWaterMarkToAllImage() {
  console.time('someFunction');
  let allImagesArr = appState.listOfAllFinalImage;
  let listOfFinalImage = [];
  for (let i = 0; i < allImagesArr.length; i++) {
    let image = allImagesArr[i];
    let url = await addWaterMarkToGivenImage(image);
    let finalImage = { name: image.fileName, url: url };
    listOfFinalImage.push(finalImage);
    appState.noOfImagesWaterMarked++;
  }
  return listOfFinalImage;
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

async function setPropsOnCanvas(
  myCanvas,
  image,
  oldImageWidth,
  oldImageHeight,
  newImageWidth,
  newImageHeight,
  ctx
) {
  if (myCanvas) {
    if (appState.listOfAllUploadedImage.length > 0) {
      if (appState.listOfDefaultWatermarkIds.length > 0) {
        console.time('someFunction');
        for (let i = 0; i < appState.listOfDefaultWatermarkIds.length; i++) {
          let event = appState.listOfDefaultWatermarkIds[i];
          let lastElement =
            appState.listOfDefaultWatermarkIds.length === i + 1 ? true : false;
          if (
            appState.modifiedWatermarksList &&
            appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
              image.index
            )
          ) {
            let props =
              appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
                image.index
              );
            if (!props[event]) {
              let obj = cloneDeep(
                appState.modifiedWatermarksList.getDefaultWatermarkByKey(event)
              );
              setScaledWidthAndHeightValues(obj, image.index);
              if (!obj.isRepeat) {
                setScaledLeftAndTopValues(obj, image.index);
              }
              await addWaterMark(
                obj,
                oldImageWidth,
                newImageWidth,
                oldImageHeight,
                newImageHeight,
                ctx,
                lastElement
              );
            }
          } else {
            let obj = cloneDeep(
              appState.modifiedWatermarksList.getDefaultWatermarkByKey(event)
            );
            setScaledWidthAndHeightValues(obj, image.index);
            if (!obj.isRepeat) {
              setScaledLeftAndTopValues(obj, image.index);
            }
            await addWaterMark(
              obj,
              oldImageWidth,
              newImageWidth,
              oldImageHeight,
              newImageHeight,
              ctx,
              lastElement
            );
          }
        }
      }

      if (
        appState.modifiedWatermarksList &&
        appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
          image.index
        )
      ) {
        console.time('someFunction');
        let props =
          appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
            image.index
          );
        for (let i = 0; i < appState.listOfModifiedWatermarkIds.length; i++) {
          let id = appState.listOfModifiedWatermarkIds[i];
          let lastElement =
            appState.listOfModifiedWatermarkIds === i + 1 ? true : false;
          if (props[id]) {
            let obj = cloneDeep(
              appState.modifiedWatermarksList.getDefaultWatermarkByKey(
                props[id].myid
              )
            );
            setScaledWidthAndHeightValues(obj, image.index);
            obj.left = props[id].left;
            obj.top = props[id].top;
            await addWaterMark(
              obj,
              oldImageWidth,
              newImageWidth,
              oldImageHeight,
              newImageHeight,
              ctx,
              lastElement
            );
          }
        }
      }
    }
  }
  if (ADD_WATERMARK_FREE_USERS) {
    await loadImage('watermark-white.png').then((img) => {
      let newWidth = getResizedWidth(img.width / 2, 1024, newImageWidth);
      let newHeight = getResizedHeight(img.height / 2, 768, newImageHeight);
      let top = newImageHeight - newHeight;
      let left = newImageWidth - newWidth;
      ctx.shadowColor = 'transparent';
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.drawImage(img, left, top, newWidth, newHeight);
    });
  }
}

async function addWaterMark(
  obj,
  oldImageWidth,
  newImageWidth,
  oldImageHeight,
  newImageHeight,
  ctx,
  lastElement
) {
  let top = getResizedTop(obj.top, oldImageHeight, newImageHeight);
  let left = getResizedLeft(obj.left, oldImageWidth, newImageWidth);
  let scaledWidth = obj.width;
  let scaledHeight = obj.height;
  let scaledStrokeWidth = obj.strokeWidth;
  if (obj.scaleX !== 1) {
    scaledWidth = obj.width * obj.scaleX;
    scaledStrokeWidth = getResizedWidth(
      obj.strokeWidth,
      obj.width,
      scaledWidth
    );
  }
  if (obj.scaleY !== 1) {
    scaledHeight = obj.height * obj.scaleY;
  }
  let newWidth = getResizedWidth(scaledWidth, oldImageWidth, newImageWidth);
  let newHeight = getResizedHeight(
    scaledHeight,
    oldImageHeight,
    newImageHeight
  );
  let scaledFont = getReducedFontSize(obj.fontSize, obj.width, scaledWidth);
  let fontSize = getReducedFontSize(scaledFont, oldImageWidth, newImageWidth);
  let strokeWidth = getReducedValue(
    scaledStrokeWidth,
    oldImageWidth,
    newImageWidth
  );
  let offsetX = 0;
  let offsetY = 0;
  let shadowBlur = 0;
  if (obj.shadow) {
    let scaledOffsetX = getResizedWidth(
      obj.shadow.offsetX,
      obj.width,
      scaledWidth
    );
    offsetX = getResizedLeft(scaledOffsetX, oldImageWidth, newImageWidth);
    let scaledOffsetY = getResizedHeight(
      obj.shadow.offsetY,
      obj.height,
      scaledHeight
    );
    offsetY = getResizedTop(scaledOffsetY, oldImageHeight, newImageHeight);
    shadowBlur = getReducedValue(obj.shadow.blur, obj.width, newWidth);
  }
  if (
    (obj.myid.match('text') && !obj.isRepeat) ||
    (obj.myid.match('fontAwesomeText') && !obj.isRepeat)
  ) {
    drawTextOnCanvas(
      obj,
      left,
      top,
      newWidth,
      newHeight,
      fontSize,
      strokeWidth,
      offsetX,
      offsetY,
      ctx,
      shadowBlur
    );
  } else if (obj.myid.match('logo') && !obj.isRepeat) {
    drawImageOnCanvas(
      obj,
      left,
      top,
      newWidth,
      newHeight,
      offsetX,
      offsetY,
      ctx,
      shadowBlur
    );
  } else if (obj.myid.match('group') && !obj.isRepeat) {
    let groupImg = obj.toDataURL('image/jpeg', 1.0);
    ctx.globalAlpha = obj.opacity;
    await loadImage(groupImg).then((img) => {
      ctx.shadowColor = 'transparent';
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      checkIfAngleChangedForImage(img, obj.angle, left, top, newWidth, newHeight, ctx)
    });
  } else if (obj.myid.match('svgLogo') && !obj.isRepeat) {
    let clonedSvgImage = cloneDeep(obj);
    clonedSvgImage.shadow = null;
    clonedSvgImage.opacity = 1;
    let svgImage = clonedSvgImage.toDataURL('image/jpeg', 1.0);
    await loadImage(svgImage).then((img) => {
      drawSvgOnCanvas(
        obj,
        left,
        top,
        newWidth,
        newHeight,
        offsetX,
        offsetY,
        ctx,
        img,
        shadowBlur
      );
    });
  } else {
    let startSvg =
      '<svg version="1.1" viewBox="' +
      0 +
      ' ' +
      0 +
      ' ' +
      newImageWidth +
      ' ' +
      newImageHeight +
      '" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">';
    let endSvg = '</svg>';
    let objSvg = obj.toSVG()
    let repeatScale = getScaledPercentageForRepeat(obj, oldImageWidth, oldImageHeight, newImageWidth, newImageHeight)
    objSvg = objSvg.replace('<g transform="', '<g transform="scale(' + repeatScale + ' ' + repeatScale + ')')
    let finalSvg = startSvg + objSvg + endSvg;
    let originalImageURL =
      'data:image/svg+xml;utf8,' + encodeURIComponent(finalSvg);
    await loadImage(originalImageURL).then((img) => {
      ctx.shadowColor = 'transparent';
      ctx.shadowBlur = 0;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 0;
      ctx.drawImage(img, 0, 0);
    });
  }
  if (lastElement) {
    await console.timeEnd('someFunction');
  }
}

function drawSvgOnCanvas(
  obj,
  left,
  top,
  width,
  height,
  offsetX,
  offsetY,
  ctx,
  img,
  shadowBlur
) {
  if (obj.shadow !== null) {
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.shadowBlur = shadowBlur;
    ctx.globalAlpha = obj.opacity;
    checkIfAngleChangedForImage(img, obj.angle, left, top, width, height, ctx)
  } else {
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    checkIfAngleChangedForImage(img, obj.angle, left, top, width, height, ctx)
  }
}

function drawImageOnCanvas(
  obj,
  left,
  top,
  width,
  height,
  offsetX,
  offsetY,
  ctx,
  shadowBlur
) {
  if (obj.shadow !== null) {
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    checkIfAngleChangedForImage(obj._originalElement, obj.angle, left, top, width, height, ctx)
  } else {
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    checkIfAngleChangedForImage(obj._originalElement, obj.angle, left, top, width, height, ctx)
  }
}

function drawTextOnCanvas(
  obj,
  left,
  top,
  width,
  height,
  font,
  strokeWidth,
  offsetX,
  offsetY,
  ctx,
  shadowBlur
) {
  let fontWithStyle =
    obj.fontWeight + ' ' + obj.fontStyle + ' ' + font + 'px ' + obj.fontFamily;
  if (
    obj.backgroundColor !== 'transparent' &&
    obj.stroke !== 'transparent' &&
    obj.shadow !== null
  ) {
    //Text with shadow, stroke, and bacground
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = obj.backgroundColor;
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    ctx.fill();
    ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.lineWidth = strokeWidth;
    ctx.globalAlpha = obj.opacity;
    ctx.strokeText(obj.text, 0, height / 1.25);
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor === 'transparent' &&
    obj.stroke === 'transparent' &&
    obj.shadow !== null
  ) {
    //Text with only Shadow
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = 'transparent';
    ctx.globalAlpha = obj.opacity;
    ctx.fill();
    ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor !== 'transparent' &&
    obj.stroke === 'transparent' &&
    obj.shadow !== null
  ) {
    //Text with shadow and bacground
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = obj.backgroundColor;
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    ctx.fill();
    ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor === 'transparent' &&
    obj.stroke !== 'transparent' &&
    obj.shadow !== null
  ) {
    //Text with shadow and stroke
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = 'transparent';
    ctx.globalAlpha = obj.opacity;
    ctx.fill();
    ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.shadowColor = obj.shadow.color;
    ctx.shadowBlur = shadowBlur;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.lineWidth = strokeWidth;
    ctx.globalAlpha = obj.opacity;
    ctx.strokeText(obj.text, 0, height / 1.25);
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor !== 'transparent' &&
    obj.stroke !== 'transparent' &&
    obj.shadow === null
  ) {
    //Text with stroke and bacground
    ctx.beginPath();
    ctx.save()
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.lineWidth = strokeWidth;
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = obj.backgroundColor;
    ctx.fill();
    //ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.shadowColor = 'transparent';

    ctx.globalAlpha = obj.opacity;
    ctx.strokeText(obj.text, 0, height / 1.25);
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor === 'transparent' &&
    obj.stroke !== 'transparent' &&
    obj.shadow === null
  ) {
    //Text with only stroke
    ctx.beginPath();
    ctx.save()
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.globalAlpha = obj.opacity;
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'transparent';
    ctx.fill();
    ctx.beginPath();
    ctx.strokeStyle = obj.stroke;
    ctx.fillStyle = obj.fill;
    ctx.font = fontWithStyle;
    ctx.lineWidth = strokeWidth;
    ctx.shadowColor = 'transparent';
    ctx.strokeText(obj.text, 0, height / 1.25);
    ctx.globalAlpha = obj.opacity;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else if (
    obj.backgroundColor !== 'transparent' &&
    obj.stroke === 'transparent' &&
    obj.shadow === null
  ) {
    //Text with only bacground
    ctx.beginPath();
    ctx.save()
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.globalAlpha = obj.opacity;
    ctx.fillStyle = obj.backgroundColor;
    ctx.fill();

    ctx.beginPath();
    ctx.fillStyle = obj.fill;
    ctx.globalAlpha = obj.opacity;
    ctx.font = fontWithStyle;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  } else {
    //Only Text
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = 'transparent';
    ctx.fill();

    ctx.beginPath();
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = offsetX;
    ctx.shadowOffsetY = offsetY;
    ctx.fillStyle = obj.fill;
    ctx.globalAlpha = obj.opacity;
    ctx.font = fontWithStyle;
    ctx.fillText(obj.text, 0, height / 1.25);
    ctx.restore()
  }
  if (obj.underline === true) {
    ctx.beginPath();
    ctx.save()
    ctx.translate(left, top)
    ctx.rotate(obj.angle * Math.PI / 180);
    ctx.strokeStyle = obj.fill;
    ctx.lineWidth = font / 13;
    ctx.moveTo(0, height / 1.1);
    ctx.lineTo(width, height / 1.1);
    ctx.stroke();
    ctx.restore()
  }
}

function checkIfAngleChangedForImage(image, angle, left, top, width, height, ctx) {
  if (angle != 0) {
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(angle * Math.PI / 180);
    ctx.drawImage(image, 0, 0, width, height);
  } else {
    ctx.drawImage(image, left, top, width, height);
  }
  ctx.restore();
}

function getResizedWidth(objWidth, oldImageWidth, newImageWidth) {
  let widthDiff = ((oldImageWidth - newImageWidth) / oldImageWidth) * 100;
  let newWidth = objWidth;
  if (Math.sign(widthDiff) === 1) {
    newWidth = objWidth - (objWidth * widthDiff) / 100;
  } else if (Math.sign(widthDiff) === -1) {
    newWidth = objWidth + (objWidth * widthDiff * -1) / 100;
  }
  return newWidth;
}
function getResizedHeight(objHeight, oldImageHeight, newImageHeight) {
  let heightDiff = ((oldImageHeight - newImageHeight) / oldImageHeight) * 100;
  let newHeight = objHeight;
  if (Math.sign(heightDiff) === 1) {
    newHeight = objHeight - (objHeight * heightDiff) / 100;
  }
  if (Math.sign(heightDiff) === -1) {
    newHeight = objHeight - (objHeight * heightDiff) / 100;
  }
  return newHeight;
}
function getResizedLeft(objLeft, oldImageWidth, newImageWidth) {
  let widthDiff = ((oldImageWidth - newImageWidth) / oldImageWidth) * 100;
  let newLeft = objLeft;
  if (Math.sign(widthDiff) === 1) {
    newLeft = objLeft - (objLeft * widthDiff) / 100;
  } else if (Math.sign(widthDiff) === -1) {
    newLeft = objLeft + (objLeft * widthDiff * -1) / 100;
  }
  return newLeft;
}
function getResizedTop(objTop, oldImageHeight, newImageHeight) {
  let heightDiff = ((oldImageHeight - newImageHeight) / oldImageHeight) * 100;
  let newTop = objTop;
  if (Math.sign(heightDiff) === 1) {
    newTop = objTop - (objTop * heightDiff) / 100;
  }
  if (Math.sign(heightDiff) === -1) {
    newTop = objTop - (objTop * heightDiff) / 100;
  }
  return newTop;
}

function getReducedFontSize(objFont, oldImageWidth, newImageWidth) {
  let fontSize = objFont;
  var ratio = 1;
  ratio = objFont / oldImageWidth;
  fontSize = newImageWidth * ratio;
  return fontSize;
}

function getReducedValue(oldStrokeWidth, width, newWidth) {
  let widthDiff = ((width - newWidth) / width) * 100;
  let newStroke = oldStrokeWidth;
  if (Math.sign(widthDiff) === 1) {
    newStroke = oldStrokeWidth - (oldStrokeWidth * widthDiff) / 100;
  } else if (Math.sign(widthDiff) === -1) {
    newStroke = oldStrokeWidth + (oldStrokeWidth * widthDiff * -1) / 100;
  }
  return newStroke + 2.5;
}

export function setScaledLeftAndTopValues(obj, currentImageIndex) {
  let mainImageScaledInfo = appState.imagesScaleInfo[0];
  let mainImageWidth = mainImageScaledInfo.newWidth;
  let mainImageHeight = mainImageScaledInfo.newHeight;
  let currentImageScaleInfo = appState.imagesScaleInfo[currentImageIndex];
  let currentImageWidth = currentImageScaleInfo.newWidth;
  let currentImageHeight = currentImageScaleInfo.newHeight;
  let mainImageLeftPercet = (obj.left / mainImageWidth) * 100;
  let currentImageLeftValue = (currentImageWidth * mainImageLeftPercet) / 100;
  obj.left = currentImageLeftValue;
  let mainImageTopPercet = (obj.top / mainImageHeight) * 100;
  let currentImageTopValue = (currentImageHeight * mainImageTopPercet) / 100;
  obj.top = currentImageTopValue;

  let currentImgObjHeightWithScale = obj.height * obj.scaleY;
  let currentTop = obj.top + currentImgObjHeightWithScale;
  if (currentImageHeight < currentTop) {
    let diff = currentImgObjHeightWithScale - (currentImageHeight - obj.top);
    obj.top = obj.top - diff;
  }
  let currentImgWidthWithScale = obj.width * obj.scaleX;
  let currentLeft = obj.left + currentImgWidthWithScale;
  if (currentImageWidth < currentLeft) {
    let diff = currentImgWidthWithScale - (currentImageWidth - obj.left);
    obj.left = obj.left - diff;
  }

  return obj;
}

export function setScaledWidthAndHeightValues(obj, currentImageIndex) {
  let mainImageScaledInfo = appState.imagesScaleInfo[0];
  let mainImageWidth = mainImageScaledInfo.newWidth;
  let mainImageHeight = mainImageScaledInfo.newHeight;
  let mainImangeObjScaleX = cloneDeep(obj.scaleX);
  let mainImangeObjScaleY = cloneDeep(obj.scaleY);
  let mainImageObjWidth = obj.width * mainImangeObjScaleX;
  let mainImageObjHeight = obj.height * mainImangeObjScaleY;
  let mainImageObjWidthPercent = (mainImageObjWidth / mainImageWidth) * 100;
  let mainImageObjHeightPercent = (mainImageObjHeight / mainImageHeight) * 100;
  let currentImageScaleInfo = appState.imagesScaleInfo[currentImageIndex];
  let currentImageWidth = currentImageScaleInfo.newWidth;
  let currentImageHeight = currentImageScaleInfo.newHeight;
  let currentImageObjWidth =
    (currentImageWidth * mainImageObjWidthPercent) / 100;
  let currentImageObjHeight =
    (currentImageHeight * mainImageObjHeightPercent) / 100;
  let widthScale =
    (currentImageObjWidth * mainImangeObjScaleX) / mainImageObjWidth;
  let heightScale =
    (currentImageObjHeight * mainImangeObjScaleY) / mainImageObjHeight;
  let widthDiff = Math.abs(mainImageWidth - currentImageWidth);
  let heightDiff = Math.abs(mainImageHeight - currentImageHeight);
  if (widthDiff > heightDiff) {
    obj.scaleX = widthScale;
    obj.scaleY = widthScale;
  } else if (heightDiff > widthDiff) {
    obj.scaleX = heightScale;
    obj.scaleY = heightScale;
  } else {
    obj.scaleX = widthScale;
    obj.scaleY = widthScale;
  }
  return obj;
}

function getScaledPercentageForRepeat(obj, oldImageWidth, oldImageHeight, newImageWidth, newImageHeight) {
  let oldObjWidth = obj.width / 6
  let oldObjHeight = obj.height / 6
  let newObjWidth = getResizedWidth(oldObjWidth, oldImageWidth, newImageWidth)
  let newObjHeight = getResizedHeight(oldObjHeight, oldImageHeight, newImageHeight)
  let widthScale = newObjWidth / oldObjWidth
  let heightScale = newObjHeight / oldObjHeight
  let widthDiff = Math.abs(newObjWidth - oldObjWidth);
  let heightDiff = Math.abs(newObjHeight - oldObjHeight);
  let scale;
  if (widthDiff > heightDiff) {
    scale = widthScale;
  } else if (heightDiff > widthDiff) {
    scale = heightScale;
  } else {
    scale = widthScale;
  }
  return scale
}
