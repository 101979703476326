import React from 'react';
import PricingComp from './PricingComp';
import { appState } from '../../modals/basemodal';

/*import YearlyPricing from './YearlyPricing'
import FreePricing from './FreePricing'
import MonthlyPricing from './MonthlyPricing'*/

function PricingPage() {
  let free_features = ['Low resolution images', 'Access to limited fonts'];
  let monthly_features = [
    'Access to unlimited fonts',
    'Save Watermarks for future',
    'High resolution watermarked photos',
    'Better performance',
  ];

  //let monthly_features = ['Full resolution image download', 'All watermarking options', 'Save your watermarks for future use', 'Watermark multiple photos at a time', 'Huge variety of font options']
  //let yearly_features = ['Save 25% with yearly plan'].concat(monthly_features);
  let yearly_features = monthly_features;

  let onMonthlySubscription = function () {
    const Paddle = window.Paddle;
    Paddle.Checkout.open({
      product: 638413,
      email: appState.loggedInUser.email,
      passthrough: appState.loggedInUser.uid,
    });
    //TODO: to put product IDs in constants
  };

  let onYearlySubscription = function () {
    const Paddle = window.Paddle;
    Paddle.Checkout.open({
      product: 638900,
      email: appState.loggedInUser.email,
      passthrough: appState.loggedInUser.uid,
    });
  };
  return (
    <div className="flex flex-col items-center w-full h-full bg-gray-100">
      <div className="mt-6 font-sans text-5xl font-bold tracking-wide text-center ">
        {' '}
        Pricing{' '}
      </div>
      <div className="flex flex-col justify-between mt-6 md:flex-row w-7/8">
        <PricingComp
          subscriptionCall={onMonthlySubscription}
          features={free_features}
          title="Free"
          price="-"
          discountDivCss = "h-0 md:h-8 mt-0 md:mt-2"
          savingsDivCss = "h-0 md:h-6 mt-0 md:mt-5"
          backgroundColor={' bg-white'}
          hideUpgradeButton={true}
        />
        <PricingComp
          subscriptionCall={onMonthlySubscription}
          features={monthly_features}
          title="Monthly"
          actualPrice="$4.99"
          discountedPrice="$4"
          backgroundColor={' bg-gray-900'}
          textColor={' text-white '}
          savingsInfo=""
          discountDivCss = "h-8 mt-2"
          savingsDivCss = "h-0 md:h-6 mt-5"
        />
        <PricingComp
          subscriptionCall={onYearlySubscription}
          features={yearly_features}
          title="Yearly"
          actualPrice="$45"
          discountedPrice="$35"
          backgroundColor={' bg-gray-900 '}
          textColor={' text-white '}
          savingsInfo="Save 25% ($13)"
          discountDivCss = "h-8 mt-2"
          savingsDivCss = "h-6 mt-5"
        />
      </div>
    </div>
  );
}

export default PricingPage;
