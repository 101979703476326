import React from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import { appState } from '../../modals/basemodal';

const ThumbnailComponent = observer((props) => {
  let selectImageCursor = null;
  let highlightSelectedImage = ' border border-gray-300';
  if (appState.currentIndex && appState.currentIndex === 2) {
    selectImageCursor = ' cursor-pointer';
    if (props.index === appState.currentSelectedImageIndex) {
      //highlightSelectedImage = " border-black border-4 "
      highlightSelectedImage = ' bg-green-500 ';
    }
  }

  const onImageSelect = () => {
    appState.currentSelectedImageIndex = props.index;
  };

  return (
    <li
      className={
        'rounded-md mr-2 flex-shrink-0 h-16 w-24' + highlightSelectedImage
      }
      onClick={onImageSelect}
    >
      <div className={'rounded-md relative h-16 w-24' + selectImageCursor}>
        <img
          src={props.SingleImage}
          alt="image"
          className="img-preview w-full h-full sticky object-cover rounded-md bg-fixed"
        />

        <div className="flex flex-col bg-transparent rounded-sm w-full h-full absolute top-0">
          <div className="flex justify-between">{props.deleteOption}</div>
        </div>
      </div>
    </li>
  );
});

export default ThumbnailComponent;
