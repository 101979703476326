import React from "react";
import { fabric } from "fabric";
import { observer } from "mobx-react";
import { observe } from "mobx";
import { appState } from "../../modals/basemodal";
import { cloneDeep, isEqual } from "lodash";
import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_OUTLINE_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SVG_IMAGE_COLOR,
} from "../../Constants";
import {
  setScaledLeftAndTopValues,
  setScaledWidthAndHeightValues,
} from "../Download/AddProps";
import DownloadImage from "../Download/DownloadImage";

const PositionWatermark = observer(
  class PositionWatermark extends React.Component {
    constructor(props) {
      super(props);
      this.previewUrl = null;
      this.__canvas = null;
      this.offCanvas = null;
      this.currentModifiedWatermark = [];

      observe(appState, "currentIndex", (change) => {
        if (appState.currentIndex === 2) {
          appState.currentSelectedImageIndex = 0;
          this.setImageIndexAndScaledValues();
        }
      });

      observe(appState, "currentSelectedImageIndex", (change) => {
        this.__canvas.clear();
        this.setNewBackgroundImage();
        this.setPropsOnCanvas();
      });
    }

    componentDidMount() {
      // Make a New Canvas
      this.__canvas = new fabric.Canvas("meCanvas", {
        preserveObjectStacking: true,
        height: appState.mainImageCanvasHeight,
        width: appState.mainImageCanvasWidth, //80% of window (0.8)
        backgroundColor: "transparent",
      });

      this.__canvas.on("object:selected", (e) => {
        this.setSelectedObjectToAppState(e);
      });
      this.__canvas.on("selection:updated", (e) => {
        this.setSelectedObjectToAppState(e);
      });

      this.__canvas.on("selection:cleared", (e) => {
        appState.selectedProperty = null;
        //appState.selectedText = null;
        //appState.selectedLogo = null;
      });

      this.__canvas.on("object:added", (e) => {
        this.setSelectedObjectToAppState(e);
        //e.target.set('active',true);
      });

      this.__canvas.on("object:moved", (e) => {
        if (e && e.target) {
          this.updatedModifiedWatermark(e.target);
          //console.log('----------------->',cloneDeep(e.target),e.target.left,e.target.top)
        }
        //isObjectMoving = true;
      });
      /*this.__canvas.on('mouse:down', (e) => {
            if (e && e.target ) {
                }
            //this.setSelectedObjectToAppState(e);
            //this.__canvas.renderAll(this.__canvas);
            //    console.log('----____==> ', e.target);
        });*/

      this.__canvas.on("text:changed", (e) => {
        appState.selectedText = null; // Mobx observer is not rendering for object (So null and assign value will work)
        appState.selectedText = e.target;
        //console.log('********> ', e.target);
      });

      if (appState.imageWidth > 0) {
        this.setNewBackgroundImage();
      }

      if (appState.listOfAllUploadedImage.length > 0) {
        this.setImageIndexAndScaledValues();
        this.setNewBackgroundImage();
      }
      if (appState.listOfAllUploadedImage.length > 0) {
        this.setImageIndexAndScaledValues();
        this.setNewBackgroundImage();
      }
      if (
        appState.listOfAllUploadedImage.length > 0 &&
        appState.listOfDefaultWatermarkIds.length > 0
      ) {
        this.setPropsOnCanvas();
      }
    }

    updatedModifiedWatermark(updatedText) {
      if (updatedText) {
        if (
          appState.listOfDefaultWatermarkIds.indexOf(updatedText.myid) > -1 &&
          appState.modifiedWatermarksList
        ) {
          let currentText = cloneDeep(
            appState.modifiedWatermarksList._defaultWatermarks._allWatermarks[
              updatedText.myid
            ]
          );
          //console.log("#################",currentText,currentText.left,updatedText.left,currentText.top,updatedText.top,cloneDeep(updatedText))
          if (
            !isEqual(currentText.left, updatedText.left) ||
            !isEqual(currentText.top, updatedText.top)
          ) {
            //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!",currentText.left,updatedText.left,currentText.top,updatedText.top)
            currentText.left = updatedText.left;
            currentText.top = updatedText.top;
            if (
              appState.modifiedWatermarksList.allModifiedWatermarkOfEachImage[
                appState.currentSelectedImageIndex
              ]
            ) {
              if (
                appState.listOfModifiedWatermarkIds.indexOf(updatedText.myid) >
                -1
              ) {
                appState.modifiedWatermarksList._allModifiedWatermarks[
                  updatedText.myid
                ] = currentText;
              } else {
                appState.listOfModifiedWatermarkIds.push(updatedText.myid);
                appState.modifiedWatermarksList.setModifiedWaterMarks(
                  updatedText.myid,
                  currentText
                );
              }
              appState.modifiedWatermarksList.allModifiedWatermarkOfEachImage[
                appState.currentSelectedImageIndex
              ] = appState.modifiedWatermarksList._allModifiedWatermarks;
            } else {
              appState.modifiedWatermarksList._allModifiedWatermarks = {};
              if (
                appState.listOfModifiedWatermarkIds.indexOf(updatedText.myid) >
                -1
              ) {
                appState.modifiedWatermarksList._allModifiedWatermarks[
                  updatedText.myid
                ] = currentText;
              } else {
                appState.listOfModifiedWatermarkIds.push(updatedText.myid);
                appState.modifiedWatermarksList.setModifiedWaterMarks(
                  updatedText.myid,
                  currentText
                );
              }

              appState.modifiedWatermarksList.setModifiedWaterMarksOfEachImage(
                appState.currentSelectedImageIndex,
                appState.modifiedWatermarksList._allModifiedWatermarks
              );
            }
          }
        }
      }
    }

    setPropsOnCanvas() {
      this.__canvas.clear();
      if (appState.listOfAllUploadedImage.length > 0) {
        if (appState.listOfDefaultWatermarkIds.length > 0) {
          appState.listOfDefaultWatermarkIds.forEach((event) => {
            if (
              appState.modifiedWatermarksList &&
              appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
                appState.currentSelectedImageIndex
              )
            ) {
              var props =
                appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
                  appState.currentSelectedImageIndex
                );
              if (!props[event]) {
                let wmObj =
                  appState.modifiedWatermarksList.getDefaultWatermarkByKey(
                    event
                  );
                let obj = cloneDeep(wmObj);
                setScaledWidthAndHeightValues(
                  obj,
                  appState.currentSelectedImageIndex
                );
                if(!obj.isRepeat){
                  setScaledLeftAndTopValues(
                    obj,
                    appState.currentSelectedImageIndex
                  );
                }
                if (appState.currentSelectedImageIndex === 0) {
                  obj._removeCacheCanvas();
                }
                if (
                  obj.myid.match("text") ||
                  obj.myid.match("fontAwesomeText")
                ) {
                  obj.set("editable", false);
                }
                obj.hasControls = false;
                this.__canvas.add(obj);
                this.__canvas.setActiveObject(obj);
              }
            } else {
              let wmObj =
                appState.modifiedWatermarksList.getDefaultWatermarkByKey(event);
              let obj = cloneDeep(wmObj);
              setScaledWidthAndHeightValues(
                obj,
                appState.currentSelectedImageIndex
              );
              if(!obj.isRepeat){
                setScaledLeftAndTopValues(
                  obj,
                  appState.currentSelectedImageIndex
                );
              }
              if (appState.currentSelectedImageIndex === 0) {
                obj._removeCacheCanvas();
              }
              if (obj.myid.match("text") || obj.myid.match("fontAwesomeText")) {
                obj.set("editable", false);
              }
              obj.hasControls = false;
              this.__canvas.add(obj);
              this.__canvas.setActiveObject(obj);
            }
          });
        }

        if (
          appState.modifiedWatermarksList &&
          appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
            appState.currentSelectedImageIndex
          )
        ) {
          var props =
            appState.modifiedWatermarksList.getModifiedWatermarkByKeyOfEachImage(
              appState.currentSelectedImageIndex
            );
          appState.listOfModifiedWatermarkIds.forEach((id) => {
            if (props[id]) {
              let obj = cloneDeep(
                appState.modifiedWatermarksList.getDefaultWatermarkByKey(
                  props[id].myid
                )
              );
              setScaledWidthAndHeightValues(
                obj,
                appState.currentSelectedImageIndex
              );
              if (appState.currentSelectedImageIndex === 0) {
                obj._removeCacheCanvas();
              }
              obj.left = props[id].left;
              obj.top = props[id].top;
              if (obj.myid.match("text") || obj.myid.match("fontAwesomeText")) {
                obj.set("editable", false);
              }
              obj.hasControls = false;
              this.__canvas.add(obj);
              this.__canvas.setActiveObject(obj);
            }
          });
        }
      }
    }

    setSelectedObjectToAppState(e) {
      var myId = e.target.myid;
      if (e.target.text) {
        if (myId === undefined) {
          //do not add object on canvas
        } else if (myId.match("text") || myId.match("fontAwesomeText")) {
          appState.selectedProperty = "text";
          appState.selectedText = e.target;
          appState.selectedText.isHidden = false;
          appState.allAddedText[myId] = e.target;
          var text = { myid: e.target.myid, target: e.target };
          appState.listOfTextAdded.push(text);
          this.setPickerColorArray("text", e);
        }
        // console.log('SASAS', e.target, '::::', appState.allAddedText, '==>', e.target.myid)
      } else if (e.target.get("type") === "image") {
        appState.selectedProperty = "logo";
        appState.selectedLogo = e.target;
        appState.selectedLogo.isHidden = false;
        if (myId.match("logo")) {
          // i === image
          appState.allAddedLogos[myId] = e.target;
          this.setPickerColorArray("logo", e);
        } else if (myId.match("svgLogo")) {
          this.setPickerColorArray("svgLogo", e);
        }
        //console.log('SASAS', appState.selectedProperty, '::::', appState.selectedLogo)
      } else if (myId.match("group")) {
        appState.selectedProperty = "group";
        appState.selectedGroup = e.target;
        appState.allAddedGroups[myId] = e.target;
      } else {
        // If REPEATED object selected update text/logo object.
        if (e.target.fill.repeat) {
          //    && appState.selectedText
          if (
            appState.allAddedText[e.target.myid] &&
            appState.allAddedText[e.target.myid].text
          ) {
            appState.selectedText = appState.allAddedText[e.target.myid];
            appState.selectedText.isHidden = true; // some times it will be false for repeated object.
            appState.selectedProperty = "text";
          }
          // else if(e.target.get('type') === 'image'){
          else if (appState.allAddedLogos[e.target.myid]) {
            // && appState.allAddedLogos[e.target.myid].get('type') === 'image'){
            appState.selectedLogo = appState.allAddedLogos[e.target.myid];
            appState.selectedLogo.isHidden = true;
            appState.selectedProperty = "logo";
          }
          //console.log('******', appState.selectedLogo, e.target.myid, appState.allAddedLogos, '::', appState.selectedProperty);
        }
      }
      this.__canvas.setActiveObject(e.target);
      this.__canvas.renderAll();
    }

    setPickerColorArray(objType, e) {
      if (objType === "text") {
        var backgroundColor =
          e.target.backgroundColor !== "transparent"
            ? e.target.backgroundColor
            : DEFAULT_BACKGROUND_COLOR;
        var strokeColor =
          e.target.stroke !== "transparent"
            ? e.target.stroke
            : DEFAULT_OUTLINE_COLOR;
        var shadowColor =
          e.target.shadow !== null
            ? e.target.shadow.color
            : DEFAULT_SHADOW_COLOR;
        appState.pickerColors = [
          "green",
          e.target.fill,
          backgroundColor,
          strokeColor,
          shadowColor,
          DEFAULT_SHADOW_COLOR,
          DEFAULT_SVG_IMAGE_COLOR,
        ];
        //console.log('Changed : 11', appState.pickerColors);
      } else if (objType === "logo") {
        var shadowColor2 =
          e.target.shadow !== null
            ? e.target.shadow.color
            : DEFAULT_SHADOW_COLOR;
        appState.pickerColors = [
          "green",
          DEFAULT_TEXT_COLOR,
          DEFAULT_BACKGROUND_COLOR,
          DEFAULT_OUTLINE_COLOR,
          DEFAULT_SHADOW_COLOR,
          shadowColor2,
          DEFAULT_SVG_IMAGE_COLOR,
        ];
        //console.log('Changed : 22', appState.pickerColors);
      } else if (objType === "svgLogo") {
        if (e.target.path) {
          let svgColor = e.target.get("fill");
          let svgShadowColor =
            e.target.shadow !== null
              ? e.target.shadow.color
              : DEFAULT_SHADOW_COLOR;
          appState.pickerColors = [
            "green",
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            svgShadowColor,
            svgColor,
          ];
        } else if (e.target._objects) {
          let svgColor = e.target._objects[0].get("fill");
          let svgShadowColor =
            e.target.shadow !== null
              ? e.target.shadow.color
              : DEFAULT_SHADOW_COLOR;
          appState.pickerColors = [
            "green",
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            svgShadowColor,
            svgColor,
          ];
        } else {
          appState.pickerColors = [
            "green",
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            DEFAULT_SHADOW_COLOR,
            DEFAULT_SVG_IMAGE_COLOR,
          ];
        }
      }
    }

    setNewBackgroundImage() {
      if (appState.listOfAllUploadedImage.length > 0) {
        var imgObj =
          appState.listOfAllUploadedImage[appState.currentSelectedImageIndex];
        if (imgObj) {
          var myDataUrl = imgObj.src;
          if (myDataUrl === 0) {
            this.__canvas.setBackgroundImage(
              myDataUrl,
              this.__canvas.renderAll.bind(this.__canvas)
            );
          } else {
            let scaledInfo =
              appState.imagesScaleInfo[appState.currentSelectedImageIndex];
            let newWidth = scaledInfo.width * scaledInfo.scale;
            let newHeight = scaledInfo.height * scaledInfo.scale;
            this.__canvas.setWidth(newWidth);
            this.__canvas.setHeight(newHeight);
            this.__canvas.setBackgroundImage(
              myDataUrl,
              this.__canvas.renderAll.bind(this.__canvas),
              {
                top: 0,
                left: 0,
                originX: "left",
                originY: "top",
                scaleX: scaledInfo.scale,
                scaleY: scaledInfo.scale,
              }
            );
          }
        }
      }
      // console.log("############# ",this.__canvas)
    }

    setImageIndexAndScaledValues() {
      if (appState.listOfAllUploadedImage.length > 0) {
        appState.listOfAllUploadedImage.forEach((imgObj, index) => {
          if (!appState.imagesScaleInfo[index]) {
            let scale = 1;
            if (
              appState.mainImageCanvasHeight < imgObj.height ||
              appState.mainImageCanvasWidth < imgObj.width
            ) {
              let heightScale = 1;
              let widthScale = 1;
              if (appState.mainImageCanvasHeight < imgObj.height) {
                heightScale = appState.mainImageCanvasHeight / imgObj.height;
              }
              if (appState.mainImageCanvasWidth < imgObj.width) {
                widthScale = appState.mainImageCanvasWidth / imgObj.width;
              }
              scale = Math.min(heightScale, widthScale);
            }
            let newWidth = imgObj.width * scale;
            let newHeight = imgObj.height * scale;
            let scaledInfo = {
              width: imgObj.width,
              height: imgObj.height,
              newWidth: newWidth,
              newHeight: newHeight,
              scale: scale,
            };
            appState.imagesScaleInfo[index] = scaledInfo;
          }
        });
      }
    }

    render() {
      return (
        <div className="flex flex-row h-full mx-24 lg:mx-8">
          <div className="w-3/4 max-h-full max-w-4/6">
            <div className="flex flex-col h-full">
              <div className="flex justify-center max-w-3/4 h-3/4">
                <canvas
                  id="meCanvas"
                  ref="refCanvas"
                  onClick={() => console.log("onClick")}
                />
              </div>
            </div>
          </div>
          <div className="w-1/4 lg:w-1/3">
            <DownloadImage />
          </div>
        </div>
      );
    }
  }
);

export default PositionWatermark;
