import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import { appState } from '../../modals/basemodal';
import firebase from 'firebase';
import { fabric } from 'fabric';
import fireAuth from '../../config/firebase';

function SingleSavedWatermark(props) {
  const [imageUrl, setImageUrl] = useState('');
  const eachWatermarkData = props.eachWatermark;
  const storagePath =
    'users/' + appState.loggedInUser.uid + eachWatermarkData.path;
  const thumbnailStoragePath = storagePath + '-thumbnail.png';
  const svgStoragePath = storagePath + '.svg';
  let storageRef = firebase.storage().ref();

  const onDelete = () => {
    storageRef
      .delete(thumbnailStoragePath)
      .then(function () {
        console.log('Tumbnail deleted');
      })
      .catch(function (error) {
        console.log('Failed to delete thumbnail  ', error);
      });
    storageRef
      .delete(svgStoragePath)
      .then(function () {
        console.log('svg deleted');
      })
      .catch(function (error) {
        console.log('Failed to delete svg  ', error);
      });
    var dbRef = fireAuth
      .firestore()
      .collection('users')
      .doc(appState.loggedInUser.uid);
    dbRef.update({
      userWatermarks: firebase.firestore.FieldValue.arrayRemove(
        props.eachWatermark
      ),
    });
  };

  const onImageSelect = (e) => {
    storageRef
      .child(svgStoragePath)
      .getDownloadURL()
      .then((url) => {
        var newID = new Date().getTime().toString().substr(5);
        fabric.loadSVGFromURL(url, function (objects, options) {
          let waterMarkObj = [];
          objects.map((event) => {
            if (event.text) {
              event.left = event.left - 73.1;
              event.top = event.top + 10;
            }
            waterMarkObj.push(event);
          });
          let waterMarkObjGroup = new fabric.Group(waterMarkObj, {
            left: 10,
            top: 10,
            myid: 'group' + newID,
            lockUniScaling: true,
            hasRotatingPoint: false,
          });
          appState.canvasId.add(waterMarkObjGroup).renderAll();
          appState.canvasId.setActiveObject(waterMarkObjGroup);
          appState.defaultWatermarksList.addWatermark(waterMarkObjGroup);
          appState.listOfDefaultWatermarkIds.push(waterMarkObjGroup.myid);
          appState.isMyWatermarksModalVisible = false;
        });
      })
      .catch((error) => {
        console.log('------Could not load watermark thumbnail-----: ', error);
      });
  };

  storageRef
    .child(thumbnailStoragePath)
    .getDownloadURL()
    .then((url) => {
      setImageUrl(url);
    })
    .catch((error) => {
      console.log('------Could not load watermark thumbnail-----: ', error);
    });

  return (
    <li
      className="border-gray-400 flex flex-row mb-2 w-full h-24 max-w-full max-h-24 hover:shadow-lg hover:border hover:border-2"
      onClick={onImageSelect}
    >
      <div
        className="select-none cursor-pointer w-32 h-24 max-w-100 max-h-24 bg-gray-200 rounded-md flex flex-1 items-center p-2
              hover:shadow-lg hover:border hover:border-2"
      >
        <div className="flex-1">
          <div className="font-medium">
            <img
              src={imageUrl}
              alt="image"
              className="w-full h-24 sticky object-scale-down rounded-md bg-fixed"
            />
          </div>
        </div>
        {/* <div>
                    <button onClick={onDelete} className="p-1 rounded-full w-8 h-8 bg-gray-200 hover:bg-gray-400 text-gray-800 font-bold ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div> */}
      </div>
    </li>
  );
}

export default SingleSavedWatermark;
