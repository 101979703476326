import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../modals/basemodal';
import * as Constants from '../Constants';

function onUpgrade(e) {
    appState.showFreeUserNotificationFor = null;
    window.location = "/pricing";
}
function OnCancel(props) {
    appState.showFreeUserNotificationFor = null;

}

function DialogBox(props) {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => { appState.showFreeUserNotificationFor = null }}></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="">
                            <div className=" flex items-center">
                                <svg className="h-14 w-14 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                                </svg>
                                <h3 className="pl-3 text-lg leading-6 font-semibold text-gray-900" id="modal-headline">
                                    {props.heading}
                                </h3>
                            </div>

                            <div className="pl-6 mt-3 mx-10  text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <p className="text-sm text-gray-500">
                                    {props.content.split('.').map((item, key) => {
                                        let data = item + "."
                                        return <span key={key}>{data}<br /></span>
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => { onUpgrade() }}>
                            Upgrade
                        </button>
                        <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm "
                            onClick={() => { OnCancel() }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const FreeUserLimitDialog = observer(class FreeUserLimitDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        let visualElement = null;
        if (appState.showFreeUserNotificationFor === Constants.FREE_USER_PHOTOS_LIMIT)
            visualElement = <DialogBox heading={"Upgrade to select multiple photos"} content={"Free user can select only one image at a time. Upgrade to select and watermark multiple photos in one go."} />;
        else if (appState.showFreeUserNotificationFor === Constants.FREE_USER_FONTS_LIMIT)
            //visualElement = <div> Free users can only use 5 fonts</div>;
            visualElement = <DialogBox heading={"Upgrade to use all fonts"} content={"You must be a premium user to access this font. Please consider upgrading."} />;
        else if (appState.showFreeUserNotificationFor === Constants.FREE_MY_WATERMARKS_LIMIT)
            visualElement = <DialogBox heading={"Upgrade to save your watermarks for future use"} content={"You can save all your watermarks for future use by upgrading your account. Your saved watermarks will appear here."} />;
        else if (appState.showFreeUserNotificationFor === Constants.FREE_USER_DOWNLOAD_LIMIT)
            visualElement = <div> free users can only download 1 photo watermark</div>;

        return (visualElement);
    }
})


export default FreeUserLimitDialog;
