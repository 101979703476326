import React from 'react';
import createReactClass from 'create-react-class';
import { observe } from 'mobx';
import { appState } from '../modals/basemodal';
import { DEFAULT_TEXT_COLOR, DEFAULT_OUTLINE_COLOR, DEFAULT_BACKGROUND_COLOR, DEFAULT_SHADOW_COLOR, DEFAULT_SVG_IMAGE_COLOR } from '../Constants';

var ctxB = [];
var ctxS = [];
var activeIndex = 1;
var wB = 150;
var hB = 150;
var wS = 30;
var hS = 150;
var drag = false;
var rgbColor = ['green', DEFAULT_TEXT_COLOR, DEFAULT_BACKGROUND_COLOR, DEFAULT_OUTLINE_COLOR, DEFAULT_SHADOW_COLOR, DEFAULT_SHADOW_COLOR, DEFAULT_SVG_IMAGE_COLOR];    //'rgb(255,0,0)'; // red

function colorBlockFill() {
  ctxB[activeIndex].rect(0, 0, wB, hB);
  gradientBlock();
}

function colorStripFill() {
  ctxS[activeIndex].rect(0, 0, wS, hS);
  var grd1 = ctxS[activeIndex].createLinearGradient(0, 0, 0, hS);
  grd1.addColorStop(0, 'rgb(255, 0, 0)'); // red
  grd1.addColorStop(0.17, 'rgb(255, 255, 0)'); // yellow
  grd1.addColorStop(0.34, 'rgb(0, 255, 0)'); // green
  grd1.addColorStop(0.51, 'rgb(0, 255, 255)'); // aqua
  grd1.addColorStop(0.68, 'rgb(0, 0, 255)'); // blue
  grd1.addColorStop(0.85, 'rgb(255, 0, 255)'); // magenta
  grd1.addColorStop(1, 'rgb(255, 0, 0)'); // red
  ctxS[activeIndex].fillStyle = grd1;
  ctxS[activeIndex].fill();
}

function gradientBlock() {
  ctxB[activeIndex].fillStyle = rgbColor[activeIndex];
  ctxB[activeIndex].fillRect(0, 0, wB, hB);
  var grdWhite = ctxB[activeIndex].createLinearGradient(0, 0, wB, 0);
  grdWhite.addColorStop(0, 'rgb(255,255,255)');
  grdWhite.addColorStop(1, 'transparent');
  ctxB[activeIndex].fillStyle = grdWhite;
  ctxB[activeIndex].fillRect(0, 0, wB, hB);
  var grdBlack = ctxB[activeIndex].createLinearGradient(0, 0, 0, hB);
  grdBlack.addColorStop(0, 'transparent');
  grdBlack.addColorStop(1, 'rgb(0,0,0)');
  ctxB[activeIndex].fillStyle = grdBlack;
  ctxB[activeIndex].fillRect(0, 0, wB, hB);
}

function selectColor(ctx, e, self) {
  var x = e.nativeEvent.offsetX;
  var y = e.nativeEvent.offsetY;
  var imageData = ctx.getImageData(x, y, 1, 1).data;
  rgbColor[activeIndex] = 'rgb(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ')';
  self.setState({ color: rgbColor[activeIndex] });
}

var ColorPickerContainer = createReactClass({
  getInitialState: function () {
    observe(appState, "pickerColors", (change) => {
      rgbColor = appState.pickerColors;
      this.setState({ color: rgbColor[this.props.pickerId] });
      //console.log("22 changed to ", rgbColor[this.props.pickerId], this.props.pickerId);
    });
    return {
      isPickerVisible: false,
      color: rgbColor[this.props.pickerId]
    };
  },
  togglePicker: function (e, pickerId) {
    if (/*!this.state.isPickerVisible &&*/ pickerId !== this.props.activeColorPickerId) {
      activeIndex = this.props.pickerId;
      this.setState({ isPickerVisible: true });
    } else {
      activeIndex = 0;
      this.setState({ isPickerVisible: false });
    }
    //this.setState({isPickerVisible: !this.state.isPickerVisible});
    this.props.activeColorPicker(activeIndex);
    //console.log('LABEL CLICK', activeIndex, this.state.isPickerVisible);
  },
  clickStrip: function (e) {
    selectColor(ctxS[activeIndex], e, this);
    gradientBlock();
    this.props.setColorProps(rgbColor[activeIndex], activeIndex);         // pass color (and key) to parent as props.
  },
  mouseDownBlock: function (e) {
    drag = true;
    selectColor(ctxB[activeIndex], e, this);
    this.props.setColorProps(rgbColor[activeIndex], activeIndex);         // pass color (and key) to parent as props.
  },
  mouseMoveBlock: function (e) {
    if (drag) {
      selectColor(ctxB[activeIndex], e, this);
      this.props.setColorProps(rgbColor[activeIndex], activeIndex);         // pass color (and key) to parent as props.
    }
  },
  mouseUpBlock: function () {
    drag = false;
  },
  // componentWillMount: function() {
  //   activeIndex = this.props.pickerId;
  // },

  render: function () {
    //console.log('activeColorPickerId, activeIndex',  this.props.activeColorPickerId, activeIndex, '::', this.props.pickerId, this.state.color)
    return (
      <div className='color-picker-div'>
        <ColorLabel isChecked={this.state.isPickerVisible}
          color={this.state.color}
          handleClick={(e) => this.togglePicker(e, this.props.pickerId)}
          pickerId={this.props.pickerId} />
        { this.props.activeColorPickerId === this.props.pickerId ?
          <ColorPicker isVisible={this.state.isPickerVisible}
            color={this.state.color}
            mouseDownBlock={this.mouseDownBlock}
            mouseMoveBlock={this.mouseMoveBlock}
            mouseUpBlock={this.mouseUpBlock}
            clickStrip={this.clickStrip}
            pickerId={this.props.pickerId} />
          : null}
      </div>
    );
  }
});

var ColorLabel = createReactClass({
  handleClick: function () {
    activeIndex = this.props.pickerId;
    this.props.handleClick();
  },
  render: function () {
    var styles = {
      backgroundColor: this.props.color
    };
    //console.log('backgroundColor', styles.backgroundColor, this.props.pickerId)
    return (
      <div>
        <label htmlFor="color-input" id={"color_label" + this.props.pickerId} onClick={this.handleClick} style={styles} className="color-label"></label>
        <input type="checkbox" id={"color_input" + this.props.pickerId} checked={this.props.isChecked} readOnly className="color-input" ></input>
      </div>
    );
  }
});

var ColorPicker = createReactClass({
  getInitialState: function () {
    return {
      color: rgbColor[activeIndex]
    }
  },
  componentDidMount: function () {
    activeIndex = this.props.pickerId;
    var canvasB = this.refs["canvasBlock" + this.props.pickerId];
    var canvasS = this.refs["canvasStrip" + this.props.pickerId];
    //console.log('XXX', canvasB, '::', canvasS)
    ctxB[this.props.pickerId] = canvasB.getContext('2d');
    ctxS[activeIndex] = canvasS.getContext('2d');
    colorBlockFill();
    colorStripFill();
  },
  render: function (e) {
    var styles = {
      //opacity: this.props.isVisible ? '1' : '0',
      display: this.props.isVisible ? 'flex' : 'none'
    };
    return (
      <div id={"color_picker" + this.props.pickerId} style={styles} className="color-picker">
        <canvas id={"color_block" + this.props.pickerId} className="color-block"
          height={hB}
          width={wB}
          onMouseDown={this.props.mouseDownBlock}
          onMouseMove={this.props.mouseMoveBlock}
          onMouseUp={this.props.mouseUpBlock}
          ref={"canvasBlock" + this.props.pickerId}></canvas>
        <canvas id={"color_strip" + this.props.pickerId} className="color-strip"
          height={hS}
          width={wS}
          onClick={this.props.clickStrip}
          ref={"canvasStrip" + this.props.pickerId}></canvas>
      </div>
    );
  }
});


export default ColorPickerContainer;
