import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';

const AcknowledgeModal = observer((props) => {
  function onOkClick() {
    if (props.onOkClick) {
      props.onOkClick();
      return;
    }
    appState.showNotificationModal = false;
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="">
              <div className="flex justify-center">
                <h3 className="pl-3 text-lg leading-6 font-semibold text-gray-900">
                  {props.heading}
                </h3>
              </div>

              <div className="pl-6 mt-3 text-center">
                <p className="text-sm text-gray-500">{props.content}</p>
              </div>
            </div>
          </div>
          <div className="flex justify-center bg-gray-50 px-4 py-3 ">
            <button
              className="mb-3 w-24 inline-flex justify-center rounded-md bg-indigo-600 hover:bg-indigo-800 shadow-xl px-4 py-2 bg-white text-base font-medium text-white focus:outline-none"
              onClick={() => {
                onOkClick();
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AcknowledgeModal;
