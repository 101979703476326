import React from 'react';
import { observe } from 'mobx';
import { BrowserRouter as Link } from "react-router-dom";
import { appState } from '../modals/basemodal';
import { SUBSCRIPTION_PLANS } from '../Constants/index'
import fireAuth from '../config/firebase';
import { observer } from 'mobx-react';
import axios from 'axios'
import CancelSubscriptionConfirmation from './CancelSubscriptionConfirmation'

//var unsubscribeUrl = "https://us-central1-tealpod-group.cloudfunctions.net/widgets/cancelSubscription"

function confirmCancellation() {
    appState.showCancelSubscriptionConfirmation = true
}

function unsubscribe() {

    //TODO: to put URL in constants
    let unsubscribeUrl = "https://us-central1-tealpod-group.cloudfunctions.net/widgets/cancelSubscription"
    //let unsubscribeUrl = "http://localhost:5001/tealpod-group/us-central1/widgets/cancelSubscription"
    axios({
        method: 'post',
        url: unsubscribeUrl,
        data: { "uid": appState.loggedInUser.uid },
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(function (response) {
            //handle success
            console.log("Unsubscribe successful");
            window.location.reload(true);
            appState.isPaidUser = false
            appState.showCancelSubscriptionConfirmation = false
        })
        .catch(function (response) {
            //handle error
            console.log("Unsubscribe unsuccessful", response);
            appState.showCancelSubscriptionConfirmation = false
        });
}
const AccountPage = observer(class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.getAccountDetails()
    }

    disposer = observe(appState, "loggedInUser", async (change) => {
        appState.accountDetails = await this.getAccountDetails()
    });

    onAccountPageClose = () => {
        window.location = "/";
    }

    getAccountDetails() {
        if (appState.loggedInUser) {
            var userRef = fireAuth.firestore().collection("users").doc(appState.loggedInUser.uid);
            userRef.get().then(function (userInfo) {
                if (userInfo.exists) {
                    let paidUser = userInfo.data().paid_user
                    if (paidUser) {
                        let subscriptionPlanId = userInfo.data().payment_details.subscription_plan_id
                        let subscriptionType = SUBSCRIPTION_PLANS[subscriptionPlanId]
                        let subscriptionStartDate = userInfo.data().payment_details.event_time
                        let nextBillingCycle = userInfo.data().payment_details.next_bill_date
                        //unsubscribeUrl = userInfo.data().payment_details.cancel_url
                        appState.accountDetails = <div className="flex flex-col w-full h-3/4 items-center justify-between">
                            <div className=" justify-between w-5/6  px-3 py-2 rounded-lg mt-3 bg-gray-100 border text-gray-700">
                                <tr className="flex justify-between mt-2">
                                    <td className="w-2/3">Account Type</td>
                                    <td>Paid User</td>
                                </tr>
                                <tr className="flex justify-between mt-2">
                                    <td className="w-2/3">Payment Type</td>
                                    <td>{subscriptionType}</td>
                                </tr>
                                <tr className="flex justify-between mt-2">
                                    <td className="w-2/3">Subscription Start Date</td>
                                    <td>{subscriptionStartDate.split(" ")[0]}</td>
                                </tr>
                                <tr className="flex justify-between mt-2">
                                    <td className="w-2/3">Next Billing Cycle</td>
                                    <td>{nextBillingCycle}</td>
                                </tr>
                            </div>

                            <div className="flex justify-center">
                                <br></br>
                                <button className="mb-6 bg-red-700 text-white p-3 m-3 hover:bg-red-900 rounded-lg text-center w-44 cursor-pointer focus:outline-none"
                                    onClick={() => confirmCancellation()}>Cancel subscription</button>
                            </div>
                        </div>

                    } else {
                        appState.accountDetails = <div className="flex flex-col w-full h-3/4 items-center justify-between">
                            <div className="w-5/6">
                                <div className=" justify-between w-full  px-3 py-2 rounded-lg mt-3 bg-gray-100 border text-gray-700">
                                    <tr className="flex justify-between m-2">
                                        <td className="w-2/3">Account Type</td>
                                        <td>Free User</td>
                                    </tr>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                <br></br>
                                <a href="/pricing" 
                                    className="mb-6 bg-indigo-600 text-white p-3 m-3 hover:bg-indigo-800 font-bold text-lg rounded-lg text-center w-44 cursor-pointer"
                                >
                                    Upgrade
                                </a>
                            </div>
                        </div>
                    }
                }
            })
        }
    }

    render() {
        return (
            <>
                <div className="flex justify-center h-full items-center bg-gray-100 m-2 rounded rounded-lg">
                    <div className="h-4/5 w-1/3 bg-white rounded-xl p-3 shadow-xl">
                        <div className="flex flex-col items-center rounded h-full rounded-xl">
                            <div className="flex w-full justify-end">
                                <button className="w-10 h-10 p-2 rounded-full bg-gray-100 hover:bg-gray-300 focus:outline-none" type="button" onClick={() => this.onAccountPageClose()}>
                                    <svg className="text-gray-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className="mt-2 text-center font-bold text-xl text-gray-700 mb-3">Account Page</div>
                            <div className="flex justify-center items-center w-16 h-16 rounded-full bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white h-10 w-10 ">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </div>
                            <div className="mt-1 text-gray-700  text-lg tracking-wide">{appState.loggedInUser ? appState.loggedInUser.email : "user not logged in"}</div>
                            {appState.accountDetails}
                        </div>
                    </div>
                </div>
                {appState.showCancelSubscriptionConfirmation ? <CancelSubscriptionConfirmation onConfirm={unsubscribe} /> : null}
            </>
        )
    }
})

export default AccountPage;