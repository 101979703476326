import { observe } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import firebase from 'firebase';
import fireAuth from '../../config/firebase';
import ProductOfTealpod from './ProductOfTealpod';
import SignInButton from './SignInButton'
import { TEALPOD_GROUP_NO_REPLY_EMAIL_ID } from '../../Constants/index'
const Login = observer(class LoginPopup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        showOnlySignUpInput: false,
        notification: null,
        error: null,
        isLoginTab: 1,
        isForgotPassword: null,
        isActionInProgress: false,
      };
      console.log("App:Login - version 2.5")
    }

    onEmailChange = (e) => {
      this.setState({ email: e.target.value });
      this.setState({ notification: null });
    };
    onPasswordChange = (e) => {
      this.setState({ password: e.target.value });
      this.setState({ notification: null });
    };

    handleSignInError(errorMsg) {
      if (
        errorMsg ===
        'Error: The password is invalid or the user does not have a password.'
      )
        this.setState({ notification: 'The password is invalid' });
      else if (
        errorMsg ===
        'Error: There is no user record corresponding to this identifier. The user may have been deleted.'
      )
        this.setState({
          notification: 'There is no user record corresponding to this email',
        });
      else if (errorMsg === 'Error: A network error. Please try again')
        this.setState({
          notification: 'Network error. Please try again in sometime',
        });
      else if (errorMsg === 'Error: The email address is badly formatted.')
        this.setState({ notification: 'The email address is invalid' });
      else this.setState({ notification: errorMsg });
    }

    signInWithEmail = (e) => {
    let me = this;
      this.setState({ isActionInProgress: true });
      const { email, password } = this.state;
      fireAuth
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          fireAuth.auth().onAuthStateChanged(function (user) {
            if (user) {
              if (!user.emailVerified) {
                me.handleSignInError("Your email id is not verified. Please click on the link in the mail sent from " + TEALPOD_GROUP_NO_REPLY_EMAIL_ID + " to verify.")
                return
              }
              else{
                appState.loggedInUser = user;
                appState.isSignUpModalVisible = false;
              }
            }})      
            this.setState({ notification: null });
            this.setState({isActionInProgress: false})
        })
            .catch((err) => {
                console.log("ERROR SIGNING IN: ", err)
                this.handleSignInError(err.toString())
                this.setState({isActionInProgress: false})
            });
    }

    signUpWithGoogle = (e) => {
      let me = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      //provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
      const auth = firebase.auth();

      firebase.auth().signInWithRedirect(provider).catch(error => console.log('Google-SignInWithRedirect error-0', error));

      /*firebase.auth().signInWithPopup(provider)
        .then(function (result) {
          //console.log('GOOGLE SIGNIN ', result);
                appState.isSignUpModalVisible = false;
        })
        .catch(function (error) {
          me.handleSignInError(error.toString());
          console.log('GOOGLE SIGNIN ERROR-0 ', error);
        });*/
    };

    setIsForgotPassword = () => {
      this.setState({ isForgotPassword: true });
    };

    forgotPassword = (e) => {
      let me = this;
      firebase
        .auth()
        .sendPasswordResetEmail(this.state.email)
        .then(function () {
          me.setState({
            notification: 'Email sent. Please check your mailbox',
          });
        })
        .catch(function (error) {
          console.log('FORGOT PASSWORD ERROR: ', error);
          me.handleSignInError(error.toString());
          // An error happened.
        });
    };

    render() {
      var paddTop = this.state.notification ? ' mt-1' : ' mt-6';
      return (
        <div className="flex flex-col items-center justify-between w-full h-5/6">
          <div className="w-full">
            <div className="m-1 text-sm font-bold text-center text-red-700">
              {this.state.notification}
            </div>
            <div
              className={
                'w-full flex flex-col justify-between items-center ' + paddTop
              }
            >
              <div className="w-3/4 text-sm font-bold text-left text-gray-500">
                Email
              </div>
              <input
                placeholder="abc@example.com"
                className="w-3/4 h-8 pl-2 border border-gray-500 rounded focus:outline-none focus:border-indigo-600"
                onChange={(e) => this.onEmailChange(e)}
                value={this.state.email}
              />
              {!this.state.isForgotPassword ? (
                <div className="flex flex-col items-center w-full">
                  <div className="w-3/4 mt-3 text-sm font-bold text-left text-gray-500">
                    Password
                  </div>
                  <input
                    type="password"
                    placeholder="Password"
                    className="w-3/4 h-8 pl-2 border border-gray-500 rounded focus:outline-none focus:border-indigo-600"
                    onChange={(e) => this.onPasswordChange(e)}
                    value={this.state.password}
                  />
                </div>
              ) : null}
              {!this.state.isForgotPassword ? (
                <SignInButton
                  onClickMethod={this.signInWithEmail}
                  name={'Sign in'}
                  isActionInProgress={this.state.isActionInProgress}
                ></SignInButton>
              ) : (
                <SignInButton
                onClickMethod={() => this.forgotPassword()}
                  name={'Reset Password'}
                ></SignInButton>
              )}
              {!this.state.isForgotPassword ? (
                <button
                  className="mt-1 text-sm font-bold text-indigo-400 hover:underline focus:outline-none"
                  onClick={() => this.setIsForgotPassword()}
                >
                  Forgot Password?
                </button>
              ) : null}

              {!this.state.isForgotPassword ? (
                <div className="flex flex-col items-center w-full">
                  <span className="m-3 text-gray-700"> OR </span>
                  <button
                    className="flex items-center justify-center w-3/4 h-16 max-w-xs p-1 font-bold text-white bg-blue-500 rounded shadow-sm"
                    onClick={() => this.signUpWithGoogle()}
                  >
                    <div className="w-1/4 h-full bg-white rounded">
                      <img
                        className="w-full h-full p-2"
                        src="../assets/images/socials/google.svg"
                      />{' '}
                    </div>
                    <span className="inline-flex items-center w-3/4 h-full ml-4 text-lg text-white">
                      Sign in with Google.
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <ProductOfTealpod />
        </div>
      );
    }
  }
);

export default Login;
