import React from 'react';
import { appState } from '../modals/basemodal';


function onConfirm(cancelMethod) {
    //appState.showCancelSubscriptionConfirmation = null;
    cancelMethod()
}
function OnCancel(props) {
    appState.showCancelSubscriptionConfirmation = false;
}

function CancelSubscriptionConfirmation(props) {
    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75" onClick={() => { appState.showCancelSubscriptionConfirmation = false }}></div>
                </div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="">
                            <div className=" flex items-center">
                                <svg className="h-14 w-14 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <h3 className="pl-8 text-lg leading-6 font-semibold text-gray-900" id="modal-headline">
                                    Confirm Cancellation
                                </h3>
                            </div>

                            <div className="pl-6 mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <p className="text-sm text-gray-500">
                                    Are you sure you want to cancel the subscription?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse justify-center">
                        <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:bg-gray-300  sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => { onConfirm(props.onConfirm) }}>
                            Confirm
                        </button>
                        <button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm "
                            onClick={() => { OnCancel() }}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CancelSubscriptionConfirmation;