import React from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
//import ImageUpload from './ImageUpload';

function HomePage() {
    return (
            <div>
                <h3>Home Page</h3>
                <nav>
                    <Link to="/app/"><h1>Open App 1234</h1></Link>
                </nav>
            </div>
    )
}

export default HomePage;