import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { appState } from "../modals/basemodal";
import LoginPopup from "./SignUp/LoginPopup";
import LoggedInUser from "./SignUp/LoggedInUser";
import fireAuth from "../config/firebase";

fireAuth.auth().onAuthStateChanged(function (user) {
  if (user) {
    if (!user.emailVerified) {
      return;
    }
    let docRef = fireAuth.firestore().collection("users").doc(user.uid);
    docRef.onSnapshot(function (doc) {
      if (doc && doc.data()) {
        appState.isPaidUser = doc.data().paid_user;
      }
    });
    var userRef = fireAuth.firestore().collection("users").doc(user.uid);
    appState.loggedInUser = user;
    userRef.get().then(function (doc) {
      if (!doc.exists) {
        return userRef.set({
          email: user.email,
          paid_user: false,
        });
      } else {
        appState.isPaidUser = doc.data().paid_user;
      }
    });
  } else {
    appState.isSignUpModalVisible = false;
    appState.loggedInUser = null;
  }
});

export const Navbar = observer(() => {
  function onSignUpButtonClick() {
    appState.isSignUpModalVisible = true;
  }
  var helpurl =
    "https://tealpod.notion.site/WatermarkHere-Help-457c63efc25b465fb448116876f32e12";

  return (
    <div className="flex flex-row items-center h-12 pr-3 bg-white shadow-2xl xl:h-8 2xl:h-12 C-NB">
      <Link to="https://watermarkhere.com" className="flex h-full text-2xl">
        <div className="inline-flex items-center px-2 logo-watermark ">
          Watermark
        </div>
        <div className="inline-flex items-center px-2 logo-here ">Here</div>
      </Link>

      <div className="flex flex-row justify-end w-full">
        {/*<Link to={helpurl} target="_blank" className="flex float-right mx-3 my-1 text-lg font-bold text-gray-400 cursor-pointer hover:text-indigo-700" style={{ float: "right", display: 'inline-block' }}>Help</Link> */}
        <a
          href={helpurl}
          target="_blank"
          rel="norefferer"
          className="flex float-right mx-3 my-1 text-lg font-bold text-gray-400 cursor-pointer hover:text-indigo-700"
          style={{ float: "right", display: "inline-block" }}
        >
          Help
        </a>
        <Link
          to="/pricing"
          className="flex float-right mx-3 my-1 text-lg font-bold text-gray-400 cursor-pointer hover:text-indigo-700"
          style={{ float: "right", display: "inline-block" }}
        >
          Pricing
        </Link>
        {appState.loggedInUser ? (
          <LoggedInUser />
        ) : (
          <>
            <div
              className="float-right px-2 my-1 ml-3 text-lg font-bold text-indigo-800 rounded-lg cursor-pointer hover:text-white hover:bg-blue-600"
              onClick={() => onSignUpButtonClick()}
              style={{ float: "right", display: "inline-block" }}
            >
              Login
            </div>
            <LoginPopup />
          </>
        )}
      </div>
    </div>
  );
});
