import React from 'react';

function SignInButton(props) {
  let cursorWait = props.isActionInProgress ? ' cursor-wait ' : '';
  return (
    <button
      className={
        'btn_signin h-8 w-3/4 mt-5 font-bold shadow-sm  uppercase rounded p-1 bg-blue-500 text-white flex items-center justify-center hover:bg-blue-700 focus:outline-none ' +
        cursorWait
      }
      disabled={props.isActionInProgress}
      onClick={() => {
        props.onClickMethod();
      }}
    >
      {props.name}
    </button>
  );
}

export default SignInButton;
