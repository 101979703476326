import React from "react";
import AddPhotos from "./AddPhotos/AddPhotos";
import AddWatermark from "./SelectWatermark/AddWatermark";
import ThumbnailContainer from "./ThumbnailComponent/ThumbnailContainer";
import { appState, clearAllData } from "../modals/basemodal";
import { observer } from "mobx-react";
import { observe } from "mobx";
import Download from "./Download";
import PositionWatermark from "./PositionWatermark/PositionWatermark";
import WizardComp from "./Wizard/WizardComp";

const StepContainerComps = [
  AddPhotos,
  AddWatermark,
  PositionWatermark,
  Download,
];

const MainArea = observer(
  class MainArea extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        fileList: [],
      };

      observe(appState.listOfAllUploadedImage, (change) => {
        if (appState.listOfAllUploadedImage.length === 0) {
          clearAllData();
        }
      });
      this.setFileList = this.setFileList.bind(this);
      this.setWizardIndex = this.setWizardIndex.bind(this);
      this.clearAllFiles = this.clearAllFiles.bind(this);

      console.log("App - version 2.1")
    }

    setFileList = function (fl) {
      this.setState({ fileList: fl });
      fl.forEach((imageFile, index) => {
        let imgDetailsObj = {};
        let fileName = imageFile.name.split(".")[0];
        let fileType = imageFile.name.split(".")[1];
        var img = new Image();
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          imgDetailsObj.src = reader.result;
          imgDetailsObj.fileName = fileName;
          imgDetailsObj.fileType = fileType;
          img.src = reader.result;
        });
        reader.readAsDataURL(imageFile);
        img.onload = function () {
          imgDetailsObj.height = this.height;
          imgDetailsObj.width = this.width;
          imgDetailsObj.imgTag = img;
          appState.listOfAllUploadedImage.push(imgDetailsObj);
        };
      });
    };

    setWizardIndex = function (wi) {
      appState.currentIndex = wi;
    };

    clearAllFiles = function () {
      this.setFileList([]);
    };
    render() {
      let currentIndex = appState.currentIndex;
      let CurrentStepComp = StepContainerComps[currentIndex];

      let photoThumbnails =
        (1 === currentIndex) | (3 === currentIndex) ? (
          ""
        ) : (
          <ThumbnailContainer
            fileList={appState.listOfAllUploadedImage}
            clearAllFiles={this.clearAllFiles}
            setFileList={this.setFileList}
          />
        );

      return (
        <>
          <WizardComp setWizardIndex={this.setWizardIndex} />
          {/*<FreeUserLimitDialog />*/}
          <div className="C-WA flex flex-col w-full h-full max-h-full overflow-hidden">
            <div className="flex flex-col flex-grow-2 h-full">
              <CurrentStepComp setFileList={this.setFileList} />
            </div>
          </div>

          {photoThumbnails}
        </>
      );
    }
  }
);

export default MainArea;
