import React from "react";
import { observer } from "mobx-react";
import DisplayItemsDialogue from "./DisplayItemsDialogue";
import { iconifyIcons } from "../../IconifyIcons";
import SingleIcons from "./SingleIcons";

// function getCategoryIcons(eachCategory, listOfIcons) {
//   var icons = listOfIcons.map((eachIcon, index) => {
//     return <SingleIcons key={index} iconClassName={eachIcon} index={index} />;
//   });

//   return (
//     <div className="flex flex-col h-20 min-h-20 bg-yellow-400">
//       <div className="inline-block  w-full flex-grow-3">
//         <div className="bg-blue-400  h-10 min-h-10">{eachCategory}</div>
//         <div className="max-h-full  flex overflow-y-auto">{icons}</div>
//       </div>
//     </div>
//   );
// }

const Icons = observer(
  class Icons extends React.Component {
    constructor(props) {
      super(props);
      this.categorySolid = iconifyIcons["font-awesome"]["solid"];
    }

    render() {
      let solidCategoryDataObject = this.categorySolid;
      let categorySolidUIObjects = Object.keys(solidCategoryDataObject).map(
        (eachIcon, index) => {
          return (
            <SingleIcons
              key={index}
              iconClassName={eachIcon}
              iconUnicode={solidCategoryDataObject[eachIcon]}
            />
          );
        }
      );

      let content = (
        <div className="flex flex-col h-full">
          <div className="inline-block  w-full flex-grow-3">
            <div className="max-h-full  flex overflow-y-auto">
              <ul className="flex flex-row justify-center flex-wrap p-3 rounded">
                {categorySolidUIObjects}
              </ul>
            </div>
          </div>
        </div>
      );

      return (
        <DisplayItemsDialogue content={content} title={"Icons"} /> //TODO: Make it higher order component
      );
    }
  }
);

export default Icons;
