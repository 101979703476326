import { observe } from 'mobx';
import React from 'react';
import { observer } from 'mobx-react';
import firebase from 'firebase';
import fireAuth from '../../config/firebase';
import ProductOfTealpod from './ProductOfTealpod';
import SignInButton from './SignInButton';
import { appState } from '../../modals/basemodal';

const SignUp = observer(
  class LoginPopup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        showOnlySignUpInput: false,
        notification: null,
        error: null,
        isLoginTab: 1,
      };
    }

    onEmailChange = (e) => {
      this.setState({ email: e.target.value });
      this.setState({ notification: null });
    };
    onPasswordChange = (e) => {
      this.setState({ password: e.target.value });
      this.setState({ notification: null });
    };

    handleSignUpError(errorMsg) {
      if (
        errorMsg ===
        'Error: The password is invalid or the user does not have a password.'
      )
        this.setState({ notification: 'The password is invalid' });
      else if (
        errorMsg ===
        'Error: The email address is already in use by another account.'
      )
        this.setState({ notification: 'The email address is already in use' });
      else if (errorMsg === 'Error: The email address is badly formatted.')
        this.setState({ notification: 'The email address is invalid' });
      else this.setState({ notification: errorMsg });
    }

    signUpWithEmail = (e) => {
      const { email, password } = this.state;
      fireAuth
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          fireAuth.auth().currentUser.sendEmailVerification().catch((err) =>{
            console.log("ERROR SENDING EMAIL: ",err)
          })
          appState.showNotificationModal = true
          fireAuth.auth().signOut();
        })
        .catch((err) => {
          console.log('Error: ', err.toString());
          this.handleSignUpError(err.toString());
        });
    };


    signUpWithGoogle = (e) => {
      let me = this;
      var provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a Google Access Token. You can use it to access the Google API.
          var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          // ...
          console.log('GOOGLE SIGNIN ', result);
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          // ...
          me.handleSignInError(error.toString());
          console.log('GOOGLE SIGNIN ERROR-1 ', error);
        });
    };

    render() {
      var paddTop = this.state.notification ? ' mt-1' : ' mt-6';
      return (
        <div className="flex flex-col justify-between items-center h-5/6 w-full">
          <div className="w-full ">
            <div className="text-sm text-red-700 font-bold m-1 text-center">
              {this.state.notification}
            </div>
            <div
              className={
                'w-full flex flex-col justify-between items-center ' + paddTop
              }
            >
              <div className="text-gray-500 font-bold text-sm text-left w-3/4">
                Email
              </div>
              <input
                placeholder="abc@example.com"
                className="border border-gray-500 rounded w-3/4 h-8 pl-2 focus:outline-none focus:border-indigo-600"
                onChange={(e) => this.onEmailChange(e)}
                value={this.state.email}
              />
              <div className="mt-3  text-gray-500 font-bold text-sm text-left w-3/4">
                Password
              </div>
              <input
                type="password"
                placeholder="Password"
                className="border border-gray-500 rounded w-3/4 pl-2 h-8 focus:outline-none focus:border-indigo-600"
                onChange={(e) => this.onPasswordChange(e)}
                value={this.state.password}
              />
              <SignInButton
                onClickMethod={this.signUpWithEmail}
                name={'SIGN UP'}
              ></SignInButton>
              <div className="flex flex-col items-center w-full">
                <button className="font-bold mt-1 text-sm text-indigo-400 hover:underline focus:outline-none">
                  &nbsp;
                </button>
                <span className="m-3 text-gray-700"> OR </span>
                <button
                  className="w-3/4 h-16 max-w-xs font-bold shadow-sm rounded p-1 bg-blue-500 text-white flex items-center justify-center"
                  onClick={() => this.signUpWithGoogle()}
                >
                  <div className="h-full w-1/4 bg-white rounded">
                    <img
                      className="p-2 h-full w-full"
                      src="../assets/images/socials/google.svg"
                    />{' '}
                  </div>
                  <span className="h-full w-3/4 ml-4 text-white inline-flex items-center text-lg">
                    Sign in with Google
                  </span>
                </button>
              </div>
            </div>
          </div>
          <ProductOfTealpod />
        </div>
      );
    }
  }
);

export default SignUp;
