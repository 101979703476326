import React from 'react';
import ProductOfTealpod from './SignUp/ProductOfTealpod'

function mobileView() {
    return <div className="flex flex-col justify-between items-center bg-gray-100 h-full ">
        <div className="flex text-3xl mt-14">
            <div className="logo-watermark  px-2 inline-flex items-center ">Watermark</div >
            <div className="logo-here  px-2 inline-flex items-center ">Here</div>
        </div>
        <div className="flex flex-col items-center">
            <div className=" mx-14 bg-white text-lg px-3 py-4 rounded-xl">
                Hi There,
      <br></br>
                <br></br>
      WatermarkHere is an online photo watermarking tool. This is a web-app and doesn't support on mobile screens. Please try on Desktop/Laptops.
      <br></br>
                <br></br>
      Thank you,
      <br></br>
      WatermarkHere Team
      <br></br>

            </div>
            <a href="/help" target="_blank" className="text-center text-xl my-1 text-blue-500 underline cursor-pointer hover:text-blue-700" >Help</a>
        </div>
        <div className="flex justify-center h-20 w-full">
            <ProductOfTealpod />
        </div>
    </div>
}

export default mobileView