import React from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { appState, deleteUserWatermark } from '../../modals/basemodal';
import SingleSavedWatermark from './SingleSavedWatermark';
import fireAuth from '../../config/firebase';

export async function getThumbnails() {
  if (appState.loggedInUser) {
    var userRef = fireAuth
      .firestore()
      .collection('users')
      .doc(appState.loggedInUser.uid);
    await userRef.get().then(function (userInfo) {
      if (userInfo.exists) {
        appState.listOfStoredWatermarks = userInfo.data().userWatermarks;
      }
    });
  }
}
export async function getThumbnailsLength() {
  if (appState.loggedInUser) {
    var userRef = fireAuth
      .firestore()
      .collection('users')
      .doc(appState.loggedInUser.uid);
    await userRef.get().then(function (userInfo) {
      if (userInfo.exists) {
        let userWatermarks = userInfo.data().userWatermarks;
        if (userWatermarks && userWatermarks.length > 0) {
          appState.userHasSavedWatermark = true;
        }
      }
    });
  }
}
const DisplayItemsDialogue = observer(
  class DisplayItemsDialogue extends React.Component {
    constructor(props) {
      super(props);
    }

    customStyles = {
      content: {
        top: '40%',
        left: '45%',
        right: '-5%',
        bottom: '10%',
        transform: 'translate(-50%, -50%)',
        height: '20.5rem',
      },
    };

    closeModal = () => {
      appState.isMyWatermarksModalVisible = false;
      appState.isIconsModalVisible = false;
    };

    render() {
      return (
        <Modal
          isOpen={
            appState.isMyWatermarksModalVisible || appState.isIconsModalVisible
          }
          onRequestClose={this.closeModal}
          style={this.customStyles}
          ariaHideApp={false}
          preventScroll={true}
        >
          <div className="overflow-auto">
            <header className="flex justify-between h-12 w-yellow-bg rounded-tr rounded-tl shadow-lg ">
              <div className="pl-4 align-center text-2xl pt-2 font-semibold">
                {this.props.title}
              </div>
              <button onClick={() => this.closeModal()}>
                <span className=" text-4xl p-2 pr-4 font-semibold">
                  &times;
                </span>
              </button>
            </header>
            <div className="overflow-auto h-60 bg-gray-100">
              <div>{this.props.content}</div>
            </div>
          </div>
        </Modal>
      );
    }
  }
);

export default DisplayItemsDialogue;
