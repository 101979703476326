import React from "react";
import { observer } from "mobx-react";
import { observe } from "mobx";
import { fabric } from "fabric";
import FontSelector from "./FontSelector";
import {
  appState,
  deleteActiveWatermarkForAppState,
} from "../../modals/basemodal";
import {
  DEFAULT_OUTLINE_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SHADOW_COLOR,
  SHADOW_X_OFFSET,
  SHADOW_Y_OFFSET,
  SHADOW_BLUR,
} from "../../Constants";
import ColorPickerContainer from "../ColorPickerContainer";
import WmHereSlider from "./WmHereSlider";
import WmHereSwitch from "./WmHereSwitch";
import "rc-slider/assets/index.css";
//import ScrollArea from 'react-scrollbar';
const TextProperty = observer(
  class TextProperty extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        inputValue_Opacity: 1,
        inputValue_fontSize: 35,
        selectedFont: "arial black",
        activeColorPickerId: 0,
        shadowX: SHADOW_X_OFFSET,
        shadowY: SHADOW_Y_OFFSET,
        shadowBlur: SHADOW_BLUR,
        shadowColor: DEFAULT_SHADOW_COLOR,
        repeatPadding: 40,
        repeatAngle: 0,
        inputText: null,
        isRepeatEnabled: false,
        isBackgroundColorEnabled: false,
        isOutlineEnabled: false,
        isShadowEnabled: false,
        isFontAwesomeText: false,
      };

      observe(appState, "selectedText", (change) => {
        //console.log("Changed to XXX", appState.selectedText,appState.listOfTextRepeatObj);
        this.setTextProperty();
      });
    }

    setTextProperty() {
      if (appState.selectedText) {
        if (appState.selectedText.myid.match("fontAwesomeText")) {
          this.setState({ isFontAwesomeText: true });
        } else {
          this.setState({ isFontAwesomeText: false });
        }

        let textObj = appState.selectedText;
        let text = textObj ? textObj.text : null;
        if (
          appState.listOfTextRepeatObj &&
          appState.listOfTextRepeatObj[textObj.myid]
        ) {
          let repeatObj = appState.listOfTextRepeatObj[textObj.myid];
          let repeatObjPadding =
            appState.repeatText[appState.selectedText.myid].padding;
          this.setState({
            isRepeatEnabled: repeatObj.isRepeat,
            repeatPadding: repeatObjPadding,
            repeatAngle: repeatObj.angle,
          });
        } else {
          this.setState({
            isRepeatEnabled: false,
            repeatPadding: 40,
            repeatAngle: textObj.angle,
            inputValue_Opacity: textObj.opacity,
          });
        }
        let isBackgroundColor =
          textObj.backgroundColor === "transparent" ? false : true;
        let isOutline = textObj.stroke === "transparent" ? false : true;
        let isShadow = textObj.shadow === null ? false : true;
        this.setState({
          isBackgroundColorEnabled: isBackgroundColor,
          isOutlineEnabled: isOutline,
          isShadowEnabled: isShadow,
          inputText: text,
          inputValue_fontSize: textObj.fontSize,
          selectedFont: textObj.fontFamily,
        });
        if (isShadow) {
          this.setState({
            shadowX: textObj.shadow.offsetX,
            shadowY: textObj.shadow.offsetY,
            shadowBlur: textObj.shadow.blur,
            shadowColor: textObj.shadow.color,
          });
        } else {
          this.setState({
            shadowX: SHADOW_X_OFFSET,
            shadowY: SHADOW_Y_OFFSET,
            shadowBlur: SHADOW_BLUR,
            shadowColor: DEFAULT_SHADOW_COLOR,
          });
        }
      }
    }

    componentDidMount() {
      this.hideColorPickerOnMouseOutsideClick();
      this.setTextProperty();
    }

    // Same CODE in TextProperty and LogoProperty. (Because we need to change local state)
    hideColorPickerOnMouseOutsideClick() {
      var me = this;
      document
        .getElementById("root")
        .addEventListener("mousedown", bodyClick, false);
      function bodyClick(event) {
        var class_name = event.target.className;
        if (
          !(
            class_name === "color-label" ||
            class_name === "color-picker" ||
            class_name === "color-block" ||
            class_name === "color-strip"
          ) &&
          me.state.activeColorPickerId !== 0
        ) {
          me.setState({ activeColorPickerId: 0 });
        }
      }
    }

    onOpacityChange = (value) => {
      var myCanvas = appState.canvasId;
      if (isNaN(value)) {
        return;
      }
      this.setState({ inputValue_Opacity: value });

      if (this.state.isRepeatEnabled) {
        appState.listOfTextRepeatObj[appState.selectedText.myid].opacity =
          value;
      } else {
        appState.selectedText.opacity = value;
      }
      myCanvas.renderAll(myCanvas);
    };

    onFontSizeChange = (value) => {
      var myCanvas = appState.canvasId;
      if (isNaN(value)) {
        return;
      }
      this.setState({ inputValue_fontSize: value });
      appState.selectedText.fontSize = value;
      myCanvas.renderAll(myCanvas);
    };

    onFontFamilyChange = (fontFamily) => {
      var myCanvas = appState.canvasId;
      this.setState({ selectedFont: fontFamily });
      appState.selectedText.fontFamily = fontFamily;
      /*
       * Application is not updating the canvas font immediately because
       * it takes some time to download the font, so giving a
       */
      myCanvas.renderAll(myCanvas);
    };
    addIconToText = (iconName) => {
      var myCanvas = appState.canvasId;
      var iconCode = "";
      var iconHtmlCode = "";
      if (iconName === "copyright") iconCode = " \u00A9";
      iconHtmlCode = " &#169;";
      if (iconName === "registered") iconCode = " \u00AE";
      if (iconName === "trademark") iconCode = " \u2122";

      appState.selectedText.text = appState.selectedText.text + iconCode;
      this.setState({ inputText: appState.selectedText.text });
      myCanvas.renderAll(myCanvas);
    };

    onClickBold = () => {
      appState.isBold = !appState.isBold;
      var myCanvas = appState.canvasId;
      if (appState.isBold) {
        appState.selectedText.set("fontWeight", "Bold");
      } else {
        appState.selectedText.set("fontWeight", "");
      }
      myCanvas.renderAll();
    };

    onClickItalic = () => {
      appState.isItalic = !appState.isItalic;
      var myCanvas = appState.canvasId;
      if (appState.isItalic) {
        appState.selectedText.set("fontStyle", "italic");
      } else {
        appState.selectedText.set("fontStyle", "normal");
      }
      myCanvas.renderAll();
    };

    onClickUnderline = () => {
      appState.isUnderline = !appState.isUnderline;
      var myCanvas = appState.canvasId;
      if (appState.isUnderline) {
        appState.selectedText.set("underline", true);
      } else {
        appState.selectedText.set("underline", false);
      }
      myCanvas.renderAll();
    };

    setColorProps = (color, keyIndex) => {
      //console.log('COLOR:----------->', color, keyIndex);
      var myCanvas = appState.canvasId;
      if (keyIndex === 1) {
        appState.selectedText.set("fill", color);
      } else if (keyIndex === 2) {
        appState.selectedText.set("backgroundColor", color);
      } else if (keyIndex === 3) {
        appState.selectedText.set("stroke", color);
      } else if (keyIndex === 4) {
        this.setState({ shadowColor: color });
        const { shadowX, shadowY, shadowBlur } = this.state;
        var shadowClr =
          color + " " + shadowX + " " + shadowY + " " + shadowBlur;
        appState.selectedText.set("shadow", shadowClr);
      }
      myCanvas.renderAll(myCanvas);
    };

    setIsBackgroundColor = (isChecked) => {
      let checked = isChecked;
      this.setState({ isBackgroundColorEnabled: checked });
      var myCanvas = appState.canvasId;
      if (isChecked) {
        var bgColor =
          appState.backgroundColor !== "transparent" ||
            appState.backgroundColor !== null
            ? appState.backgroundColor
            : DEFAULT_BACKGROUND_COLOR;
        appState.selectedText.set("backgroundColor", bgColor);
      } else {
        appState.backgroundColor = appState.selectedText.backgroundColor;
        appState.selectedText.set("backgroundColor", "transparent");
      }
      myCanvas.renderAll(myCanvas);
    };

    setIsOutline = (isChecked) => {
      this.setState({ isOutlineEnabled: isChecked });
      var myCanvas = appState.canvasId;
      if (isChecked) {
        var bgColor =
          appState.outline !== "transparent" || appState.outline !== null
            ? appState.outline
            : DEFAULT_OUTLINE_COLOR;
        appState.selectedText.set("stroke", bgColor);
      } else {
        appState.outline = appState.selectedText.stroke;
        appState.selectedText.set("stroke", "transparent");
      }
      myCanvas.renderAll(myCanvas);
    };

    onOutlineColorChecked = (e) => {
      var myCanvas = appState.canvasId;
      if (!e.target.checked) {
        appState.selectedText.set("stroke", "none");
        myCanvas.renderAll(myCanvas);
      }
    };

    onShadowPropertyChange = (value, key) => {
      var myCanvas = appState.canvasId;
      var { shadowColor, shadowX, shadowY, shadowBlur } = this.state;
      if (isNaN(value)) {
        return;
      }
      if (key === "blur") {
        this.setState({ shadowBlur: value });
        shadowBlur = value;
      } else if (key === "shadowX") {
        this.setState({ shadowX: value });
        shadowX = value;
      } else if (key === "shadowY") {
        this.setState({ shadowY: value });
        shadowY = value;
      }

      var shadowClr =
        shadowColor + " " + shadowX + " " + shadowY + " " + shadowBlur;
      appState.selectedText.set("shadow", shadowClr);
      myCanvas.renderAll(myCanvas);
    };

    hideAndShowObject(oldObject, visibility, type) {
      var myCanvas = appState.canvasId;
      //console.log("hide and show", oldObject, visibility, type)
      if (oldObject) {
        if (visibility === "hide") {
          oldObject.opacity = 0;
          oldObject.isHidden = true;
          oldObject.selectable = false; // selectable is TRUE
          myCanvas.discardActiveObject(oldObject); // after repeat on property window getting hidden, We cant select repeated.
          if (type === "repeatedLogo") {
            oldObject.height = 0; // To Hide Repeate Object.
            oldObject.width = 0;
            myCanvas.remove(oldObject);
          } else {
            myCanvas.sendToBack(oldObject);
          }
        } else if (visibility === "show") {
          oldObject.opacity = 1;
          oldObject.isHidden = false;
          oldObject.selectable = true;
          if (type !== "repeatedLogo") {
            myCanvas.bringToFront(oldObject);
          } else if (type === "repeatedLogo") {
            // To Show hiddden Repeated Object.
            oldObject.height = appState.listOfAllUploadedImage[0].height * 6;
            oldObject.width = appState.listOfAllUploadedImage[0].width * 6;
            oldObject.selectable = false;
          }
          myCanvas.setActiveObject(oldObject);
          this.updateDefaultWatermark(oldObject);
        }
        myCanvas.renderAll(myCanvas);
      }
    }

    onShadowEnable = (isChecked) => {
      var myCanvas = appState.canvasId;
      this.setState({ isShadowEnabled: isChecked });
      var shadowClr = DEFAULT_SHADOW_COLOR + " " + 10 + " " + 10 + " " + 10;

      if (isChecked) {
        var bgColor =
          appState.textShadow !== null ? appState.textShadow : shadowClr;
        appState.selectedText.set("shadow", bgColor);
      } else {
        appState.textShadow = appState.selectedText.shadow;
        appState.selectedText.set("shadow", null);
      }
      myCanvas.renderAll(myCanvas);
    };

    onRepeatEnable = (isChecked) => {
      console.log(
        "text property repeat",
        isChecked,
        appState.listOfTextRepeatObj,
        appState.modifiedWatermarksList
      );
      var myCanvas = appState.canvasId;
      this.setState({ isRepeatEnabled: isChecked, inputValue_Opacity: 1 });
      appState.activeObjectType = "text";

      var me = this;
      if (
        isChecked &&
        !appState.listOfTextRepeatObj[appState.selectedText.myid]
      ) {
        // this.currentTextRepeatObj === null
        this.setState({ repeatPadding: 40, repeatAngle: appState.selectedText.angle });
        var textObj = new fabric.IText(appState.selectedText.text, {
          fontFamily: appState.selectedText.fontFamily,
          fill: appState.selectedText.fill, //'rgb(255, 0, 0)',
          backgroundColor: appState.selectedText.backgroundColor,
          myid: appState.selectedText.myid, //newID,
          stroke: appState.selectedText.stroke,
          shadow: appState.selectedText.shadow,
          padding: me.state.repeatPadding,
        });
        appState.repeatText[appState.selectedText.myid] = textObj;
        me = this;
        var patternSourceCanvas = new fabric.StaticCanvas();
        patternSourceCanvas.add(textObj);
        patternSourceCanvas.renderAll();
        var pattern = new fabric.Pattern({
          source: function () {
            textObj.text = appState.allAddedText[textObj.myid].text;
            textObj.fontSize = appState.allAddedText[textObj.myid].fontSize;
            textObj.set("fill", appState.allAddedText[textObj.myid].fill);
            textObj.fontFamily = appState.allAddedText[textObj.myid].fontFamily;
            textObj.set(
              "backgroundColor",
              appState.allAddedText[textObj.myid].backgroundColor
            );
            textObj.set("stroke", appState.allAddedText[textObj.myid].stroke);
            textObj.set("shadow", appState.allAddedText[textObj.myid].shadow);
            textObj.set(
              "underline",
              appState.allAddedText[textObj.myid].underline
            );
            textObj.set(
              "fontWeight",
              appState.allAddedText[textObj.myid].fontWeight
            );
            textObj.set(
              "fontStyle",
              appState.allAddedText[textObj.myid].fontStyle
            );
            patternSourceCanvas.setDimensions({
              width:
                textObj.getScaledWidth() + textObj.padding + me.state.shadowX,
              height:
                textObj.getScaledHeight() + textObj.padding + me.state.shadowY,
            });
            patternSourceCanvas.renderAll();
            return patternSourceCanvas.getElement();
          },
          repeat: "repeat",
        });

        var previous_repeatLogoObj =
          appState.listOfTextRepeatObj[appState.selectedText.myid]; // me.currentTextRepeatObj;
        // create a rectangle object

        appState.listOfTextRepeatObj[appState.selectedText.myid] =
          new fabric.Rect({
            fill: pattern,

            width: appState.listOfAllUploadedImage[0].width * 5,
            height: appState.listOfAllUploadedImage[0].height * 5, //window.outerHeight * 2,
            objectCaching: false, // very imp
            originX: "center",
            originY: "center",
            left: 0, // 25% of canvas width
            top: 0, // 25% of canvas height.
            myid: appState.selectedText.myid,
            isHidden: false,
            angle: me.state.repeatAngle,
            isRepeat: true,
          });
        if (!previous_repeatLogoObj) {
          myCanvas.add(
            appState.listOfTextRepeatObj[appState.selectedText.myid]
          );
          myCanvas.sendToBack(
            appState.listOfTextRepeatObj[appState.selectedText.myid]
          );
        }
      }

      if (isChecked === false) {
        if (appState.listOfTextRepeatObj[appState.selectedText.myid] !== null) {
          appState.listOfTextRepeatObj[
            appState.selectedText.myid
          ].isRepeat = false;
          this.hideAndShowObject(
            appState.listOfTextRepeatObj[appState.selectedText.myid],
            "hide",
            "repeatedLogo"
          );
          this.hideAndShowObject(appState.selectedText, "show", "text");
          appState.listOfTextRepeatObj[appState.selectedText.myid] = null; // repeatedLogo object is removed in hideAndShowObject()
        }
      } else if (isChecked === true) {
        if (appState.listOfTextRepeatObj[appState.selectedText.myid] !== null) {
          this.hideAndShowObject(appState.selectedText, "hide", "text"); // HIDE then SHOW
          this.hideAndShowObject(
            appState.listOfTextRepeatObj[appState.selectedText.myid],
            "show",
            "repeatedLogo"
          );
        }
      }
      //console.log('CHECK-->', isChecked, appState.selectedText.myid, appState.listOfTextRepeatObj)
      myCanvas.renderAll(myCanvas);
    };

    onRepeatPropertyChange = (value, key) => {
      var myCanvas = appState.canvasId;
      if (key === "padding") {
        this.setState({ repeatPadding: value });
        appState.repeatText[appState.selectedText.myid].padding = value;
      }
      myCanvas.renderAll(myCanvas);
    };

    onAngleChange = (value) => {
      var myCanvas = appState.canvasId;
      this.setState({ repeatAngle: value });
      appState.selectedText.angle = value;
      if (appState.listOfTextRepeatObj && appState.listOfTextRepeatObj[appState.selectedText.myid]) {
        appState.listOfTextRepeatObj[appState.selectedText.myid].angle = value;
      }
      myCanvas.renderAll(myCanvas);
    }

    activeColorPicker = (activeIndex) => {
      this.setState({ activeColorPickerId: activeIndex });
    };

    onTextEdit = (e) => {
      var myCanvas = appState.canvasId;
      this.setState({ inputText: e.target.value });
      appState.selectedText.text = e.target.value;
      myCanvas.renderAll(myCanvas);
    };

    deleteTextObject = () => {
      var myCanvas = appState.canvasId;
      myCanvas.remove(appState.selectedText);
      if (appState.listOfTextRepeatObj[appState.selectedText.myid]) {
        myCanvas.remove(
          appState.listOfTextRepeatObj[appState.selectedText.myid]
        );
      }
      appState.noOfWaterMarksAdded = appState.noOfWaterMarksAdded - 1;
      deleteActiveWatermarkForAppState(appState.selectedText.myid);
      myCanvas.renderAll(myCanvas);
    };

    updateDefaultWatermark(updatedText) {
      if (updatedText) {
        console.log(updatedText.myid);
        if (
          appState.listOfDefaultWatermarkIds.indexOf(updatedText.myid) > -1 &&
          appState.modifiedWatermarksList
        ) {
          appState.modifiedWatermarksList._defaultWatermarks._allWatermarks[
            updatedText.myid
          ] = updatedText;
        }
      }
      console.log(appState.modifiedWatermarksList);
    }

    render() {
      const {
        inputValue_Opacity,
        inputText,
        inputValue_fontSize,
        selectedFont,
        shadowBlur,
        shadowX,
        activeColorPickerId,
        repeatPadding,
        repeatAngle,
        shadowY,
      } = this.state;
      let textColorPicker = "";
      let shadowFontColorPicker = "";
      if (this.state.isFontAwesomeText === true && this.state.isShadowEnabled) {
        shadowFontColorPicker = "fontawesome-color-picker";
      } else if (this.state.isShadowEnabled) {
        textColorPicker = "text-color-picker";
      }
      return appState.selectedProperty === "text" ? (
        <div className="h-full p-2 overflow-auto border border-gray-400 rounded-lg">
          <div className="flex flex-1 props-area">
            <div className="flex-1 px-2 bg-gray-100">
              {/*<ScrollArea className="h-4/5">*/}
              {/*<div className="">*/}
              {this.state.isFontAwesomeText === false ? (
                <textarea
                  className="block w-full p-2 mt-4 text-gray-800 bg-gray-200 border border-gray-500 rounded-md sm:text-md"
                  placeholder="Enter text"
                  value={
                    inputText === null && appState.selectedText
                      ? appState.selectedText.text
                      : inputText
                  }
                  onChange={this.onTextEdit}
                  autosize={{ minRows: 1 }}
                />
              ) : null}
              {this.state.isFontAwesomeText === false ? (
                <div className="flex flex-row flex-wrap mt-2">
                  <div className="flex justify-center py-1 text-gray-800 rounded-lg">
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.onClickBold()}
                    >
                      <i className="text-xl fa fa-bold"></i>
                    </div>
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.onClickItalic()}
                    >
                      <i className="text-xl fa fa-italic"></i>
                    </div>{" "}
                    {/* &#169;  &#174;  &#8482;*/}
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.onClickUnderline()}
                    >
                      <i className="text-xl fa fa-underline"></i>
                    </div>
                  </div>
                  <div className="flex justify-center py-1 text-gray-800 rounded-lg">
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.addIconToText("copyright")}
                    >
                      <i className="text-xl fa fa-copyright"></i>
                    </div>
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.addIconToText("registered")}
                    >
                      <i className="text-xl fa fa-registered"></i>
                    </div>{" "}
                    {/* &#169;  &#174;  &#8482;*/}
                    <div
                      className="px-1 mx-1"
                      onClick={() => this.addIconToText("trademark")}
                    >
                      <i className="text-xl fa fa-trademark"></i>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.isFontAwesomeText === false ? (
                <div className="flex justify-between mt-2 ">
                  <div className="pt-2 mr-2 text-gray-800 apply-font">Font</div>
                  <FontSelector
                    onFontFamilyChange={this.onFontFamilyChange}
                    selectedFont={selectedFont}
                    className="w-full mt-2 text-gray-800 border border-gray-300 rounded-md"
                  />
                </div>
              ) : null}
              <WmHereSlider
                className="mt-1"
                title="Size"
                min={10}
                max={200}
                step={1}
                onChange={this.onFontSizeChange}
                value={
                  typeof inputValue_fontSize === "number"
                    ? inputValue_fontSize
                    : 0
                }
              />

              <WmHereSlider
                min={0}
                max={1}
                title="Opacity"
                step={0.1}
                onChange={this.onOpacityChange}
                value={
                  typeof inputValue_Opacity === "number"
                    ? inputValue_Opacity
                    : 0
                }
              />
              <WmHereSlider
                title="Rotate"
                min={0}
                max={360}
                step={1}
                onChange={(e) => this.onAngleChange(e, "angle")}
                value={typeof repeatAngle === "number" ? repeatAngle : 0}
              />
              <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>

              <tr className="flex w-full min-w-full">
                <td className="w-1/2 text-gray-800 min-w-1/2">
                  {this.state.isFontAwesomeText ? "Color" : "Text color"}
                </td>
                <td className="w-1/4 min-w-1/4"></td>
                <td className={"min-w-1/4 w-1/4 relative " + textColorPicker}>
                  <ColorPickerContainer
                    key="1"
                    pickerId={1}
                    activeColorPicker={this.activeColorPicker}
                    activeColorPickerId={activeColorPickerId}
                    setColorProps={this.setColorProps}
                  />
                </td>
              </tr>

              <tr className="flex w-full min-w-full mt-2 mt-4 ">
                <div className="w-1/2 mr-2 text-gray-800 min-w-1/2 ">
                  Background
                </div>
                <td className="w-1/4 min-w-1/4">
                  <WmHereSwitch
                    onChange={this.setIsBackgroundColor}
                    checked={this.state.isBackgroundColorEnabled}
                  />
                </td>
                {this.state.isBackgroundColorEnabled ? (
                  <td className={"min-w-1/4 w-1/4 relative " + textColorPicker}>
                    <ColorPickerContainer
                      key="2"
                      pickerId={2}
                      activeColorPicker={this.activeColorPicker}
                      activeColorPickerId={activeColorPickerId}
                      setColorProps={this.setColorProps}
                    />
                  </td>
                ) : (
                  <td className="w-1/4 min-w-1/4"></td>
                )}
              </tr>
              <tr className="flex w-full min-w-full mt-2">
                <td className="w-1/2 mr-2 text-gray-800 min-w-1/2 ">Outline</td>
                <td className="w-1/4 min-w-1/4">
                  <WmHereSwitch
                    onChange={this.setIsOutline}
                    checked={this.state.isOutlineEnabled}
                  />
                </td>
                {this.state.isOutlineEnabled ? (
                  <td className={"min-w-1/4 w-1/4 relative " + textColorPicker}>
                    <ColorPickerContainer
                      key="3"
                      pickerId={3}
                      activeColorPicker={this.activeColorPicker}
                      activeColorPickerId={activeColorPickerId}
                      setColorProps={this.setColorProps}
                    />
                  </td>
                ) : (
                  <td className="w-1/4 min-w-1/4"></td>
                )}
              </tr>
              <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>

              <tr className="flex w-full min-w-full mt-2">
                <td className="w-1/2 mr-2 font-bold text-gray-800 min-w-1/2">
                  Shadow
                </td>
                <td className="w-1/4 min-w-1/4">
                  <WmHereSwitch
                    onChange={this.onShadowEnable}
                    checked={this.state.isShadowEnabled}
                  />
                </td>
                {this.state.isShadowEnabled ? (
                  <td
                    className={
                      "min-w-1/4 w-1/4 relative text-color-picker " +
                      shadowFontColorPicker
                    }
                  >
                    <ColorPickerContainer
                      key="4"
                      pickerId={4}
                      activeColorPicker={this.activeColorPicker}
                      activeColorPickerId={activeColorPickerId}
                      setColorProps={this.setColorProps}
                    />
                  </td>
                ) : (
                  <td className="w-1/4 min-w-1/4"></td>
                )}
              </tr>
              {this.state.isShadowEnabled ? (
                <div className="p-2 bg-gray-200 rounded-md">
                  <WmHereSlider
                    className="mt-1"
                    title="Blur"
                    min={0}
                    max={100}
                    step={1}
                    onChange={(e) => this.onShadowPropertyChange(e, "blur")}
                    value={typeof shadowBlur === "number" ? shadowBlur : 0}
                  />
                  <WmHereSlider
                    className="mt-1"
                    title="X-Offset"
                    min={0}
                    max={100}
                    step={1}
                    onChange={(e) => this.onShadowPropertyChange(e, "shadowX")}
                    value={typeof shadowX === "number" ? shadowX : 0}
                  />
                  <WmHereSlider
                    className="mt-1"
                    title="Y-Offset"
                    min={0}
                    max={100}
                    step={1}
                    onChange={(e) => this.onShadowPropertyChange(e, "shadowY")}
                    value={typeof shadowY === "number" ? shadowY : 0}
                  />
                </div>
              ) : null}
              <hr className="my-2 bg-gray-300 h-0.5 shadow-lg"></hr>
              <tr className="flex w-full min-w-full mt-2">
                <td className="w-1/2 mr-1 font-bold text-gray-800 min-w-1/2 ">
                  Repeat
                </td>
                <td>
                  <WmHereSwitch
                    onChange={this.onRepeatEnable}
                    checked={this.state.isRepeatEnabled}
                  />
                </td>
              </tr>
              {this.state.isRepeatEnabled ? (
                <div className="p-2 bg-gray-200 rounded-md">
                  <WmHereSlider
                    title="Padding"
                    min={0}
                    max={300}
                    step={1}
                    onChange={(e) => this.onRepeatPropertyChange(e, "padding")}
                    value={
                      typeof repeatPadding === "number" ? repeatPadding : 0
                    }
                  />
                </div>
              ) : null}
              <div className="flex justify-center">
                <button
                  onClick={this.deleteTextObject}
                  className="flex items-center justify-center p-2 mt-4 mb-2 font-medium text-white bg-red-700 rounded-lg hover:bg-red-800 focus:outline-none"
                >
                  {/* <DeleteSvgIcon className={"pointer-events-none w-6 h-6 mr-1 text-white"} ></DeleteSvgIcon> */}
                  Delete{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null;
    }
  }
);

export default TextProperty;
