import React from 'react';
import { observer } from 'mobx-react';
import { fabric } from 'fabric';
import { appState } from '../../modals/basemodal';
import { DEFAULT_TEXT_COLOR } from '../../Constants';
import Icons from './Icons';

export function addFontAwesome(unicode) {
  var myCanvas = appState.canvasId;
  let newID = new Date().getTime().toString().substr(5);

  var text = new fabric.Text(unicode, {
    fontFamily: 'FontAwesome',
    fill: DEFAULT_TEXT_COLOR,
    backgroundColor: 'transparent',
    stroke: 'transparent',
    strokeWidth: 1,
    left: 10,
    top: 10,
    fill: 'white',
    myid: 'fontAwesomeText' + newID,
    opacity: 1,
    fontSize: 45,
    shadow: null,
    isHidden: false,
    hasRotatingPoint: false,
    lockUniScaling: true,
  });
  myCanvas.add(text);
  myCanvas.setActiveObject(text);
  appState.defaultWatermarksList.addWatermark(text);
  appState.listOfDefaultWatermarkIds.push(text.myid);
  appState.noOfWaterMarksAdded++;
}
const WatermarkTypes = observer(
  class WatermarkTypes extends React.Component {
    constructor(props) {
      super(props);

      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
      this.refs.fileUploader.click();
    }

    addText() {
      var myCanvas = appState.canvasId;
      appState.textShadow = null;
      if (myCanvas) {
        let newID = new Date().getTime().toString().substr(5);
        let text = new fabric.IText('Enter Text', {
          fontFamily: 'arial black',
          fill: DEFAULT_TEXT_COLOR,
          backgroundColor: 'transparent',
          stroke: 'transparent',
          strokeWidth: 1,
          left: 10,
          top: 10,
          myid: 'text' + newID, // 't' is imp when creation object we cant use number if we add "t" it will consider as string. eg: textObj["t123456"]  => t = text
          opacity: 1,
          fontSize: 35,
          fontStyle: 'normal',
          fontWeight: '',
          underline: false,
          shadow: null,
          isHidden: false,
          lockUniScaling: true,
          hasRotatingPoint: false,
          opacity: 1,
          angle:0,
        });
        myCanvas.add(text);
        myCanvas.setActiveObject(text);
        appState.defaultWatermarksList.addWatermark(text);
        appState.listOfDefaultWatermarkIds.push(text.myid);
        appState.noOfWaterMarksAdded++;
      }
    }

    addLogo(e) {
      appState.logoShadow = null;
      this.refs.fileUploader.click();
      var myCanvas = appState.canvasId;
      if (myCanvas && e.target.files[0]) {
        var newID = new Date().getTime().toString().substr(5);
        var reader = new FileReader();
        let fileType = e.target.files[0].name.split('.')[1];
        reader.onload = function (event) {
          if (fileType === 'svg') {
            fabric.loadSVGFromURL(
              event.target.result,
              function (objects, options) {
                let svgImage = fabric.util.groupSVGElements(objects, options);
                svgImage
                  .set({
                    left: 1,
                    top: 1,
                    cornerSize: 10,
                    myid: 'svgLogo' + newID,
                    shadow: null,
                    isHidden: false,
                    lockUniScaling: true,
                    hasRotatingPoint: false,
                    opacity: 1,
                    angle: 0,
                  })
                  .setCoords();

                myCanvas.add(svgImage);
                myCanvas.setActiveObject(svgImage);
                appState.defaultWatermarksList.addWatermark(svgImage);
                appState.listOfDefaultWatermarkIds.push(svgImage.myid);
                appState.noOfWaterMarksAdded++;
              }
            );
          } else {
            let imgObj = new Image();
            imgObj.src = event.target.result;
            imgObj.onload = function () {
              let image = new fabric.Image(imgObj);
              image.set({
                left: 10,
                top: 10,
                angle: 0,
                //padding: 10,
                cornerSize: 10,
                myid: 'logo' + newID, // 'i' is imp when creation object we cant use number if we add "i" it will consider as string. eg: textObj["i123456"] => i = image
                shadow: null, //'rgb(250,55,100) 10px 10px 10px',
                isHidden: false,
                lockUniScaling: true,
                hasRotatingPoint: false,
                opacity: 1,
              });
              myCanvas.add(image);
              myCanvas.setActiveObject(image);
              appState.defaultWatermarksList.addWatermark(image);
              appState.listOfDefaultWatermarkIds.push(image.myid);
              appState.noOfWaterMarksAdded++;
            };
          }
        };
      }
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = null;
      this.refs.fileUploader.value = '';
    }

    onIconsClick() {
      appState.isIconsModalVisible = true;
    }

    render() {
      return (
        <div>
          <div className="flex flex-row justify-center">
            <button
              className="h-10 px-4 py-2 mr-4 font-bold text-white bg-green-500 rounded-lg xl:h-8 xl:px-2 xl:py-1 lg:h-8 lg:py-0 lg:px-1 2xl:h-10 2xl:px-4 2xl:py-2 hover:bg-green-800 w-36 xl:w-32 lg:w-32 2xl:w-36 focus:outline-none"
              onClick={() => this.addText()}
            >
              Add Text
            </button>
            <button 
              className="h-10 px-4 py-2 mr-4 font-bold text-white bg-pink-500 rounded-lg xl:h-8 xl:px-2 xl:py-1 lg:h-8 lg:py-0 2xl:h-10 2xl:px-4 2xl:py-2 hover:bg-pink-800 w-36 xl:w-32 2xl:w-36 focus:outline-none"
              onClick={(e) => this.handleClick(e)}
            >
              Add Logo
              <input
                type="file"
                id="file"
                ref="fileUploader"
                style={{ display: 'none' }}
                onChange={(e) => this.addLogo(e)}
              />
            </button>
            <button
              className="h-10 px-4 py-2 mr-4 font-bold text-white bg-indigo-600 rounded-lg xl:h-8 xl:px-2 xl:py-1 lg:h-8 lg:py-0 2xl:h-10 2xl:px-4 2xl:py-2 hover:bg-indigo-800 w-36 xl:w-32 2xl:w-36 focus:outline-none"
              onClick={() => this.onIconsClick()}
            >
              Add Icons
            </button>
          </div>
          {appState.isIconsModalVisible ? <Icons /> : null}
        </div>
      );
    }
  }
);

export default WatermarkTypes;
