import React from 'react';
import fireAuth from '../../config/firebase';
import { Link, withRouter } from 'react-router-dom';

class LoggedInUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUserDropDownOpen: false
        };
        this.setIsUserDropDownOpen = this.setIsUserDropDownOpen.bind(this);

    }

    signOutUser = function () {
        fireAuth.auth().signOut().then(function () {
            console.log("Sign out success")
            window.location.href = "/"
        }).catch(function (error) {
            console.log("Sign out failure-1: ", error)
        });
    }

    /*signOutUser = function (history) {
        fireAuth.auth().signOut().then(function () {
            console.log('Sign out success');
            history.push('/');
          }).catch(function (error) {
            console.log('Sign out failure-2: ', error);
          });
      };*/

    setIsUserDropDownOpen = function () {
        this.setState({ isUserDropDownOpen: !this.state.isUserDropDownOpen });
    }

    render() {

        return (
            <div className="mx-1">
                {/* <a href="/pricing" className="m-2 text-black cursor-pointer font-bold" >Pricing</a> */}
                <button className="h-8 w-8 bg-gray-700 p-2 m-1 rounded-full cursor-pointer hover:bg-gray-800 focus:outline-none"
                    onClick={() => this.setIsUserDropDownOpen()}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                </button>
                {this.state.isUserDropDownOpen ?
                    <div className={"origin-top-right absolute right-0 mt-1 w-40 rounded-md shadow-xl bg-white ring-1 ring-black ring-opacity-5"}>
                        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        <Link
                            to="/account" className="block px-4 py-2 text-base text-gray-700 hover:bg-blue-500 hover:text-white" role="menuitem"
                            >Account
                        </Link>
                            <button type="submit" className="block w-full text-left px-4 py-2 text-base text-gray-700 hover:bg-blue-500 hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                                role="menuitem" onClick={() => this.signOutUser()}>
                                Sign out
                    </button>
                        </div>
                    </div> : null}
            </div>
        )
    }
}

export default withRouter(LoggedInUser);