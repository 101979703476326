import React from "react";
import { observer } from "mobx-react";
import { appState, deleteImage, clearAllData } from "../../modals/basemodal";
import ThumbnailComponent from "./ThumbnailComponent";
import resizeImage from "resize-image";
import DeleteSvgIcon from "../DeleteSvgIcon";
import ImageEditor from "filerobot-image-editor";
import { downloadImage } from "../Download/utils";

const ThumbnailContainer = observer(
  class ThumbnailContainer extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        previewVisible: false,
        previewImage: "",
        currentImage: null,
        showSingleDownloadSpinner: false,
        currentImageIndex: -1,
      };
    }

    clearAll = () => {
      clearAllData();
    };

    deleteButton = (eachImage) => {
      return (
        <button
          onClick={() => deleteImage(eachImage)}
          className="p-1 ml-auto text-white bg-red-600 rounded-full focus:outline-none"
        >
          <DeleteSvgIcon
            className={"pointer-events-none w-4 h-4 ml-auto"}
          ></DeleteSvgIcon>
        </button>
      );
    };

    // editButton = (eachImage) => {
    //   return (
    //     <button
    //       onClick={() => this.setState({ currentImage: eachImage.src })}
    //       className="p-1 text-white bg-blue-600 rounded-full focus:outline-none"
    //     >
    //       <EditSvgIcon
    //         className={'pointer-events-none w-6 h-4 ml-auto'}
    //       ></EditSvgIcon>
    //     </button>
    //   );
    // };

    clearAllListItem = () => {
      return (
        <li
          key="clearAllButton"
          className="flex h-full border border-gray-300 rounded-md"
        >
          <button
            className="w-20 h-16 py-1 text-sm text-black bg-white rounded-lg shadow hover:bg-red-400 px-"
            onClick={this.clearAll}
          >
            <div className="flex justify-center">
              <DeleteSvgIcon
                className={"pointer-events-none w-5 h-5"}
              ></DeleteSvgIcon>
            </div>
            <span>Clear All</span>
          </button>
        </li>
      );
    };

    editorConfig = {
      colorScheme: "light",
      tools: ["crop", "adjust", "effects", "filters", "rotate"],
      translations: {
        en: {
          "toolbar.download": "Save",
          "toolbar.apply": "Apply",
          "header.image_editor_title": "Image editor",
        },
      },
    };

    onComplete = async (newUrl) => {
      let indexToBeModify;
      appState.listOfAllUploadedImage.forEach((imgObj, index) => {
        if (imgObj.src === this.state.currentImage) {
          indexToBeModify = index;
        }
      });
      let updatedImageUrl = newUrl.canvas.toDataURL("image/jpeg", 1);
      await this.updateImage(indexToBeModify, updatedImageUrl);
    };

    async updateImage(index, updatedImageUrl) {
      let image = appState.listOfAllUploadedImage[index];
      await this.loadImage(updatedImageUrl).then((img) => {
        image.src = updatedImageUrl;
        image.imgTag = img;
        image.width = img.width;
        image.height = img.height;
      });
    }

    loadImage(src) {
      return new Promise((resolve, reject) => {
        let img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    }

    resizeByWidth(width, orgImage) {
      let height = "";
      height = this.getDisabledDimensionValue(
        width,
        orgImage.width,
        orgImage.height
      );
      let newImgData = resizeImage.resize(
        orgImage,
        width,
        height,
        resizeImage.JPEG
      );
      return newImgData;
    }

    getDisabledDimensionValue(resizeValue, srcImgValue, srcValueToBeChanged) {
      let newDim = -1;
      if (srcImgValue > resizeValue) {
        let changedPercentage = (srcImgValue - resizeValue) / srcImgValue;
        let percentSize = srcValueToBeChanged * changedPercentage;
        newDim = srcValueToBeChanged - percentSize;
      } else if (srcImgValue < resizeValue) {
        let changedPercentage = (resizeValue - srcImgValue) / srcImgValue;
        let percentSize = srcValueToBeChanged * changedPercentage;
        newDim = srcValueToBeChanged + percentSize;
      } else {
        newDim = srcValueToBeChanged;
      }
      return newDim;
    }

    render() {
      // const { previewVisible, previewImage } = this.state;
      const fileList = this.props.fileList;
      let listOfImages = null;
      if (fileList) {
        listOfImages = fileList.map((eachImage, ind) => {
          let resizedImage = this.resizeByWidth(90, eachImage.imgTag);
          return (
            <div key={ind} className="flex flex-col">
              <ThumbnailComponent
                key={ind}
                SingleImage={resizedImage}
                deleteOption={
                  appState.currentIndex === 0
                    ? this.deleteButton(eachImage)
                    : null
                }
                // editOption={
                //   appState.currentIndex === 0 && this.editButton(eachImage)
                // }
                index={ind}
                currentIndex={appState.currentIndex}
              />
              {appState.currentIndex === 0 && (
                <p
                  onClick={() => this.setState({ currentImage: eachImage.src })}
                  className="mt-1 text-sm text-center text-black text-indigo-900 underline cursor-pointer"
                >
                  Edit
                </p>
              )}
              {appState.currentIndex === 2 && (
                <p
                  onClick={() =>
                    downloadImage(appState.listOfAllFinalImage[ind], ind)
                  }
                  className="mt-1 text-sm text-center text-black text-indigo-900 underline cursor-pointer"
                >
                  Download
                </p>
              )}
            </div>
          );
        });

        if (appState.currentIndex === 0) {
          listOfImages.push(this.clearAllListItem());
        }
      }
      if (fileList.length) {
        return (
          <>
            <div className="absolute w-screen C-TC bottom-1">
              {/*<div className="w-full h-full">
                             <div className="flex h-full ">*/}
              <ul className="flex flex-row pt-2 pl-1 pr-1 overflow-x-auto rounded">
                {listOfImages}
              </ul>
            </div>
            <ImageEditor
              show={this.state.currentImage ? true : false}
              src={this.state.currentImage}
              onClose={() => this.setState({ currentImage: undefined })}
              onBeforeComplete={(newUrl) => {
                this.onComplete(newUrl);
                return false;
              }}
              config={this.editorConfig}
            />
          </>
        );
      } else {
        return null;
      }
    }
  }
);

export default ThumbnailContainer;
