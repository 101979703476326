import { observe } from 'mobx';
import React from 'react';
import { appState } from '../../modals/basemodal'
import { observer } from 'mobx-react';

const PricingComp = observer((props) => {

    if (props.features && props.features.length > 0) {
        var listOfFeatures = props.features.map((eachFeature, index) => {
            return (<li key={index} className="my-1 antialiased font-medium ">{eachFeature}</li>)
        })
    }
    let actionButtonClass = "bg-blue-600 p-2 m-2 rounded w-1/3 font-bold text-center text hover:bg-blue-900 text-white cursor-pointer";

    let UpgradeButton = function () {
        if (props.subscriptionCall) {
            return (<div className={actionButtonClass} onClick={() => props.subscriptionCall()}>Upgrade</div>);
        } else {
            return SignUpButton();
        }
    }
    let SignUpButton = function () {
        return (<div className={actionButtonClass} onClick={() => { appState.isSignUpModalVisible = true }}>Sign In</div>);
    }
    let button = appState.loggedInUser ? <UpgradeButton /> : <SignUpButton />
    return (
        <div className={"flex flex-col items-center  h-full w-full rounded-2xl mt-4 mb-4 md:m-4 shadow-lg py-4 " + props.backgroundColor + " " + props.textColor}>
            <div className="py-2 text-2xl font-normal underline">{props.title}</div>
            <div className={" mt-2 text-4xl font-bold text-white " + props.discountDivCss}>{props.discountedPrice}</div>
            <div className={"mt-5 mb-3 text-xl text-yellow-300 underline " + props.savingsDivCss}>{props.savingsInfo}</div>
            <ul className="w-5/6 pl-6 mt-2 list-disc h-1/2">

                {listOfFeatures}

            </ul>
            {
                props.hideUpgradeButton ? null : (appState.isPaidUser? null: button)
            }

        </div>
    )
})

export default PricingComp;