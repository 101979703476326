import resizeImage from 'resize-image';


export function calculateDimForGivenPercent(srcDim, resizeDim) {
    let dim = -1;
    if (resizeDim < 100) {
        let percentSize = srcDim * (resizeDim / 100);
        dim = srcDim - percentSize;
    }
    else if (resizeDim > 100) {
        let percentSize = srcDim * ((resizeDim - 100) / 100);
        dim = srcDim + percentSize;
    } else {
        dim = srcDim;
    }
    return dim;
}


export function getDisabledDimensionValue(resizeValue, srcImgValue, srcValueToBeChanged) {
    let newDim = -1;
    if (srcImgValue > resizeValue) {
        let changedPercentage = (srcImgValue - resizeValue) / srcImgValue;
        let percentSize = srcValueToBeChanged * changedPercentage;
        newDim = srcValueToBeChanged - percentSize;
    }
    else if (srcImgValue < resizeValue) {
        let changedPercentage = (resizeValue - srcImgValue) / srcImgValue;
        let percentSize = srcValueToBeChanged * changedPercentage;
        newDim = srcValueToBeChanged + percentSize;
    } else {
        newDim = srcValueToBeChanged;
    }
    return newDim;

}

export function resizeByWidth(width, orgImage) {
    let height = ""
    height = getDisabledDimensionValue(width, orgImage.width, orgImage.height);
    let imgUrl = resizeImage.resize(orgImage, width, height, resizeImage.PNG);
    let newImgData = {'url' : imgUrl, 'resizedWidth' : width, 'resizedHeight' : height}
    return newImgData
}