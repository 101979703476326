import React from 'react';
import Dropzone from 'react-dropzone';
import FreeUserLimitDialog from '../FreeUserLimitDialog';

function AddPhotos(props) {
  let onDragDropFiles = function (files) {
    var fileArray = Object.values(files);
    //console.log("------------", files, typeof files, typeof fileArray, fileArray);

        /*if (!appState.isPaidUser && fileArray.length > 1) {
            appState.showFreeUserNotificationFor = FREE_USER_PHOTOS_LIMIT;
            fileArray = [Object.values(files)[0]]
        }
        if (!appState.isPaidUser && appState.listOfAllUploadedImage.length > 0) {
            appState.showFreeUserNotificationFor = FREE_USER_PHOTOS_LIMIT;
            fileArray = null
        }*/
    if (fileArray) {
      props.setFileList(fileArray);
    }
  };

  return (
    <>
      <Dropzone onDrop={(acceptedFiles) => onDragDropFiles(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <section className="flex h-full C-AP ">
            <div className="w-full text-center C-AP2 focus:outline-none">
              <div className="flex justify-center mt-8">
                <div
                  className="w-2/5 bg-white border-2 border-gray-300 border-dashed rounded-lg shadow-lg m- 3"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="flex flex-col items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-1/2 px-32 pt-16 opacity-50 h-1/3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    <div className="pb-16 m-2">
                      <strong>Drag and Drop your photos here</strong>

                      <p className="p-3 xl:p-2 2xl:p-3">or</p>
                      <button className="h-12 px-6 py-2 text-xl font-bold border rounded-lg shadow w-yellow-bg xl:text-lg 2xl:text-xl xl:h-10 2xl:h-12 xl:px-4 2xl:px-6 xl:py-1 2xl:py-2 hover:bg-yellow-500">
                        Add Photos
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      <FreeUserLimitDialog />
    </>
  );
}

export default AddPhotos;
