import React from 'react';
import { observer } from 'mobx-react';
import DownloadImage from './DownloadImage';


const Download = observer(class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = {


        }
    }

    render() {

        return (
            <div className="flex flex-col m-3 rounded-md items-center h-full">
                <div className="download-tab flex flex-col w-1/3 my-2 max-h-full bg-white pb-4 items-center rounded-lg shadow-xl">
                    <span className="p-2 py-4 text-gray-700 text-2xl"><strong>Download Images</strong></span>
                    <DownloadImage />
                </div>
            </div>
        )
    }
});







export default Download;