import React from 'react';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import './index.css';

import HomePage from './component/home';
import AccountPage from './component/account';
import PricingPage from './component/Pricing/PricingPage';
import HelpPage from './component/help';

import { isMobile } from 'react-device-detect';
import { Navbar } from './component/Navbar';
import MainArea from './component/MainArea';
import mobileView from './component/mobileView';

function App() {
  if (isMobile) {
    return (
      <BrowserRouter>
        <Route exact path="/home/" component={mobileView} />
        <Route exact path="/help/" component={HelpPage} />
        <Route exact path="/pricing" component={PricingPage} />
        <Route exact path="/" component={mobileView} />
      </BrowserRouter>
    );
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100 ">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/account" component={AccountPage} />
          <Route exact path="/pricing" component={PricingPage} />
          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/" component={MainArea} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
