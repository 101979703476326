import React from 'react';
import { appState } from '../../modals/basemodal'
import { addFontAwesome } from './WatermarkTypes'


function SingleIcons(props) {

    const onImageSelect = (e) => {
        addFontAwesome(props.iconUnicode)
        appState.isIconsModalVisible = false
    }

    return (
        <li className="border-gray-400 flex flex-row m-2 w-11 h-11 hover:border hover:border-2 hover:border-black hover:bg-blue-600 rounded-lg" onClick={onImageSelect}>
            <div className="flex justify-center select-none cursor-pointer w-10 h-10 bg-gray-200 rounded-md flex flex-1 items-center
              hover:shadow-lg hover:border hover:border-2 m-0.5">
                <i className={props.iconClassName}></i>
            </div>
        </li>
    )
}


export default SingleIcons;