import React from 'react';
import FontPicker from 'font-picker-react';
import FontFaceObserver from 'fontfaceobserver';
import AcknowledgeModal from '../AddPhotos/AcknowledgeModal';

import { GOOGLE_DEV_FONT_KEY, FREE_USER_FONTS_LIMIT } from '../../Constants';
import { appState } from '../../modals/basemodal';

const maxNumberOfFonts = 300; // 50 is default.
const sortBy = 'popularity'; //"alphabet"
const freeFontsForFreeUser = 4;
class FontSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFontFamily: this.props.selectedFont,
      showFontModal: false,
      paidFonts: [],
    };
    this.disableFontsForFreeUsers = this.disableFontsForFreeUsers.bind(this);
    this.fontPickerRef = React.createRef();
  }

  disableFontsForFreeUsers(lis) {
    if (!appState.isPaidUser) {
      if (lis) {
        const paidFonts = [];
        for (let i = 0; i < lis.length; i++) {
          if (i > freeFontsForFreeUser) {
            paidFonts.push(lis[i].firstChild.innerText);
            let currentLi = lis[i];
            let liButton = currentLi.firstChild; // or currentLi.childNodes[0]
            let currentButtonInnerHTML = liButton.innerHTML;
            liButton.innerHTML =
              '<span>' +
              currentButtonInnerHTML +
              '</span><span class="paidIndicator"></span>';
          }
        }
        this.setState({ paidFonts });
      }
    }
  }
  componentDidMount() {
    const me = this;
    setTimeout(function () {
      const picker = document.getElementById('font-picker');
      if (picker) {
        const list = picker.getElementsByTagName('li');
        me.disableFontsForFreeUsers(list);
      }
    }, 1000);
  }

  /*componentDidUpdate(prevProps, prevState){
        console.log(">>==222==> componentDidUpdate");
        let lis2 = document.getElementById("font-picker").getElementsByTagName("li");
        console.log(lis2.length);

        setTimeout(function(){ 
            console.log("2-XXXXX>>>>>", this.fontPickerRef);
            //console.log("2-#####>>>>>", this.fontPickerRef.loadingStatus);
        }, 1000); 
    }*/

  onFontSelect(userSelectedFont) {
    if (!appState.isPaidUser || !appState.loggedInUser) {
      const isPaidFont = this.state.paidFonts.findIndex(
        (font) => font === userSelectedFont.family
      );
      if (isPaidFont !== -1) {
        appState.showFreeUserNotificationFor = FREE_USER_FONTS_LIMIT;
        //this.setState({ showFontModal: true });
        return;
      }
    }

    let fontFamily = userSelectedFont.family;
    this.setState({
      activeFontFamily: fontFamily,
    });

    setTimeout(function () {
      //this.props.onFontFamilyChange(fontFamily);
    }, 500);

    const font = new FontFaceObserver(fontFamily);
    font.load().then(
      () => {
        //console.log(`${this.state.activeFontFamily} has loaded`);
        this.props.onFontFamilyChange(fontFamily);
      },
      () => {
        console.log(`${fontFamily} is not available`);
      }
    );
  }

  render() {
    return (
      <div>
        <FontPicker
          ref={this.fontPickerRef}
          apiKey={GOOGLE_DEV_FONT_KEY}
          activeFontFamily={this.state.activeFontFamily}
          onChange={(nextFont) => this.onFontSelect(nextFont)}
          limit={maxNumberOfFonts}
          sort={sortBy}
        />
        {/*<span className="apply-font">Sample Text</span>*/}
        {this.state.showFontModal && (
          <AcknowledgeModal
            heading="Upgrade to Premium"
            content="You must be a premium user to access this font. Please consider upgrading."
            onOkClick={() => this.setState({ showFontModal: false })}
          />
        )}
      </div>
    );
  }
}

export default FontSelector;
