import React from 'react';

function ProductOfTealpod() {

    return (
            <div className="flex w-full max-w-xs justify-center w-3/4 items-end absolute bottom-8 pb-5">
                <div className="mx-2 text-gray-700">
                    A product of
                    </div>
                <img src="https://d33wubrfki0l68.cloudfront.net/d2ba2cd3294c49f8a7b3b6e2bdc60d1bdcfaccda/42a09/teal-text-name.png"
                    className="h-8" alt="Tealpod"></img>
            </div>
        )
    }


export default ProductOfTealpod;

