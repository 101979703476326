
/**
 * this is list of all watermarks user added (if user add 3 watermarks and group 2 and 3 
 * then this dict will still have 3 items)
 * this is a dictionary not a array
   example:
    "created_time_for_icon" : null,
    "created_time_for_text1": null,
    "created_time_for_text2" : null
 */
let watermarksRaw = {
    "created_time_for_icon" : null,
    "created_time_for_text1": null,
    "created_time_for_text2" : null
}; 

//------------
export class DefaultWatermark {
    constructor( key){
      //  this._rawRef = rawRef;
        this._key = key;
        this._props = {};
        /*this.opacity = null
        this.size = null
        this.opacity = null
        this.other_props = null*/
    }
    getKey(){
        return this._key;
    }
    getProps(){
        return this._props;
    }
    /**
     * 
     * @param {*} key KEY will be from CONSTANTS.SIZE, POSITION, OPACITY ...
     * @param {*} val VAL be { 'width': '30%', 'height': '30%'} ...
     */
    setProp(key, val){
        this._props[key] = val;
    }

}
/**
 * This dictionary contains objects, one per watermark (if there are icon and 2 text objects on screen, 
 * then totally 3 elemnts in this dict)
 * Each watermark object default properties
 */
export class DefaultWatermarksList {
    constructor(){
        this._allWatermarks = {}
    }
    // called from Step-2, should send a instance of Watermark
    /**
     * 
     * let dw = new DefaultWatermark(key, rawRef)
     * defaultlist.addWatermark(dw)
     */
    addWatermark(d_wmk){
        let key = d_wmk.myid;
        this._allWatermarks[key] = d_wmk;
        return key;
    }
    deleteWatermark(key){
        if(this._allModifiedWatermarks[key]){
            delete this._allWatermarks[key];
        }
        // --- important ---
        // also delete this watermark from all the PerImageWatermarks
    }
    getAllWatermarks(){
        return this._allWatermarks;
    }
}
export class ModifiedWatermark {
    constructor(defaultWatermark){
        this._defaultWatermark = defaultWatermark;
        this._modifiedProps = {};
    }
    getKey(){
        return this._defaultWatermark.getKey();
    }
    getProps(){
        // club defaultWatermark.props + _modifiedProps and return
        return Object.assign({}, this._defaultWatermark.getProps(), this._modifiedProps)
    }
    setProp(key, val){
        this._modifiedProps[key] = val;
    }
}
export class ModifiedWatermarksList {
    constructor(defaultWatermarksList){
        this._defaultWatermarks = defaultWatermarksList;
        this._allModifiedWatermarks = {}
        this.allModifiedWatermarkOfEachImage = {}
    }
    getAllKeys(){
        return this._defaultWatermarks ;
    }
    totalNumberOfWatermarks(){
        return Object.keys( this._defaultWatermarks ).length;
    }
    getDefaultWatermarkByKey(key){
        if(this._defaultWatermarks._allWatermarks[key]){
            return this._defaultWatermarks._allWatermarks[key]
        }
    }
    setModifiedWaterMarks(key, val){
        this._allModifiedWatermarks[key] = val;
    }
    getModifiedWatermarkByKey(key){
        if(this._allModifiedWatermarks[key]){
            return this._allModifiedWatermarks[key]
        }
    }

    setModifiedWaterMarksOfEachImage(key, val){
        this.allModifiedWatermarkOfEachImage[key] = val;
    }
    getModifiedWatermarkByKeyOfEachImage(key){
        if(this.allModifiedWatermarkOfEachImage[key]){
            return this.allModifiedWatermarkOfEachImage[key]
        }
    }

    deleteModifiedWatermark(key){
        if(this._allModifiedWatermarks[key]){
            delete this._allModifiedWatermarks[key];
        }
        // --- important ---
        // also delete this watermark from all the PerImageWatermarks
    }
    deleteDefaultWatermark(key){
        if(this._defaultWatermarks._allWatermarks[key]){
            delete this._defaultWatermarks._allWatermarks[key];
        }
        // --- important ---
        // also delete this watermark from all the PerImageWatermarks
    }
}

export class AllImageWatermarks {
    constructor(){
        this.defaultWatermarks = new DefaultWatermarksList();
            //this.defaultWatermarks.addWatermarks(new Watermark("raw_reference"))
            //this.defaultWatermarks.addWatermarks(new Watermark("raw_ref"))
        /**
         * This array is exactly same as the number of images added
         * Each object in this array is a instance of WatermarksListPerImage
         */
        this.modifiedWatermarks = [];
    }
    getDefaultWatermarks(){
        return this.defaultWatermarks;
    }
    //---
    getModifiedWatermarks(imageIndex){
        let lwmks =  this.modifiedWatermarks[imageIndex]
        // if it is null create and insert into modifiedWatermarks
        if(null == lwmks){
            lwmks = new ModifiedWatermarksList(this.defaultWatermarks)
            this.modifiedWatermarks[imageIndex] = lwmks;
        }
        return lwmks;
    }
}


