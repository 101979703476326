export const CANVAS_HEIGHT = 768;
export const CANVAS_WIDTH = 1024;
export const LEFT_MARGIN_DIFF = 380;

export const ACTION_RESIZE = "ACTION_RESIZE";
export const ACTION_WATERMARK = "ACTION_WATERMARK";
export const ACTION_BORDER = "ACTION_BORDER";
export const ACTION_PHOTO_EFFECTS = "ACTION_PHOTO_EFFECTS";

export const PROCESSING = "PROCESSING";
export const COMPLETED = "COMPLETED";
export const DEFAULT_TEXT_COLOR = "white"
export const DEFAULT_OUTLINE_COLOR = "red"
export const DEFAULT_BACKGROUND_COLOR = "blue"
export const DEFAULT_SHADOW_COLOR = "black"
export const DEFAULT_SVG_IMAGE_COLOR = "black"
export const SHADOW_X_OFFSET = 5
export const SHADOW_Y_OFFSET = 5
export const SHADOW_BLUR = 10

export const IMAGE_COMPRESS_VALUE = 0.85

export const RESIZE_OPTIONS = ["Do not Resize", 'By Width', 'By Height', 'By Percentage'];

// WATERMARK PROPERTIES
export const WATERMARK_PROPERTIES = {
    "SIZE": "SIZE",
    "POSITION": "POSITION",
    "OPACITY": "OPACITY",
    "COLOR": "COLOR",
    "BORDER": "BORDER",
    "REPEAT": "REPEAT"
}

export const SUBSCRIPTION_PLANS = {
    638900: "YEARLY",
    638413: "MONTHLY"
}

//export const GOOGLE_DEV_FONT_KEY = "AIzaSyAP0wHtbDSouowme4aVrLMSsKHStkT8mjY";
export const GOOGLE_DEV_FONT_KEY = "AIzaSyAc6cssnu1UUUgEB4vI0iR5G-KP2GOXYpQ";

export const FREE_USER_PHOTOS_LIMIT = "FREE_USER_PHOTOS_LIMIT";
export const FREE_USER_FONTS_LIMIT = "FREE_USER_FONTS_LIMIT";
export const FREE_MY_WATERMARKS_LIMIT = "FREE_MY_WATERMARKS_LIMIT";
export const FREE_USER_DOWNLOAD_LIMIT = "FREE_USER_DOWNLOAD_LIMIT";

export const TEALPOD_GROUP_NO_REPLY_EMAIL_ID = "noreply@apps.tealpod.com";
