import React from 'react';
import { observer } from 'mobx-react';
import {
  appState,
  deleteActiveWatermarkForAppState,
} from '../../modals/basemodal';
import WmHereSlider from './WmHereSlider';
import DeleteSvgIcon from '../DeleteSvgIcon';

const GroupProperty = observer(
  class GroupProperty extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        inputValue_Opacity: 1,
      };
    }

    setLogoProperty() {
      if (appState.selectedGroup) {
        this.setState({ inputValue_Opacity: appState.selectedGroup.opacity });
      }
    }

    componentDidMount() {
      this.setLogoProperty();
    }

    onOpacityChange = (value) => {
      var myCanvas = appState.canvasId;
      if (isNaN(value)) {
        return;
      }
      this.setState({ inputValue_Opacity: value });
      appState.selectedGroup.opacity = value;
      myCanvas.renderAll(myCanvas);
    };

    deleteLogoObject = () => {
      var myCanvas = appState.canvasId;
      myCanvas.remove(appState.selectedGroup);
      deleteActiveWatermarkForAppState(appState.selectedGroup.myid);
      myCanvas.renderAll(myCanvas);
    };

    render() {
      const { inputValue_Opacity } = this.state;

      return appState.selectedProperty === 'group' ? (
        <div className="w-full rounded-lg border border-gray-400 p-2">
          <div className="text-center font-bold text-xl tracking-tight text-gray-700">
            Group Properties
          </div>
          <hr className="bg-gray-500 h-0.5 shadow-lg"></hr>
          <WmHereSlider
            title="Opacity"
            min={0}
            max={1}
            step={0.1}
            onChange={this.onOpacityChange}
            value={
              typeof inputValue_Opacity === 'number' ? inputValue_Opacity : 0
            }
          />
          <div className="flex justify-center">
            <button
              onClick={this.deleteLogoObject}
              className="mt-4 mb-2 p-2 flex justify-center font-medium bg-red-700 hover:bg-red-800 text-white focus:outline-none rounded-lg"
            >
              <DeleteSvgIcon
                className={
                  'pointer-events-none w-4 h-4 min-h-4 min-w-4 m-1 text-white'
                }
              ></DeleteSvgIcon>
              Delete{' '}
            </button>
          </div>
        </div>
      ) : null;
    }
  }
);

export default GroupProperty;
