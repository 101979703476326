import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import fireAuth from '../../config/firebase';
import ReactModal from 'react-modal';
import firebase from 'firebase';
import Login from './Login'
import SignUp from './SignUp'
import '../../index.css'


const LoginPopup = observer(class LoginPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            password: null,
            showOnlySignUpInput: false,
            notification: null,
            error: null,
            isLoginTab: 1
        }
    }

    customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: '50%',
            bottom: 'auto',
            marginRight: '-30%',
            marginBottom: '-50%',
            transform: 'translate(-50%, -50%)',
            height: 550,
            opacity: 1
        },
        overlay: {
            backgroundColor: "#9298a6d9",
        }
    };

    showSignUpModal = () => {
        this.setState({ showOnlySignUpInput: true });
    }

    componentWillUnmount = () => {
        this.setState({ isLoginTab: 1 });
    }

    handleCancel = () => {
        appState.isSignUpModalVisible = false;
        this.setState({ showOnlySignUpInput: false });
    }


    render() {
        let textStyleForLoginTab = this.state.isLoginTab ? " text-blue-600 border-b-4 border-blue-600  " : " text-gray-800 "
        let textStyleForSignUpTab = this.state.isLoginTab ? " text-gray-800 " : " text-blue-600 border-b-4 border-blue-600  "
        return (
            <div>
                <ReactModal
                    isOpen={appState.isSignUpModalVisible}
                    contentLabel="SIGN IN"
                    onOk={this.handleOk}
                    onRequestClose={this.handleCancel}
                    style={this.customStyles}
                    maskClosable={false}
                    ariaHideApp={false}
                    transparent={true} >
                    <div className="h-full bg-white ">
                        <nav className="flex justify-around w-full">
                            <button className={"w-1/3 rounded-tl text-lg font-bold py-2 block focus:outline-none focus:text-indigo-600 focus:bg-white focus:border-b-2 border-indigo-600" + textStyleForLoginTab}
                                onClick={() => { this.setState({ isLoginTab: 1 }) }}>
                                Login
                            </button>
                            <button className={"w-1/3 rounded-tr text-lg font-bold text-gray-800 py-2 block focus:outline-none focus:text-indigo-600 focus:bg-white focus:border-b-2 border-indigo-600" + textStyleForSignUpTab}
                                onClick={() => { this.setState({ isLoginTab: 0 }) }}>
                                Sign up
                            </button>
                        </nav>
                        {this.state.isLoginTab ? <Login /> : <SignUp />}
                    </div>
                </ReactModal>
            </div >
        )
    }
});

export default LoginPopup;
