import firebase from 'firebase'; 

/**
 * Previous authDomain before cross-origin issues
 *  OLD-->     authDomain: "tealpod-group.firebaseapp.com",
 *  NEW-->     authDomain: "app.watermarkhere.com",
 */

  const firebaseConfig = {
    apiKey: "AIzaSyAP0wHtbDSouowme4aVrLMSsKHStkT8mjY",
    authDomain: "app.watermarkhere.com",
    databaseURL: "https://tealpod-group.firebaseio.com",
    projectId: "tealpod-group",
    storageBucket: "tealpod-group.appspot.com",
    messagingSenderId: "1025790345698",
    appId: "1:1025790345698:web:7cc3b0a0dbaf17058d79bb"
  };


  const fireAuth = firebase.initializeApp(firebaseConfig);

  export default fireAuth;


