import { appState, clearAllData } from '../../modals/basemodal';
import WizardStep from './WizardStep';
import { getThumbnailsLength } from '../SelectWatermark/DisplayItemsDialogue';
import AcknowledgeModal from '../AddPhotos/AcknowledgeModal';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import React, { Component } from 'react';
import { TEALPOD_GROUP_NO_REPLY_EMAIL_ID } from '../../Constants/index';

const wizardInfo = [
  { title: 'Add Photos' },
  { title: 'Add Watermark' },
  { title: 'Download' },
];

const WizardComp = observer(
  class WizardComp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        previousButton: null,
        nextButton: null,
        noImageNotification: false,
      };
      const disposer = observe(appState, 'currentIndex', (change) => {
        this.previousButton();
        this.nextButton();
        if (this.isLastPage()) {
          this.startOverButton();
        }
      });
    }

    componentDidMount() {
      this.previousButton();
      this.nextButton();
      if (this.isLastPage()) {
        this.startOverButton();
      }
    }

    previousButton() {
      let bgColor = ' bg-blue-600 hover:bg-blue-800';
      let disable = false;
      if (appState.currentIndex === 0) {
        bgColor = 'opacity-0 cursor-auto';
        disable = true;
      }
      this.setState({
        previousButton: (
          <div className="inline-flex items-center pl-10">
            <button
              className={
                'flex items-center justify-center pr-2 focus:outline-none text-white font-bold py-2 w-32 xl:w-28 lg:w-24 lg:h-9 2xl:w-32 h-12 xl:h-10 2xl:h-12 rounded-lg ' +
                bgColor
              }
              onClick={() => this.previous()}
              disabled={disable}
            >
              <svg
                className="w-8 h-8 min-w-8 min-h-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
              Previous
            </button>
          </div>
        ),
      });
    }

    startOver() {
      window.location = '/';
      clearAllData();
    }

    startOverButton() {
      let bgColor = ' bg-green-600 hover:bg-green-800';
      this.setState({
        nextButton: (
          <div className="inline-flex items-center pr-10 min-w-24">
            <button
              className={
                'flex items-center justify-center pl-2 focus:outline-none text-white font-bold py-2 w-28  xl:w-28 2xl:w-32  h-12 lg:w-24 lg:h-9 xl:h-10 2xl:h-10 rounded-lg invisible ' +
                bgColor
              }
              // onClick={() => this.startOver()}
            ></button>
          </div>
        ),
      });
    }
    //TODO: write a single function for both previous and next
    nextButton() {
      let bgColor = ' bg-blue-600 hover:bg-blue-800';
      // if ((0 === appState.currentIndex && appState.listOfAllUploadedImage.length === 0) ||
      //     (1 === appState.currentIndex && appState.listOfDefaultWatermarkIds.length === 0)) {
      //     bgColor = " bg-gray-600 cursor-not-allowed"
      //     disable = true
      // }
      this.setState({
        nextButton: (
          <div className="inline-flex items-center pr-10 min-w-24">
            <button
              className={
                'flex items-center justify-center pl-2 focus:outline-none text-white font-bold py-2 w-32 xl:w-28 2xl:w-32 lg:w-24 lg:h-9 h-12 xl:h-10 2xl:h-12 rounded-lg ' +
                bgColor
              }
              onClick={() => this.next()}
            >
              Next
              <svg
                className="w-6 h-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        ),
      });
    }

    isLastPage() {
      return appState.currentIndex === wizardInfo.length - 1 ? true : false;
    }

    limits(ind) {
      ind = ind < 0 ? 0 : ind;
      let lastIndex = wizardInfo.length - 1;
      ind = ind > lastIndex ? lastIndex : ind;
      this.props.setWizardIndex(ind);
    }

    async previous() {
      let previousIndex = appState.currentIndex - 1;
      if (previousIndex === 1) {
        await getThumbnailsLength();
      }
      this.limits(previousIndex);
    }

    async next() {
      if (
        appState.currentIndex === 0 &&
        !appState.listOfAllUploadedImage.length > 0
      ) {
        this.setState({
          noImageNotification: true,
        });
      } else {
        let nextIndex = appState.currentIndex + 1;
        if (nextIndex === 1) {
          await getThumbnailsLength();
        }
        this.limits(nextIndex);
      }
    }

    setNoImageNotification() {
      this.setState({
        noImageNotification: false,
      });
    }

    render() {
      return (
        <div className="flex justify-between h-20 my-2 bg-white rounded-md shadow-md xl:mt-1 2xl:my-2 xl:mb-2 C-WC xl:h-14 2xl:h-20 mx-60 2xl:mx-72 lg:mx-36">
          {this.state.previousButton}
          <div className="w-full h-full py-3 xl:py-1 2xl:py-3">
            <div className="flex justify-center">
              <WizardStep index={0} title={wizardInfo[0].title} enable={true} />
              <WizardStep
                index={1}
                title={wizardInfo[1].title}
                enable={appState.listOfAllUploadedImage.length > 0}
              />
              <WizardStep
                index={2}
                title={wizardInfo[2].title}
                enable={appState.listOfDefaultWatermarkIds.length > 0}
              />
            </div>
          </div>
          {this.state.nextButton}
          {this.state.noImageNotification ? (
            <AcknowledgeModal
              heading={'Please add an image to proceed'}
              onOkClick={() => this.setNoImageNotification()}
            />
          ) : null}
          {appState.showNotificationModal && (
            <AcknowledgeModal
              heading={'Please verify your email'}
              content={
                'An email has been sent to your inbox with from ' +
                TEALPOD_GROUP_NO_REPLY_EMAIL_ID +
                '. Please click on the link and verify your email to login.'
              }
            />
          )}
        </div>
      );
    }
  }
);

export default WizardComp;
