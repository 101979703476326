import React from 'react';
import Iframe from 'react-iframe';
// C:\_work\projects\tealpod-website\tealpodwebsite\wm\....
function HelpPage() {
    return (
        <div className="h-screen">
            <Iframe url="https://tealpod.com/wm/help.html"
                width="100%"
                height="93%"
                id="faqID"
                className="faqClassName"
                display="initial"
                position="relative"/>
        </div>
    )
}

export default HelpPage;