import Slider from 'rc-slider';

function WmHereSlider(props) {
    return (
        <div className="mt-2 flex justify-between">
            <div className="w-1/2 min-w-1/2 text-gray-800 mr-1  ">{props.title}</div>
            <Slider className="mt-1 ml-2 mr-4" min={props.min} max={props.max} step={props.step} onChange={props.onChange} value={props.value}
                railStyle={{ background: "rgba(0, 0, 0, 0.3)", height: 6 }}
                trackStyle={{
                    transition: "0.1s ease background-color",
                    height: 6,
                    background: "#6056C4"
                }}
                handleStyle={{
                    height: 20,
                    width: 20,
                    borderRadius: "50%",
                    background: "#ffffff",
                    boxShadow: "0 2px 4px black",
                    border: "none",
                    marginTop: -10,
                }
                } />
            {/* <div span={6}>
                                <input type="number" min={0} max={100} style={{ width: '100%' }} step={1} value={shadowBlur} onChange={(e) => this.onShadowPropertyChange(e, 'blur')} />
                            </div> */}
        </div>
    )
}

export default WmHereSlider;