import React from 'react';
import { observer } from 'mobx-react';
import { appState } from '../../modals/basemodal';
import { getThumbnails } from './DisplayItemsDialogue';
import { FREE_MY_WATERMARKS_LIMIT } from '../../Constants';
import FreeUserLimitDialog from '../FreeUserLimitDialog';

const UploadWatermark = observer((props) => {
  async function myWatermarksOnClick() {
    if (!appState.isPaidUser) {
      appState.showFreeUserNotificationFor = FREE_MY_WATERMARKS_LIMIT;
    } else {
      appState.isMyWatermarksModalVisible = true;
      await getThumbnails();
    }
  }

  return (
    <>
      {/* <div>
        <div>
          <button
            className="h-full px-4 py-2 mx-2 font-bold text-white bg-yellow-600 rounded-lg hover:bg-yellow-900 focus:outline-none"
            onClick={() => {
              myWatermarksOnClick();
            }}
          >
            Saved Watermarks
          </button>
        </div>
        {appState.isMyWatermarksModalVisible ? <SavedWatermarks /> : null}
      </div> */}
      <h1 className="text-lg font-bold text-center color-gray-900">
        Saved Watermarks
      </h1>
      <FreeUserLimitDialog />
    </>
  );
});

export default UploadWatermark;
