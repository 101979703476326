import Switch from "react-switch";

function WmHereSwitch(props) {
    return (
        <Switch onChange={props.onChange} checked={props.checked}
            onColor="#766FC1"
            onHandleColor="#F6F6FC"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch" />
    )
}

export default WmHereSwitch