import React, { useEffect } from 'react';
import WatermarkTypes from './WatermarkTypes';
import UploadWatermark from './UploadWatermark';
import MainImage from './MainImage';
import TextProperty from './TextProperty';
import LogoProperty from './LogoProperty';
import GroupProperty from './GroupProperty';
import { appState, deleteUserWatermark } from '../../modals/basemodal';
import {
  DefaultWatermarksList,
  ModifiedWatermarksList,
} from '../../modals/wmodal';
import { observer } from 'mobx-react';
import fireAuth from '../../config/firebase';
import SingleSavedWatermark from './SingleSavedWatermark';

//function AddWatermark(props) {
const AddWatermark = observer((props) => {
  if (appState.defaultWatermarksList === null) {
    appState.defaultWatermarksList = new DefaultWatermarksList();
    appState.modifiedWatermarksList = new ModifiedWatermarksList(
      appState.defaultWatermarksList
    );
  }

  let currentActivePropertyItem = null;
  const propertDivVals = '';
  let propertyDivClass = '';

  if (appState.selectedProperty === 'text') {
    currentActivePropertyItem = <TextProperty />;
    propertyDivClass = propertDivVals;
  } else if (appState.selectedProperty === 'logo') {
    currentActivePropertyItem = <LogoProperty />;
    propertyDivClass = propertDivVals;
  } else if (appState.selectedProperty === 'group') {
    currentActivePropertyItem = <GroupProperty />;
    propertyDivClass = propertDivVals;
  } else {
    propertyDivClass = '';
  }

  useEffect(() => {
    const getThumbnails = async () => {
      if (appState.loggedInUser) {
        var userRef = fireAuth
          .firestore()
          .collection('users')
          .doc(appState.loggedInUser.uid);
        await userRef.get().then(function (userInfo) {
          if (userInfo.exists) {
            appState.listOfStoredWatermarks = userInfo.data().userWatermarks;
          }
        });
      }
    };
    getThumbnails();
  }, [appState.loggedInUser]);

  return (
    <div className="w-screen h-full C-AW">
      <div className="flex flex-row w-screen h-full mx-auto justify-evenly">
        <div
          className="flex flex-col items-center w-1/5 max-w-xs p-2 py-4 mx-3 border border-gray-400 rounded-lg"
          style={{ height: '95%' }}
        >
          <UploadWatermark />
          <div className="w-full mt-4 overflow-auto">
            {appState.loggedInUser ? (
              appState.isPaidUser ? (
                (appState.listOfStoredWatermarks && appState.listOfStoredWatermarks.length > 0) ? (
                appState.listOfStoredWatermarks.map((watermark, index) => (
                  <SingleSavedWatermark
                    key={index}
                    eachWatermark={watermark}
                    index={index}
                    onDeleteMethod={deleteUserWatermark}
                  />
                ))):<p className="text-center">
                You don't have any saved watermarks yet to be displayed
              </p>
              ) : (
                <p className="text-center">
                  This is a premium user feature
                </p>
              )
            ) : (
              <p className="text-center xl:text-sm 2xl:text-base">
                Please login to view your saved watermarks
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-col mx-2 w-100" style={{ maxHeight: '72vh' }}>
          <WatermarkTypes />
          <MainImage />
        </div>
        <div
          className={'w-1/4 max-w-sm px-2 rounded-lg mx-2' + propertyDivClass}
          style={{ height: '95%' }}
        >
          <TextProperty />
          <LogoProperty />
          <GroupProperty />
        </div>
      </div>
    </div>
  );
});

export default AddWatermark;
