import React, { Component } from 'react';
import { fabric } from 'fabric';
import { observer } from 'mobx-react';
import { observe } from 'mobx';
import {
  appState,
  deleteActiveWatermarkForAppState,
} from '../../modals/basemodal';
import {
  DEFAULT_TEXT_COLOR,
  DEFAULT_OUTLINE_COLOR,
  DEFAULT_BACKGROUND_COLOR,
  DEFAULT_SHADOW_COLOR,
  DEFAULT_SVG_IMAGE_COLOR,
} from '../../Constants';
import { getThumbnailsLength } from './DisplayItemsDialogue';
import { cloneDeep } from 'lodash';

const MainImage = observer(
  class MainImage extends React.Component {
    constructor(props) {
      super(props);
      this.previewUrl = null;
      this.__canvas = null;
      this.Screenwidth = window.outerWidth;

      const disposer = observe(appState, 'isPaidUser', (change) => {
        if (appState.isPaidUser) {
          getThumbnailsLength();
        }
      });
      const disposer1 = observe(appState, 'imageWidth', (change) => {
        this.setNewBackgroundImage();
        this.setPropsOnCanvas();
      });

      const disposer2 = observe(
        appState,
        'listOfAllUploadedImage',
        (change) => {
          this.setNewBackgroundImage();
          this.setPropsOnCanvas();
        }
      );
    }

    componentDidMount() {
      // Make a New Canvas
      document.addEventListener('keydown', this.onKeyboardDeleteBtn, false);

      appState.mainImageCanvasWidth =
        window.innerWidth - (window.innerWidth * 40) / 100;
      appState.mainImageCanvasHeight =
        window.innerHeight - (window.innerHeight * 30) / 100;
      this.__canvas = new fabric.Canvas('meCanvas', {
        preserveObjectStacking: true,
        height: appState.mainImageCanvasHeight,
        width: appState.mainImageCanvasWidth, //80% of window (0.8)
        backgroundColor: 'transparent',
      });

      this.__canvas.on('object:selected', (e) => {
        //console.log('object:selected',e)
        this.setSelectedObjectToAppState(e);
      });
      this.__canvas.on('selection:updated', (e) => {
        //console.log('selection:updated',e)
        this.setSelectedObjectToAppState(e);
      });
      // this.__canvas.on('text:changed', (e) => console.log('text:changed', e.target, e));

      this.__canvas.on('selection:cleared', (e) => {
        //console.log('selection:cleared',e.deselected)
        if (e && e.deselected) {
          this.updateDefaultWatermark(e.deselected[0]);
        }
        appState.selectedProperty = null;
        //appState.selectedText = null;
        //appState.selectedLogo = null;
      });

      this.__canvas.on('object:added', (e) => {
        //console.log('object:added',e)
        this.setSelectedObjectToAppState(e);
        //e.target.set('active',true);
      });

      this.__canvas.on('mouse:down', (e) => {
        //console.log('mouse:down',e)
        this.setSelectedObjectToAppState(e);
        if (e && e.target) {
          this.updateDefaultWatermark(e.target);
        }
        //this.__canvas.renderAll(this.__canvas);
        //    console.log('----____==> ', e.target);
      });

      this.__canvas.on('text:changed', (e) => {
        appState.selectedText = null; // Mobx observer is not rendering for object (So null and assign value will work)
        appState.selectedText = e.target;
        //console.log('****text:changed****> ', e.target);
      });

      if (appState.imageWidth > 0) {
        this.setNewBackgroundImage();
        this.setPropsOnCanvas();
      }

      if (
        appState.listOfAllUploadedImage.length > 0 &&
        appState.listOfDefaultWatermarkIds.length > 0
      ) {
        appState.listOfDefaultWatermarkIds.map((event) => {
          let obj =
            appState.modifiedWatermarksList.getDefaultWatermarkByKey(event);
          this.__canvas.add(obj);
          this.__canvas.setActiveObject(obj);
        });
      }

      if (appState.listOfAllUploadedImage.length > 0) {
        this.setNewBackgroundImage();
        this.setPropsOnCanvas();
      }

      if (this.__canvas) {
        appState.canvasId = this.__canvas;
        var text = new fabric.Text('\uf1b9', {
          fontFamily: 'FontAwesome',
          left: 1,
          top: 1,
          fontSize: 0,
          fill: 'red',
          hasRotatingPoint: false,
          lockUniScaling: true,
          lockMovementX: true,
          lockMovementY: true,
          lockScalingFlip: true,
          lockScalingX: true,
          lockScalingY: true,
          opacity: 0,
        });
        appState.canvasId.add(text);
      }
    }

    setPropsOnCanvas() {
      let objects = this.__canvas._objects;
      if (objects.length > 0) {
        objects.map((event) => {
          event._removeCacheCanvas();
        });
      }
    }

    componentWillUnmount() {
      document.removeEventListener('keydown', this.onKeyboardDeleteBtn, false);
    }

    onKeyboardDeleteBtn = (e) => {
      if (e.key === 'Delete') {
        var activeObject = this.__canvas.getActiveObject();
        if (activeObject) {
          this.__canvas.remove(activeObject);
        }
        deleteActiveWatermarkForAppState(activeObject.myid);
      }
    };

    updateDefaultWatermark(updatedText) {
      if (updatedText) {
        console.log(updatedText.myid);
        if (
          appState.listOfDefaultWatermarkIds.indexOf(updatedText.myid) > -1 &&
          appState.modifiedWatermarksList
        ) {
          appState.modifiedWatermarksList._defaultWatermarks._allWatermarks[
            updatedText.myid
          ] = updatedText;
        }
      }
      console.log(appState.modifiedWatermarksList);
    }

    setSelectedObjectToAppState(e) {
      // console.log('object:selected----AAAAAAAAAAA--->', console.log(JSON.stringify(e.target)));
      if (e.target) {
        var myId = e.target.myid;
        if (myId !== undefined) {
          if (e.target.text) {
            if (myId.match('text') || myId.match('fontAwesomeText')) {
              appState.selectedProperty = 'text';
              appState.selectedText = e.target;
              appState.selectedText.isHidden = false;
              appState.allAddedText[myId] = e.target;
              var text = { myid: e.target.myid, target: e.target };
              appState.listOfTextAdded.push(text);
              this.setPickerColorArray('text', e);
            }
            //console.log('SASAS----IF', e.target, '::::', appState.allAddedText, '==>', e.target.myid)
          } else if (
            e.target.get('type') === 'image' ||
            (myId.match('svgLogo') && e.target.isRepeat === undefined)
          ) {
            appState.selectedProperty = 'logo';
            appState.selectedLogo = e.target;
            appState.selectedLogo.isHidden = false;
            if (myId.match('logo')) {
              // i === image
              appState.allAddedLogos[myId] = e.target;
              this.setPickerColorArray('logo', e);
            } else if (myId.match('svgLogo')) {
              appState.allAddedLogos[myId] = e.target;
              this.setPickerColorArray('svgLogo', e);
            }
            //console.log('SASAS----ELSE IF', appState.selectedProperty, '::::', appState.selectedLogo)
          } else if (myId.match('group')) {
            appState.selectedProperty = 'group';
            appState.selectedGroup = e.target;
            if (myId.match('group')) {
              // i === image
              appState.allAddedGroups[myId] = e.target;
            }
          } else {
            // If REPEATED object selected update text/logo object.
            if (e.target.fill.repeat) {
              //    && appState.selectedText
              if (
                appState.allAddedText[e.target.myid] &&
                appState.allAddedText[e.target.myid].text
              ) {
                appState.selectedText = appState.allAddedText[e.target.myid];
                appState.selectedText.isHidden = true; // some times it will be false for repeated object.
                appState.selectedProperty = 'text';
              }
              // else if(e.target.get('type') === 'image'){
              else if (appState.allAddedLogos[e.target.myid]) {
                // && appState.allAddedLogos[e.target.myid].get('type') === 'image'){
                appState.selectedLogo = appState.allAddedLogos[e.target.myid];
                appState.selectedLogo.isHidden = true;
                appState.selectedProperty = 'logo';
              }
              //console.log('***ELSE***', e.target ,appState.selectedLogo, e.target.myid, appState.allAddedLogos, '::', appState.selectedProperty);
            }
          }
          this.__canvas.setActiveObject(e.target);
          this.__canvas.renderAll();
        }
      }
    }

    setPickerColorArray(objType, e) {
      if (objType === 'text') {
        var backgroundColor =
          e.target.backgroundColor !== 'transparent'
            ? e.target.backgroundColor
            : DEFAULT_BACKGROUND_COLOR;
        var strokeColor =
          e.target.stroke !== 'transparent'
            ? e.target.stroke
            : DEFAULT_OUTLINE_COLOR;
        var textShadowColor =
          e.target.shadow !== null
            ? e.target.shadow.color
            : DEFAULT_SHADOW_COLOR;
        appState.pickerColors = [
          'green',
          e.target.fill,
          backgroundColor,
          strokeColor,
          textShadowColor,
          DEFAULT_SHADOW_COLOR,
          DEFAULT_SVG_IMAGE_COLOR,
        ];
        //console.log('Changed : 11', appState.pickerColors);
      } else if (objType === 'logo') {
        var logoShadowColor =
          e.target.shadow !== null
            ? e.target.shadow.color
            : DEFAULT_SHADOW_COLOR;
        appState.pickerColors = [
          'green',
          DEFAULT_TEXT_COLOR,
          DEFAULT_BACKGROUND_COLOR,
          DEFAULT_OUTLINE_COLOR,
          DEFAULT_SHADOW_COLOR,
          logoShadowColor,
          DEFAULT_SVG_IMAGE_COLOR,
        ];
        //console.log('Changed : 22', appState.pickerColors);
      } else if (objType === 'svgLogo') {
        if (e.target.path) {
          var svgColor = e.target.get('fill');
          var svgShadowColor =
            e.target.shadow !== null
              ? e.target.shadow.color
              : DEFAULT_SHADOW_COLOR;
          appState.pickerColors = [
            'green',
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            svgShadowColor,
            svgColor,
          ];
        } else if (e.target._objects) {
          var svgColor = e.target._objects[0].get('fill');
          var svgShadowColor =
            e.target.shadow !== null
              ? e.target.shadow.color
              : DEFAULT_SHADOW_COLOR;
          appState.pickerColors = [
            'green',
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            svgShadowColor,
            svgColor,
          ];
        } else {
          appState.pickerColors = [
            'green',
            DEFAULT_TEXT_COLOR,
            DEFAULT_BACKGROUND_COLOR,
            DEFAULT_OUTLINE_COLOR,
            DEFAULT_SHADOW_COLOR,
            DEFAULT_SHADOW_COLOR,
            DEFAULT_SVG_IMAGE_COLOR,
          ];
        }
      }
    }

    setNewBackgroundImage() {
      if (appState.listOfAllUploadedImage.length > 0) {
        var imgObj = appState.listOfAllUploadedImage[0];
        var myDataUrl = imgObj.src;
        if (myDataUrl === 0) {
          this.__canvas.setBackgroundImage(
            myDataUrl,
            this.__canvas.renderAll.bind(this.__canvas)
          );
        } else {
          let scale = 1;
          if (
            appState.mainImageCanvasHeight < imgObj.height ||
            appState.mainImageCanvasWidth < imgObj.width
          ) {
            let heightScale = 1;
            let widthScale = 1;
            if (appState.mainImageCanvasHeight < imgObj.height) {
              heightScale = appState.mainImageCanvasHeight / imgObj.height;
            }
            if (appState.mainImageCanvasWidth < imgObj.width) {
              widthScale = appState.mainImageCanvasWidth / imgObj.width;
            }
            scale = Math.min(heightScale, widthScale);
          }
          let newWidth = imgObj.width * scale;
          let newHeight = imgObj.height * scale;
          this.__canvas.setWidth(newWidth);
          this.__canvas.setHeight(newHeight);
          this.__canvas.setBackgroundImage(
            myDataUrl,
            this.__canvas.renderAll.bind(this.__canvas),
            {
              top: 0,
              left: 0,
              originX: 'left',
              originY: 'top',
              scaleX: scale,
              scaleY: scale,
            }
          );
        }
        this.__canvas.renderAll();
      }
    }

    render() {
      return (
        <div className="max-w-full mt-3 mb-5 C-MI max-h-100 xl:mb-5 2xl:mt-3 2xl:mb-5 xl:mt-3">
          <div className="flex items-center justify-center h-full max-w-full max-h-full ">
            <canvas
              id="meCanvas"
              ref="refCanvas"
              onClick={() => console.log('onClick')}
            />
          </div>
        </div>
      );
    }
  }
);

export default MainImage;
